const initialState = {
    page: 1,
    limit: 10,
    order: 'nome',
    direction: 'asc',
    pontoMonitoramento: [],
    id: '',
    codigo: '',
    nome: '',
    latitude: '',
    longitude: '',
    idCursoDagua: '',
    idFonteExterna: '',
    codigoReferencia: '',
    excluido: '',
    pontosParametros: [],
    pontosEntidades: [],
    fonteExterna: null
};

export function pontoMonitoramento(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_PONTO_MONITORAMENTO':
            return {
                ...state,
                pontoMonitoramento: action.pontoMonitoramento,
                pontoEntidade: action.pontoEntidade,
                totalPages: action.totalPages
            };
        case 'PONTO_MONITORAMENTO_DETAIL':
            return {
                ...state,
                id: action.id,
                codigo: action.codigo,
                nome: action.nome,
                latitude: action.latitude,
                longitude: action.longitude,
                idCursoDagua: action.idCursoDagua,
                idFonteExterna: action.idFonteExterna,
                codigoReferencia: action.codigoReferencia,
                excluido: action.excluido,
                pontosParametros: action.pontosParametros,
                pontosEntidades: action.pontosEntidades,
                fonteExterna: action.fonteExterna
            };
        case 'PONTO_MONITORAMENTO_CLEAR':
            return {
                ...state,
                pontoMonitoramento: [],
                id: '',
                codigo: '',
                nome: '',
                latitude: '',
                longitude: '',
                idCursoDagua: '',
                idFonteExterna: '',
                codigoReferencia: '',
                excluido: '',
                pontosParametros: [],
                pontosEntidades: [],
                fonteExterna: null
            };
        case "PONTO_MONITORAMENTO_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_PONTO_MONITORAMENTO":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state;
    }
}

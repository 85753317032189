  import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { authActions } from '../_actions';
import {
    Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown,
    DropdownToggle, DropdownItem, DropdownMenu
} from 'reactstrap';
import { history } from '../_helpers';

const NavBarIndex = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);
    const dispatch = useDispatch();

    // {<img src={require('../imgs/logo_prosinos.png')} width="100" height="auto" alt="logo" className="logo ml-2 pb-2" />}
    // {<img src={require('../imgs/brand_fortalezatec_escuro.png')} width="130" height="auto" alt="logo" className="logo ml-2" />}
    // { <img src={require('../imgs/logo_gestor_bacias_horizontal.png')} width="110" height="auto" alt="logo" className="logo ml-2" /> }

    return (
        <div>
            <Navbar className="navbar navbar-light bg-light" expand="md">
                <NavbarBrand href="#">
                    {<img src={require('../imgs/logo_prosinos.png')} width="auto" height="35" alt="logo" className="p-0 m-0" />}
                </NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="m-auto">
                       
                    </Nav>
                    <Nav className="m-auto">
                        <NavLink onClick={() => history.push("/")} href="#">
                            <img src={require('../imgs/logo_monitoramento.png')} width="auto" height="35" alt="logo" className="p-0 m-0" />
                        </NavLink>
                        <NavLink onClick={() => history.push("/educacaoAmbiental")} href="#">
                            <img src={require('../imgs/banner_educacao_ambiental_mini.png')} width="auto" height="35" alt="educacao_ambiental" className="p-0 m-0" />
                        </NavLink>
                        <NavLink onClick={() => history.push("/ecopontos")} href="#">
                            <img src={require('../imgs/logo_logistica_novo_mini.png')} width="auto" height="40" alt="ecopontos" className="p-0 m-0"  />
                        </NavLink>
                    </Nav>
                    <Nav className="">
                        <NavItem style={{alignSelf: 'center'}}>
                            <NavLink className="dropdown-item" onClick={() => history.push("/login")} href="#">Acesso Restrito</NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        permissaoTipoUsuario: (state.authentication.user ? (state.authentication.user.tipoUsuario ? state.authentication.user.tipoUsuario.permissoesTiposUsuarios : null) : null),
        permissaoUsuario: (state.authentication.user ? state.authentication.user.permissoesUsuarios : null)
    };
}
const connectedNavBarPage = (NavBarIndex);
export { connectedNavBarPage as NavBarIndex };
import { crudService } from '../_services';
import alerts from '../_components/alerts';
import { stringUtils } from '../_utils';

export const graficoParametrosAguaAction = {
    getGrafico,
    onChangeProps,
    handleOnChangeProps,
    clear
};

function getGrafico(filterModel, showWaiting) {
    return dispatch => {
        if (showWaiting) {
            alerts.waiting();
        }
        let apiEndpoint = 'graficoParametrosAgua';
        crudService.get(apiEndpoint + stringUtils.serialize(filterModel))
            .then((response) => {
                if (response.data) {
                    response.data.graficoParametrosAgua = {
                        options: {
                            colors: ['#4d3a96', '#4576b5'],
                            chart: {
                                id: "basic-bar",                               
                                animations: {
                                    speed: 300
                                },
                            },
                            dataLabels: {
                                position: 'top',
                                enabled: true,
                                style: {
                                    fontWeight: 'normal',
                                    colors: ['#000']
                                },
                                offsetY: -20
                            },
                            plotOptions: {
                                bar: {
                                    distributed: false,
                                    horizontal: false,
                                    dataLabels: {
                                        position: 'top'
                                    }
                                }
                            },
                            xaxis: {
                                enabled: false,
                                labels: { 
                                    rotate: -45,
                                    type: 'category'
                                },
                                style: {
                                    fontSize: '7px'
                                },
                                categories: response.data.graficoParametrosAgua.options
                            },
                            annotations: {
                                yaxis: [
                                    {
                                        y: response.data.graficoParametrosAgua.series[2],
                                        borderColor: '#4287f5',
                                        strokeDashArray: 0,
                                        label: {
                                            borderColor: '#4287f5',
                                            style: {
                                                color: '#fff',
                                                background: '#4287f5'
                                            },
                                            text: 'Média de valores: ' + (Number(response.data.graficoParametrosAgua.series[2]).toFixed(2))
                                        }
                                    }
                                ]
                            }
                        },
                        series: [
                            {
                                name: "Valor",
                                data: response.data.graficoParametrosAgua.series[0],
                                colors: '#4d3a96'
                            },
                            {
                                name: "IQA",
                                data: response.data.graficoParametrosAgua.series[1],
                                colors: '#4576b5'
                            }
                        ]
                    };
                    dispatch(editGraficosDetails(response.data));
                }
                if (showWaiting) {
                    alerts.clean();
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}

function onChangeProps(props, event) {
    return dispatch => {
        if (event.target.type === 'checkbox') {
            dispatch(handleOnChangeProps(props, event.target.checked));
        } else {
            dispatch(handleOnChangeProps(props, event.target.value));

        }
    };
}

export function changeGraficosList(graficoParametrosAgua, totalPages) {
    return {
        type: "FETECHED_ALL_GRAFICO_PARAMETROS_AGUA",
        graficoParametrosAgua: graficoParametrosAgua,
        totalPages: totalPages
    };
}
export function clear() {
    return {
        type: "GRAFICO_PARAMETROS_AGUA_CLEAR"
    };
}
export function handleOnChangeProps(props, value) {
    return {
        type: "HANDLE_ON_CHANGE_GRAFICO_PARAMETROS_AGUA",
        props: props,
        value: value
    };
}
export function editGraficosDetails(graficoParametrosAgua) {
    return {
        type: "GRAFICO_PARAMETROS_AGUA_DETAIL",
        graficoParametrosAgua: graficoParametrosAgua.graficoParametrosAgua,
        idPontoMonitoramento: graficoParametrosAgua.idPontoMonitoramento,
        idParametro: graficoParametrosAgua.idParametro,
        dataInicial: graficoParametrosAgua.dataInicial,
        dataFinal: graficoParametrosAgua.dataFinal
    };
}
export function updatedUserInfo() {
    return {
        type: "GRAFICO_PARAMETROS_AGUA_UPDATED"
    };
}
export function createUserInfo() {
    return {
        type: "GRAFICO_PARAMETROS_AGUA_CREATED_SUCCESSFULLY"
    };
}
export function deleteGraficosDetails() {
    return {
        type: "DELETED_GRAFICO_PARAMETROS_AGUA_DETAILS"
    };
}
import React from 'react';
import './App.css';
import { Router, Switch, Route } from 'react-router-dom';
import { Analises } from './analises/';
import { AnalisesEdit } from './analises/';
import { Camadas } from './camadas/';
import { CamadasEdit } from './camadas/';
import { CursosDagua } from './cursosDagua/';
import { CursosDaguaEdit } from './cursosDagua/';
import { GraficoQualidadeCursoDagua } from './graficos/';
import { GraficoParametrosAgua } from './graficos/';
import { GraficoParametrosQuantitativos } from './graficos/';
import { Index } from './index/';
import { IndexEducacaoAmbiental } from './indexEducacaoAmbiental/';
import { IndexEcopontos } from './indexEcopontos/';
import { Login } from './login/';
import { PasswordReset } from './login/';
import { RequestPasswordReset } from './login/';
import { Home } from './home/';
import { Entidades } from './entidades/';
import { EntidadesEdit } from './entidades/';
import { Erro403 } from './erros/';
import { FontesExternasEdit, FontesExternas } from './fontesExternas/';
import { Parametros } from './parametros/';
import { ParametrosAgua } from './parametrosAgua/';
import { ParametrosAguaEdit } from './parametrosAgua/';
import { Permissoes } from './permissoes/';
import { PontosMonitoramentos } from './pontosMonitoramento/';
import { PontosMonitoramentosEdit } from './pontosMonitoramento/';
import { PontosMonitoramentosPublicos } from './pontosMonitoramentoPublico/';
import { PesquisaRelatorioAnalises } from './relatorios/';
import { RelatorioAnalises } from './relatorios/';
import { Sobre } from './sobre/';
import { TiposUsuarios } from './tiposUsuarios/';
import { TiposUsuariosEdit } from './tiposUsuarios/';
import { UnidadesMedidas } from './unidadesMedidas/';
import { UnidadesMedidasEdit } from './unidadesMedidas/';
import { Usuarios } from './usuarios/';
import { UsuariosEdit } from './usuarios/';
import { history } from './_helpers';
import { PrivateRoute } from './_components';
import { ToastProvider } from 'react-toast-notifications';
import { fontesExternasAction } from './_actions/fontesExternas.actions';

if (process.env.NODE_ENV === 'development') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
        trackAllPureComponents: true,
    });
}

const App = (props) => {
    return (
        <div className="App">
            <ToastProvider>
                <Router history={history}>
                    <div id="app">
                        <Switch>
                            <PrivateRoute exact path='/home' component={Home} />
                            <Route exact path='/login' component={Login} />
                            <Route exact path='/password-reset' component={PasswordReset} />
                            <Route exact path='/request-password-reset' component={RequestPasswordReset} />
                            <Route exact path='/pontoMonitoramentoPublico/:id' component={PontosMonitoramentosPublicos} />
                            <Route exact path='/sobre' component={Sobre} />
                            <PrivateRoute exact path='/analises' component={Analises} />
                            <PrivateRoute exact path='/analise' component={AnalisesEdit} />
                            <PrivateRoute exact path='/analise/:id' component={AnalisesEdit} />
                            <PrivateRoute exact path='/camadas' component={Camadas} />
                            <PrivateRoute exact path='/camada' component={CamadasEdit} />
                            <PrivateRoute exact path='/camada/:id' component={CamadasEdit} />
                            <PrivateRoute exact path='/cursosDagua' component={CursosDagua} />
                            <PrivateRoute exact path='/cursoDagua' component={CursosDaguaEdit} />
                            <PrivateRoute exact path='/cursoDagua/:id' component={CursosDaguaEdit} />
                            <PrivateRoute exact path='/entidades' component={Entidades} />
                            <PrivateRoute exact path='/entidade' component={EntidadesEdit} />
                            <PrivateRoute exact path='/entidade/:id' component={EntidadesEdit} />
                            <PrivateRoute exact path='/erro403' component={Erro403} />
                            <PrivateRoute exact path='/fontesExternas' component={FontesExternas} />
                            <PrivateRoute exact path='/fonteExterna' component={FontesExternasEdit} />
                            <PrivateRoute exact path='/fonteExterna/:id' component={FontesExternasEdit} />
                            <PrivateRoute exact path='/graficoParametrosAgua' component={GraficoParametrosAgua} />
                            <PrivateRoute exact path='/graficoParametrosQuantitativos' component={GraficoParametrosQuantitativos} />
                            <PrivateRoute exact path='/graficoQualidadeCursoDagua' component={GraficoQualidadeCursoDagua} />
                            <PrivateRoute exact path='/parametros' component={Parametros} />
                            <PrivateRoute exact path='/parametrosAgua' component={ParametrosAgua} />
                            <PrivateRoute exact path='/parametroAgua' component={ParametrosAguaEdit} />
                            <PrivateRoute exact path='/parametroAgua/:id' component={ParametrosAguaEdit} />
                            <PrivateRoute exact path='/permissoes' component={Permissoes} />
                            <PrivateRoute exact path='/pontosMonitoramento' component={PontosMonitoramentos} />
                            <PrivateRoute exact path='/pontoMonitoramento' component={PontosMonitoramentosEdit} />
                            <PrivateRoute exact path='/pontoMonitoramento/:id' component={PontosMonitoramentosEdit} />
                            <PrivateRoute exact path='/pesquisaRelatorioAnalises' component={PesquisaRelatorioAnalises} />
                            <PrivateRoute exact path='/relatorioAnalises' component={RelatorioAnalises} />
                            <PrivateRoute exact path='/tiposUsuarios' component={TiposUsuarios} />
                            <PrivateRoute exact path='/tipoUsuario' component={TiposUsuariosEdit} />
                            <PrivateRoute exact path='/tipoUsuario/:id' component={TiposUsuariosEdit} />
                            <PrivateRoute exact path='/unidadesMedidas' component={UnidadesMedidas} />
                            <PrivateRoute exact path='/unidadeMedida' component={UnidadesMedidasEdit} />
                            <PrivateRoute exact path='/unidadeMedida/:id' component={UnidadesMedidasEdit} />
                            <PrivateRoute exact path='/usuarios' component={Usuarios} />
                            <PrivateRoute exact path='/usuario' component={UsuariosEdit} />
                            <PrivateRoute exact path='/usuario/:id' component={UsuariosEdit} />
                            <Route exact path='/educacaoAmbiental' component={IndexEducacaoAmbiental} />
                            <Route exact path='/ecopontos' component={IndexEcopontos} />
                            <Route exact path='/' component={Index} />
                        </Switch>
                    </div>
                </Router>
            </ToastProvider>
        </div>
    );
}
export default App;
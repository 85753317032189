import React, { useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { parametrosAguaAction, pontosMonitoramentoAction, graficoParametrosAguaAction } from '../_actions';
import { withRouter } from 'react-router-dom';
import {
    Card, CardBody, CardHeader, Container, Row, Col, FormGroup, Label, Input
} from 'reactstrap';
import { NavBar } from '../_components/navbar';
import '././styles.css';
import Select from 'react-select';
import { Button } from '@material-ui/core';
import Chart from 'react-apexcharts';

import alerts from '../_components/alerts';

const GraficoParametrosAgua = (props) => {

    const dispatch = useDispatch();

    useEffect(() => {
        const { match: { params } } = props;

        dispatch(pontosMonitoramentoAction.getPontosMonitoramentoSemFonteExternaSelect());
        dispatch(parametrosAguaAction.getParametrosAguaTipo1Select());
    }, []);

    const handleSelectChange = prop => event => {
        var event2 = {
            type: 'select',
            target: {
                value: event.value
            }
        };
        dispatch(graficoParametrosAguaAction.onChangeProps(prop, event2));
    };

    const handleChange = prop => event => {
        dispatch(graficoParametrosAguaAction.onChangeProps(prop, event));
    };

    const renderOptionsPontoSemFonteExterna = () => {
        return props.pontoMonitoramento.pontoMonitoramento.map((p, i) => {
            return (
                { value: p.value, label: p.text }
            );
        });
    };

    const renderOptionsParametroTipo1 = () => {
        return props.parametroAgua.parametroAgua.map((p, i) => {
            return (
                { value: p.value, label: p.text }
            );
        });
    };

    const buscarDadosParametrosAgua = () => {
        if (props.graficoParametrosAgua.idParametro != '' && props.graficoParametrosAgua.idPontoMonitoramento != ''
            && props.graficoParametrosAgua.dataInicial != null && props.graficoParametrosAgua.dataFinal != null        ) {

            if (props.graficoParametrosAgua.dataInicial < props.graficoParametrosAgua.dataFinal) {

                var filterModel = {
                    idParametro: props.graficoParametrosAgua.idParametro,
                    idPontoMonitoramento: props.graficoParametrosAgua.idPontoMonitoramento,
                    dataInicial: props.graficoParametrosAgua.dataInicial,
                    dataFinal: props.graficoParametrosAgua.dataFinal                    
                }

                dispatch(graficoParametrosAguaAction.getGrafico(filterModel, true));

            } else {
                alerts.alert('Data final não pode ser menor que a data inicial!');
            }

        } else {
            alerts.alert('Preencha os dados!');
        }
    }

    return (
        <div>
            <NavBar />
            <Container>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col md={12}>
                                <Label className="center" for="data">Gráfico dos Parâmetros Qualitativos em um Ponto</Label>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col sm={12} md={5}>
                                <FormGroup>
                                    <Label className="left" for="idPontoMonitoramento">Ponto de Monitoramento *</Label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        value={renderOptionsPontoSemFonteExterna().filter(function (option) {
                                            return option.value == (props.graficoParametrosAgua.idPontoMonitoramento);
                                        })}
                                        onChange={handleSelectChange('idPontoMonitoramento')}
                                        isSearchable
                                        id="idPontoMonitoramento"
                                        name="idPontoMonitoramento"
                                        options={renderOptionsPontoSemFonteExterna()}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={5}>
                                <FormGroup>
                                    <Label className="left" for="idParametro">Parâmetro *</Label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        value={renderOptionsParametroTipo1().filter(function (option) {
                                            return option.value == (props.graficoParametrosAgua.idParametro);
                                        })}
                                        onChange={handleSelectChange('idParametro')}
                                        isSearchable
                                        id="idParametro"
                                        name="idParametro"
                                        options={renderOptionsParametroTipo1()}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={5}>
                                <FormGroup>
                                    <Label className="left" for="dataInicial">Data inicial *</Label>
                                    <Input
                                        id="dataInicial"
                                        label="dataInicial"
                                        autoFocus
                                        className="form-control"
                                        type="date"
                                        value={props.graficoParametrosAgua.dataInicial}
                                        onChange={handleChange('dataInicial')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={5}>
                                <FormGroup>
                                    <Label className="left" for="dataFinal">Data final *</Label>
                                    <Input
                                        id="dataFinal"
                                        label="dataFinal"
                                        autoFocus
                                        className="form-control"
                                        type="date"
                                        value={props.graficoParametrosAgua.dataFinal}
                                        onChange={handleChange('dataFinal')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={2} style={{ alignSelf: 'end' }}>
                                <FormGroup>
                                    <Button onClick={() => buscarDadosParametrosAgua()} variant="contained" color="primary">Buscar</Button>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <div style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                                    <Chart
                                        options={props.graficoParametrosAgua.graficoParametrosAgua.options}
                                        series={props.graficoParametrosAgua.graficoParametrosAgua.series}
                                        type="bar"
                                        height="270"
                                    />
                                </div>
                            </Col>
                        </Row>                        
                    </CardBody>
                </Card>
            </Container>
        </div>
    );

};


const mapStateToProps = (state) => {
    return state;
};

const connectedGraficoParametrosAguaPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(GraficoParametrosAgua));
export { connectedGraficoParametrosAguaPage as GraficoParametrosAgua };
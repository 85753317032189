import React from 'react';
import { View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import moment from 'moment';

const styles = StyleSheet.create({
    image: {
        padding: 10,
        width: 120
    },
    title: {
        fontSize: 14,
        textAlign: 'left'
    },
    subTitle: {
        fontSize: 12,
        textAlign: 'left'
    },
    details: {
        fontSize: 8,
        textAlign: 'left'
    }
});

const Header = (props) => {

    return (
        <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 10 }} >

            <View style={{ width: "30%" }} >
                <Image
                    style={styles.image}
                    src={require('../imgs/logo_gestor_bacias_horizontal.png')}
                />
            </View>

            <View style={{ flexDirection: "row", width: "70%" }} >

                <View style={{ alignItems: "center", marginLeft: 5, width: "50%" }} >
                    <Text style={styles.title}>{props.title}</Text>
                    <Text style={styles.subTitle}>{props.subTitle}</Text>
                </View>

                <View style={{ alignItems: "flex-end", marginLeft: 5, marginRight: 20, width: "46%" }} >
                    <Text style={styles.details}>Emitido por {JSON.parse(localStorage.getItem('user'))["nome"]}</Text>
                    <Text style={styles.details}>Em {moment(new Date).format('DD/MM/YYYY HH:mm')}</Text>
                </View>

            </View>            

        </View>
    )

};

export { Header };
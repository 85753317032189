import axios from 'axios';
import { history } from '../_helpers';
import config from '../config/config';
import alerts from '../_components/alerts';

export const crudService = {
    get,
    post,
    put,
    deleteDetail
};
function get(apiEndpoint) {
    //console.log('axios', config.baseUrl + apiEndpoint)
    return axios.get(config.baseUrl + apiEndpoint, getOptions())
        .then((response) => {
            if (response.status === 401) {
                logout();
            } else if (response.status === 403) {
                history.push("/erro403");
            } else {
                return response;
            }
        }).catch((err) => {
            if (err.response.status === 401) {
                logout();
            } else if (err.response.status === 403) {
                history.push("/erro403");
            } else {
                console.log(err);               
            }
            return err;
        });
}
function post(apiEndpoint, payload) {
    return axios.post(config.baseUrl + apiEndpoint, payload, getOptions())
        .then((response) => {
            if (response.status === 401) {
                logout();
            } else if (response.status === 403) {
                history.push("/erro403");
            } else {
                return response;
            }
        }).catch((err) => {
            if (err.response.status === 401) {
                logout();
            } else if (err.response.status === 403) {
                history.push("/erro403");
            } else {
                console.log(err);
            }
            return err;
        });
}
function put(apiEndpoint, payload) {
    return axios.put(config.baseUrl + apiEndpoint, payload, getOptions())
        .then((response) => {
            if (response.status === 401) {
                logout();
            } else if (response.status === 403) {
                history.push("/erro403");
            } else {
                return response;
            }
        }).catch((err) => {
            if (err.response.status === 401) {
                logout();
            } else if (err.response.status === 403) {
                history.push("/erro403");
            } else {
                console.log(err);
            }
            return err;
        });
}
function deleteDetail(apiEndpoint) {
    return axios.delete(config.baseUrl + apiEndpoint, getOptions())
        .then((response) => {
            if (response.status === 401) {
                logout();
            } else if (response.status === 403) {
                history.push("/erro403");
            } else {
                return response;
            }
        }).catch((err) => {
            if (err.response.status === 401) {
                logout();
            } else if (err.response.status === 403) {
                history.push("/erro403");
            } else {
                console.log(err);
            }
            return err;
        });
}
function getOptions() {
    let options = {};
    if (localStorage.getItem('token')) {
        options.headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token') };
    }
    return options;
}
function logout() {
    localStorage.removeItem('auth');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    history.push('/login');
    alerts.alert("Sessão encerrada. Realize login novamente.");
}
import moment from 'moment';

export const stringUtils = {
    toInputLowercase,
    toInputUppercase,
    phoneMask,
    formatDateYYMMDD,
    formatCpf,
    getParametroFaixa,
    getParametroFaixaNitrogenio,
    formataData,
    formataNumero,
    formataDataHora,
    serialize,
    formataTelefone,
    formataValor,
    removeDiacritics,
    onlyNumbers,
    retornaCorIqa,
    retornaTextoIqa,
    retornaClassificacao
};

function toInputLowercase (e) {
    //e.target.value = ("" + e.target.value).toLowerCase();
    var start = e.target.selectionStart;
    var end = e.target.selectionEnd;
    e.target.value = e.target.value.toLowerCase();
    e.target.setSelectionRange(start, end);
};

function toInputUppercase(e) {
    //e.target.value = ("" + e.target.value).toUpperCase();
    var start = e.target.selectionStart;
    var end = e.target.selectionEnd;
    e.target.value = e.target.value.toUpperCase();
    e.target.setSelectionRange(start, end);
};

function formataTelefone (v) {
    const digitos = !v ? '' : v.replace(/[^\d]/g, '');
    if (!digitos || digitos.length < 10) return v;
    const corte = digitos.length === 10 ? 6 : 7;
    const max = digitos.length > 11 ? 11 : digitos.length;
    return `(${digitos.substring(0, 2)}) ${digitos.substring(2, corte)}-${digitos.substring(corte, max)}`;
};

function phoneMask(v) {
    if (!v || v.length === 0) return '';
    const a = formataTelefone(v);
    return (a.length >= 6 && a[5] === '9') ? '(99) 99999-9999' : '(99) 9999-9999';
};

function formatDateYYMMDD(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
};

function formatCpf(cpf) {
    cpf = cpf.replace(/[^\d]/g, "");
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

function formataValor(valor) {
    var valor_decimal = parseFloat(valor); //.replace(".", "").replace(",", ".")
    var newValue = valor_decimal.toLocaleString(navigator.language, { maximumFractionDigits: 2 });
    return newValue;
}

function formataNumero(numero) {
    let formetedNumber = (Number(numero)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    let splitArray = formetedNumber.split('.');
    if (splitArray.length > 1) {
        formetedNumber = splitArray[0];
    }
    return (formetedNumber);
}

function formataData(data) {
    var newDate = moment(data);
    var dataString = newDate.format('DD') + '/' + newDate.format('MM') + '/' + newDate.format('YYYY');
    return dataString;
}

function formataDataHora(data) {
    var newDate = moment(data);
    var dataString = newDate.format('DD/MM/YYYY HH:mm');
    return dataString;
}

function getParametroFaixa(parametro, valor) {
    var valor_decimal = parseFloat(valor); //.replace(".", "").replace(",", ".")
    var faixas = parametro.parametrosAguaFaixa;
    var item = faixas.filter(faixa => valor_decimal >= parseFloat(faixa.faixaInicial)
        && valor_decimal <= parseFloat(faixa.faixaFinal)
        && (faixa.excluido == false || faixa.excluido == null)    
    );
    if (item.length > 0) {
        return item[0];
    } else {
        return '';
    }   
}

function getParametroFaixaNitrogenio(parametro, valor, valor_ph) {

    var valor_parametro = valor.replace(/,/, '.');
    var valor_ph_base = valor_ph.replace(/,/, '.');

    //console.log("parsefloat_ph: " + parseFloat(valor_ph) + "DEF: " + valor_ph + " REP: " + valor_ph_base);
    //console.log("parsefloat_: " + parseFloat(valor) + "DEF: " + valor + " REP: " + valor_parametro);

    //console.log("valor_ph: " + valor_ph);
    //console.log("valor_: " + valor);

    var nome_faixa = null;
    if (parseFloat(valor_ph_base) <= 7.5) {

        if (parseFloat(valor_parametro) <= 3.7) {
            //excelente e bom
            nome_faixa = "BOM";
        } else if (parseFloat(valor_parametro) <= 13.3) {
            //regular
            nome_faixa = "REGULAR";
        } else {
            //ruim
            nome_faixa = "RUIM";
        }

    } else if (parseFloat(valor_ph_base) > 7.5 && parseFloat(valor_ph_base) <= 8) {

        if (parseFloat(valor_parametro) <= 2) {
            //excelente e bom
            nome_faixa = "BOM";
        } else if (parseFloat(valor_parametro) <= 5.6) {
            //regular
            nome_faixa = "REGULAR";
        } else {
            //ruim
            nome_faixa = "RUIM";
        }

    } else if (parseFloat(valor_ph_base) > 8.5) {

        if (parseFloat(valor_parametro) <= 0.5) {
            //excelente e bom
            nome_faixa = "BOM";
        } else if (parseFloat(valor_parametro) <= 2.2) {
            //regular
            nome_faixa = "REGULAR";
        } else {
            //ruim
            nome_faixa = "RUIM";
        }

    }

    var faixa = parametro.parametrosAguaFaixa.find((element) => {
        return element.nome === nome_faixa;
    });

    if (faixa != undefined) {
        return faixa;
    } else {
        return '';
    }

    //var valor_decimal = parseFloat(valor.replace(".", "").replace(",", "."));
    var faixas = parametro.parametrosAguaFaixa;
    var item = faixas.filter(faixa => valor_parametro >= parseFloat(faixa.faixaInicial)
        && valor_parametro <= parseFloat(faixa.faixaFinal)
        && (faixa.excluido == false || faixa.excluido == null)
    );
    if (item.length > 0) {
        return item[0];
    } else {
        return '';
    }

}

function serialize(obj) {
    let str = '?' + Object.keys(obj).reduce(function (a, k) {
        a.push(k + '=' + encodeURIComponent(obj[k]));
        return a;
    }, []).join('&');
    return str;
}

function onlyNumbers(str) {
    return str.replace(/\D/g, "");
};

function removeDiacritics(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

function retornaCorIqa(valor) {
    try {
        if (parseFloat(valor) <= 25) {
            return "#e74c3c"; //vermelho
        } else if (parseFloat(valor) <= 50) {
            return "#808D8E";  //cinza
        } else if (parseFloat(valor) <= 70) {
            return "#f1c40f";  //amarelo
        } else if (parseFloat(valor) <= 90) {
            return "#2ecc71"; //verde
        } else if (parseFloat(valor) <= 100) {
            return "#3498db"; //azul
        }

    } catch (error) {
        return "";
    }
}

function retornaTextoIqa(valor) {
    try {
        if (parseFloat(valor) <= 25) {
            return "Muito Ruim";
        } else if (parseFloat(valor) <= 50) {
            return "Ruim";
        } else if (parseFloat(valor) <= 70) {
            return "Regular";
        } else if (parseFloat(valor) <= 90) {
            return "Bom";
        } else if (parseFloat(valor) <= 100) {
            return "Excelente";
        }

    } catch (error) {
        return "";
    }
}

function retornaClassificacao(parametros) {
    try {
        //console.log('PARAMETROS TESTE', parametros)

        var total_count_ruim = 0; //vermelho
        var total_count_regular = 0; //amarelo
        var total_count_bom = 0;
        var total_count_excelente = 0;
        var pH = parametros.find((element) => {
            return element.parametroAgua.nome === "PH";
        })
        for (let item of parametros) {
            if (item.parametroAgua.nome != 'NITROGÊNIO') {
            var faixa = getParametroFaixa(item.parametroAgua, item.valor)
            if (faixa != '') {
                //console.log('ITEM', item)
                if (faixa.nome.includes('RUIM')) {
                    total_count_ruim += 1;
                } else if (faixa.nome.includes('REGULAR')) {
                    total_count_regular += 1;
                } else if (faixa.nome.includes('BOM')) {
                    total_count_bom += 1;
                } else if (faixa.nome.includes('EXCELENTE')) {
                    total_count_excelente += 1;
               }
            }
            } else {
                getParametroFaixaNitrogenio(item.parametroAgua, item.valor, pH.valor)
                if (faixa.nome.includes('RUIM')) {
                    total_count_ruim += 1;
                } else if (faixa.nome.includes('REGULAR')) {
                    total_count_regular += 1;
                } else if (faixa.nome.includes('BOM')) {
                    total_count_bom += 1;
                } else if (faixa.nome.includes('EXCELENTE')) {
                    total_count_excelente += 1;
                }
            }
        }
       // console.log('total_count_ruim', total_count_ruim)
       // console.log('total_count_regular', total_count_regular)
      //  console.log('total_count_bom', total_count_bom)
       // console.log('total_count_excelente', total_count_excelente)

        if (total_count_ruim > 0) {
            return "4";
        } else if (total_count_ruim == 0 && total_count_regular > 0) {
            return "3";
        } else if (total_count_ruim == 0 && total_count_regular == 0 && total_count_bom > 0) {
            return "2";
        } else if (total_count_excelente == 8){
            return "1";
        }

    } catch (error) {
        return "";
    }
}



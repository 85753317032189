const initialState = {  
    id: 0,
    idPontoMonitoramento: '',
    idParametro: '',
    dataInicial: '',
    dataFinal: '',
    graficoParametrosQuantitativosDataAtual: {
        options: {},
        series: []
    },
    graficoParametrosQuantitativosDataUmAnoAtras: {
        options: {},
        series: []
    },
    graficoParametrosQuantitativosDataDoisAnosAtras: {
        options: {},
        series: []
    },
};

export function graficoParametrosQuantitativos(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_GRAFICO_PARAMETROS_QUANTITATIVOS':
            return {
                ...state,
                graficoParametrosQuantitativosDataAtual: action.graficoParametrosQuantitativosDataAtual,
                graficoParametrosQuantitativosDataUmAnoAtras: action.graficoParametrosQuantitativosDataUmAnoAtras,
                graficoParametrosQuantitativosDataDoisAnosAtras: action.graficoParametrosQuantitativosDataDoisAnosAtras
            };
        case 'GRAFICO_PARAMETROS_QUANTITATIVOS_DETAIL':
            return {
                ...state,
                id: action.id,              
                idPontoMonitoramento: action.idPontoMonitoramento,
                idParametro: action.idParametro,
                dataInicial: action.dataInicial,
                dataFinal: action.dataFinal,
                graficoParametrosQuantitativosDataAtual: action.graficoParametrosQuantitativosDataAtual,
                graficoParametrosQuantitativosDataUmAnoAtras: action.graficoParametrosQuantitativosDataUmAnoAtras,
                graficoParametrosQuantitativosDataDoisAnosAtras: action.graficoParametrosQuantitativosDataDoisAnosAtras
            };
        case 'GRAFICO_PARAMETROS_QUANTITATIVOS_CLEAR':
            return {
                ...state,
                id: 0,
                idPontoMonitoramento: '',
                idParametro: '',
                dataInicial: '',
                dataFinal: ''
            };
        case "GRAFICO_PARAMETROS_QUANTITATIVOS_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_GRAFICO_PARAMETROS_QUANTITATIVOS":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state;
    }
}

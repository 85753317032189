const initialState = {
    permissaoUsuario: [],
    id: '',
    idPermissao: '',
    idUsuario: '',
    permitido: ''
};

export function permissaoUsuario(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_PERMISSAO_USUARIO':
            return {
                ...state,
                permissaoUsuario: action.permissaoUsuario,
                totalPages: action.totalPages
            };
        case 'PERMISSAO_USUARIO_DETAIL':
            return {
                ...state,
                id: action.id,
                idPermissao: action.idPermissao,
                idUsuario: action.idUsuario,
                permitido: action.permitido
            };
        case 'PERMISSAO_USUARIO_CLEAR':
            return {
                ...state,
                permissaoUsuario: [],
                id: '',
                idPermissao: '',
                idUsuario: '',
                permitido: ''
            };
        case "PERMISSAO_USUARIO_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_PERMISSAO_USUARIO":
            return {
                ...state,
                [action.props]: action.value
            };
        case "HANDLE_ON_CHANGE_LIST_PERMISSAO_USUARIO":
            return {
                ...state,
                [permissaoUsuario]: [...state.permissaoUsuario][action.position].permitido = action.value
            };
        default:
            return state;
    }
}

import { crudService } from '../_services';
import alerts from '../_components/alerts';

export const dashboardAction = {
    getDashboard
};

const corPaleta = 'palette2';

function getDashboard() {
    return dispatch => {
        let apiEndpoint = 'dashboard';
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    if (response.data.graficoPontosCursos != null) {
                        response.data.graficoPontosCursos = {
                            options: {
                                chart: {
                                    id: "basic-bar",
                                    animations: {
                                        speed: 300
                                    }
                                },
                                theme: {
                                    palette: corPaleta
                                },
                                dataLabels: {
                                    position: 'top',
                                    enabled: true,
                                    style: {
                                        fontWeight: 'normal',
                                        colors: ['#000']
                                    },
                                    offsetY: -20
                                },
                                plotOptions: {
                                    bar: {
                                        distributed: true,
                                        horizontal: false,
                                        dataLabels: {
                                            position: 'top'
                                        }
                                    }
                                },
                                xaxis: {
                                    categories: response.data.graficoPontosCursos.options,
                                    labels: {
                                        show: false,
                                        style: {
                                            fontSize: '9px'
                                        },
                                        offsetY: -5
                                    }
                                }
                            },
                            series: [
                                {
                                    name: "Pontos",
                                    data: response.data.graficoPontosCursos.series[0]
                                }
                            ]
                        };
                    } else {
                        response.data.graficoPontosCursos = {
                            graficoPontosCursos: {
                                options: {},
                                series: []
                            },
                        }
                    }
                    if (response.data.graficoPontosEntidades != null) {
                        response.data.graficoPontosEntidades = {
                            options: {
                                chart: {
                                    id: "basic-bar",
                                    animations: {
                                        speed: 300
                                    }
                                },
                                theme: {
                                    palette: corPaleta
                                },
                                dataLabels: {
                                    position: 'top',
                                    enabled: true,
                                    style: {
                                        fontWeight: 'normal',
                                        colors: ['#000']
                                    },
                                    offsetY: -20
                                },
                                plotOptions: {
                                    bar: {
                                        distributed: true,
                                        horizontal: false,
                                        dataLabels: {
                                            position: 'top'
                                        }
                                    }
                                },
                                xaxis: {
                                    categories: response.data.graficoPontosEntidades.options,
                                    labels: {
                                        show: false,
                                        style: {
                                            fontSize: '9px'
                                        },
                                        offsetY: -5
                                    }
                                }
                            },
                            series: [
                                {
                                    name: "Pontos",
                                    data: response.data.graficoPontosEntidades.series[0]
                                }
                            ]
                        };
                    } else {
                        response.data.graficoPontosEntidades = {
                            graficoPontosEntidades: {
                                options: {},
                                series: []
                            },
                        }
                    }

                    //response.data.graficoPontosCursos = {
                    //    options: {
                    //        labels: response.data.graficoPontosCursos.options
                    //    }, 
                    //    series: response.data.graficoPontosCursos.series[0]
                    //};

                    //response.data.graficoMatriculasNiveisCarreira = {
                    //    options: {
                    //        labels: response.data.graficoMatriculasNiveisCarreira.options
                    //    },                        
                    //    series: response.data.graficoMatriculasNiveisCarreira.series[0]
                    //};

                    dispatch(editDashboardDetails(response.data));

                }

            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
export function editDashboardDetails(dashboard) {
    return {
        type: "DASHBOARD_DETAIL",
        totalPontos: dashboard.totalPontos,
        totalEntidades: dashboard.totalEntidades,
        totalAnalises: dashboard.totalAnalises,
        totalCursos: dashboard.totalCursos,
        graficoPontosCursos: dashboard.graficoPontosCursos,
        graficoPontosEntidades: dashboard.graficoPontosEntidades,
        //graficoMatriculasFuncoes: dashboard.graficoMatriculasFuncoes,
        //graficoMatriculasClasses: dashboard.graficoMatriculasClasses,
        //graficoMatriculasNiveisCarreira: dashboard.graficoMatriculasNiveisCarreira
    };
}
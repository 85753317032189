const initialState = {  
    id: 0,
    idPontoMonitoramento: '',
    idParametro: '',
    dataInicial: '',
    dataFinal: '',
    graficoParametrosAgua: {
        options: {},
        series: []
    },
};

export function graficoParametrosAgua(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_GRAFICO_PARAMETROS_AGUA':
            return {
                ...state,
                graficoParametrosAgua: action.graficoParametrosAgua
            };
        case 'GRAFICO_PARAMETROS_AGUA_DETAIL':
            return {
                ...state,
                id: action.id,              
                idPontoMonitoramento: action.idPontoMonitoramento,
                idParametro: action.idParametro,
                dataInicial: action.dataInicial,
                dataFinal: action.dataFinal,
                graficoParametrosAgua: action.graficoParametrosAgua
            };
        case 'GRAFICO_PARAMETROS_AGUA_CLEAR':
            return {
                ...state,
                id: 0,
                idPontoMonitoramento: '',
                idParametro: '',
                dataInicial: '',
                dataFinal: ''
            };
        case "GRAFICO_PARAMETROS_AGUA_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_GRAFICO_PARAMETROS_AGUA":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state;
    }
}

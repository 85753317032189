const initialState = {
    unidadeMedida: [],
    id: '',
    abreviatura: '',
    nome: '',
    excluido: ''
};

export function unidadeMedida(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_UNIDADE_MEDIDA':
            return {
                ...state,
                unidadeMedida: action.unidadeMedida,
                totalPages: action.totalPages
            };
        case 'UNIDADE_MEDIDA_DETAIL':
            return {
                ...state,
                id: action.id,
                nome: action.nome,
                abreviatura: action.abreviatura,
                excluido: action.excluido
            };
        case 'UNIDADE_MEDIDA_CLEAR':
            return {
                ...state,
                unidadeMedida: [],
                id: '',
                nome: '',
                abreviatura: '',
                excluido: ''
            };
        case "UNIDADE_MEDIDA_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_UNIDADE_MEDIDA":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state;
    }
}

import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from "react-redux";
import { analisesAction, analisesParametrosAction, parametrosAction, entidadesAction, pontosMonitoramentoAction, logAction } from '../_actions';
import { withRouter, Link } from 'react-router-dom';
import { history } from '../_helpers';
import { stringUtils } from '../_utils';
import {
    Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, Button, UncontrolledTooltip, Container, Row, Col, FormGroup, Label, Input, Form, InputGroupAddon, InputGroupText
} from 'reactstrap';
import InputMask from "react-input-mask";
import { NavBar } from '../_components/navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faCheck } from '@fortawesome/free-solid-svg-icons';
import '././styles.css';
import { ModalLog } from '../log/';
import { AnalisesParametrosTab } from '../analises'
import NumberFormat from 'react-number-format';
import Select from 'react-select';

const AnalisesEdit = (props) => {

    const dispatch = useDispatch();

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const analiseRef = useRef(null);

    useEffect(() => {
        const { match: { params } } = props;
        

        dispatch(entidadesAction.getEntidadeSelect());
        dispatch(pontosMonitoramentoAction.getPontosMonitoramentoSelect());

        if (params.id) {
            dispatch(analisesAction.getAnaliseById(params.id));
        } else {
            dispatch(analisesAction.clear());
        }
     //   console.log("analise props", props);
    }, []);

    useEffect(() => {
        if (props.analise.idEntidade != null && props.analise.idEntidade != '') {
            dispatch(pontosMonitoramentoAction.getPontosMonitoramentoSelectByEntidade(props.analise.idEntidade));
        }
    }, [props.analise.idEntidade]);

    const handleChange = prop => event => {
        dispatch(analisesAction.onChangeProps(prop, event));
    };

    const handleSubmit = event => {

        event.preventDefault();
        const { match: { params } } = props;

        if (props.analise.data === null || props.analise.data === '') {
            alert('Informe uma Data válida!');
        } else {

            //iqa: (props.analise.iqa != '' ? props.analise.iqa.replace(".","").replace(",", ".") : ''),
            let payload = {
                id: params.id ? params.id : 0,
                data: props.analise.data,
                idEntidade: props.analise.idEntidade,
                iqa: (props.analise.iqa != '' ? props.analise.iqa.toString().replace(".", 0).replace(",", ".") : 0),
                iqaCalculado: (props.analise.iqaCalculado != '' ? props.analise.iqaCalculado : 0),
                idPonto: props.analise.idPonto
            };

            if (params.id) {
                dispatch(analisesAction.editAnaliseInfo(params.id, payload));
            } else {
                dispatch(analisesAction.createAnalise(payload));
            }

        }

    };

    const handleClickHistory = event => {

        event.preventDefault();
        const { match: { params } } = props;

        if (params.id) {
            dispatch(logAction.getLog('Analise', params.id));
            toggle();
        }

    };

    const handleSelectChange = prop => event => {

        var event2 = {
            type: 'select',
            target: {
                value: event.value
            }
        };

        dispatch(analisesAction.onChangeProps(prop, event2));

    };

    const renderOptionsEntidade = () => {
        return props.entidade.entidade.map((p, i) => {
            return (
                { value: p.value, label: p.text }
            );
        });
    };

    const renderOptionsPonto = () => {
        return props.pontoMonitoramento.pontoMonitoramento.map((p, i) => {
            return (
                { value: p.value, label: p.text }
            );
        });
    };

    const { log } = props.log;

    return (
        <div>
            <NavBar />
            <Container>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col md={12}>
                                <Breadcrumb>
                                    <BreadcrumbItem><Link to="/analises" href="">Lista de Análises</Link></BreadcrumbItem>
                                    <BreadcrumbItem active>{(props.analise.id > 0 ? props.analise.id : 'Nova')}</BreadcrumbItem>
                                    {props.analise.id > 0 ?
                                        <BreadcrumbItem className="pb-0 pt-0 m-0 btn btn-muted">
                                            <a href="#" id="tagHistorico" onClick={(event) => handleClickHistory(event)}>Histórico</a>
                                            <UncontrolledTooltip placement="bottom" target="tagHistorico">
                                                Exibe o histórico de alterações do registro
                                            </UncontrolledTooltip>
                                        </BreadcrumbItem>
                                    : null}
                                </Breadcrumb>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Form onSubmit={(event) => handleSubmit(event)}>
                            <Row>
                                <Col md={4} lg={4} xl={3}>
                                    <FormGroup>
                                        <Label className="left" for="data">Data e Hora *</Label>
                                        <Input
                                            id="data"
                                            label="Data"
                                            autoFocus 
                                            className="form-control"
                                            type="datetime-local"
                                            value={props.analise.data}
                                            onChange={handleChange('data')}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4} lg={2} xl={3}>
                                    <FormGroup>
                                        <Label className="left" for="idEntidade">Entidade *</Label>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            value={renderOptionsEntidade().filter(function (option) {
                                                return option.value == (props.analise.idEntidade || '');
                                            })}
                                            onChange={handleSelectChange('idEntidade')}
                                            isSearchable
                                            id="idEntidade"
                                            name="idEntidade"
                                            options={renderOptionsEntidade()}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4} lg={4} xl={4}>
                                    <FormGroup>
                                        <Label className="left" for="idPonto">Ponto de Monitoramento *</Label>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            value={renderOptionsPonto().filter(function (option) {
                                                return option.value == (props.analise.idPonto || '');
                                            })}
                                            onChange={handleSelectChange('idPonto')}
                                            isSearchable
                                            id="idPonto"
                                            name="idPonto"
                                            options={renderOptionsPonto()}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <FormGroup>
                                        <Label className="left" for="iqa">IQA ({stringUtils.formataValor(props.analise.iqaCalculado) || ''})</Label>
                                        <NumberFormat
                                            id="iqa"
                                            label="IQA"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            className="form-control"
                                            value={props.analise.iqa}
                                            onChange={handleChange('iqa')}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <br />
                            <Row className="mb-2">
                                <Col className="left" md="8" lg="6">
                                    <span>* Campos obrigatórios</span>
                                </Col>
                                <Col className="d-flex flex-row-reverse" md="4" lg="6">
                                    <Button variant="contained" color="primary" type="submit">Salvar <FontAwesomeIcon icon={faCheck} /></Button>
                                    <Button className="mr-1" variant="contained" color="secondary" component='a' onClick={() => history.push("/analises/")} href="">Voltar <FontAwesomeIcon icon={faUndo} /></Button>
                                </Col>
                            </Row>
                            {props.analise.id !== 0 ?
                                <AnalisesParametrosTab container={analiseRef.current} />
                                :
                                <div>Salve a Análise primeiramente, para poder editar os parâmetros!</div>
                            }
                        </Form>
                    </CardBody>
                </Card>
            </Container>
            <ModalLog toggle={toggle} modal={modal} log={log} />
        </div>
    );

};

const mapStateToProps = (state) => {
    return state;
};

const connectedAnalisesEditPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(AnalisesEdit));
export { connectedAnalisesEditPage as AnalisesEdit };
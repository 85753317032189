import React from 'react';
import {
    Modal, ModalHeader, ModalBody, Table
} from 'reactstrap';
import Moment from 'react-moment';
import 'moment-timezone';

export const ModalLog = ({ modal, toggle, log }) => {

    return (         
        <div>
            <Modal isOpen={modal} toggle={toggle} size="xl" >
                <ModalHeader toggle={toggle}>Histórico do Registro</ModalHeader>
                <ModalBody>
                    <Table className="small" size="sm" hover striped bordered responsive >
                        <thead>
                            <tr>
                                <th>Data/Hora</th>
                                <th>Usuário</th>
                                <th>Tipo</th>
                                <th>Campo</th>
                                <th>Valor Antigo</th>
                                <th>Novo Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                log.map(n => {
                                    return (
                                        <tr key={n.id}>
                                            <td style={{ align: "left" }}><Moment format="DD/MM/YY HH:mm">{n.data}</Moment></td>
                                            <td style={{ align: "left" }}>{n.nomeUsuario}</td>
                                            <td style={{ align: "left" }}>{n.tipoRegistro}</td>
                                            <td style={{ align: "left" }}>{n.campo}</td>
                                            <td style={{ align: "left" }}>{n.valorAntigo}</td>
                                            <td style={{ align: "left" }}>{n.valorNovo}</td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </Table>
                </ModalBody>
            </Modal>
        </div>
    );

};
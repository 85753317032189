import React, { useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { parametrosAction } from '../_actions';
import { withRouter } from 'react-router-dom';
import { Table, Button, Container, Row, Col, Card, CardBody, CardHeader, Input, Form } from 'reactstrap';
import { NavBar } from '../_components/navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const Parametros = (props) => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(parametrosAction.getParametro());
    }, []);

    const handleChange = (event, prop, n) => {
        var index = props.parametro.parametro.findIndex(x => x.id === n.id);
        dispatch(parametrosAction.onChangePropsList(prop, event, index));
    };

    const handleSubmit = event => {

        dispatch(parametrosAction.createParametros(props.parametro.parametro));

    };

    const { parametro } = props.parametro;

    return (
        <div>
            <NavBar />
            <Container>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col md={8}>
                                <h5 className="left">Lista de Parâmetros</h5>
                            </Col>
                            <Col md={4} className="d-flex flex-row-reverse">
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Form onSubmit={(event) => handleSubmit(event)}>
                            <Table hover striped bordered responsive size="sm">
                                <thead>
                                    <tr>
                                        <th hidden>ID</th>
                                        <th hidden>Chave</th>
                                        <th>Descrição</th>
                                        <th hidden>Tipo</th>
                                        <th>Valor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        parametro.map(n => {
                                            return (
                                                <tr key={n.id}>
                                                    <td style={{ textAlign: "left", verticalAlign: "middle" }} hidden>{n.id}</td>
                                                    <td style={{ textAlign: "left", verticalAlign: "middle" }} hidden>{n.chave}</td>
                                                    <td style={{ textAlign: "left", verticalAlign: "middle" }}>{n.descricao}</td>
                                                    <td style={{ textAlign: "left", verticalAlign: "middle" }} hidden>{n.tipo}</td>
                                                    <td style={{ textAlign: "right", verticalAlign: "middle" }}>
                                                        {
                                                            <Input
                                                                id="valor"
                                                                label="Valor"
                                                                className="form-control"
                                                                type={n.tipo === 'N' ? "number" : "text"}
                                                                value={n.valor}
                                                                onChange={(event) => handleChange(event, 'valor', n)}
                                                            />
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </Table>
                            <Row>
                                <Col className="d-flex flex-row-reverse" md="12" lg="12">
                                    <Button variant="contained" color="primary" type="submit">Salvar <FontAwesomeIcon icon={faCheck} /></Button>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );

};

const mapStateToProps = (state) => {
    return {
        parametro: state.parametro
    };
};

const connectedParametrosPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(Parametros));
export { connectedParametrosPage as Parametros };
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { cemadenAction, cursosDaguaAction, pontosMonitoramentoAction, logAction, fontesExternasAction, tiposParametrosAction, parametrosAguaAction, entidadesAction } from '../_actions';
import { withRouter, Link } from 'react-router-dom';
import { history } from '../_helpers';
import { stringUtils } from '../_utils';
import {
    Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, Button, Container, Row, Col, FormGroup, Label, Input, Form,
    TabContent, TabPane, Nav, NavItem, NavLink, InputGroup, InputGroupAddon, InputGroupText, UncontrolledTooltip
} from 'reactstrap';
import { NavBar } from '../_components/navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faCheck, faEye } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import Chart from 'react-apexcharts';
import moment from 'moment';

import '././styles.css';
import { ModalLog } from '../log/';
import Select from 'react-select';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    KmlLayer,
    Marker,
} from "react-google-maps";
import Geocode from "react-geocode";
import alerts from '../_components/alerts';

Geocode.setApiKey({ key: process.env.REACT_APP_MAP_KEY });
Geocode.enableDebug();

const PontosMonitoramentosEdit = (props) => {

    const _ = require("lodash");
    const { SearchBox } = require("react-google-maps/lib/components/places/SearchBox");
    const { StandaloneSearchBox } = require("react-google-maps/lib/components/places/StandaloneSearchBox");
    const { compose, lifecycle, withProps, withState, withHandlers } = require("recompose");

    const dispatch = useDispatch();
    const [, forceUpdate] = React.useState(0);

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [zoom, setZoom] = useState(14);
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(() => {
        const { match: { params } } = props;

        // selects
        dispatch(tiposParametrosAction.clear());
        dispatch(tiposParametrosAction.getTipoParametroSelect());
        dispatch(cursosDaguaAction.clear());
        dispatch(cursosDaguaAction.getCursoDaguaSelect());
        dispatch(fontesExternasAction.clear());
        dispatch(fontesExternasAction.getFonteExternaSelect());

        let filterModel = {
            Page: 1,
            Limit: 1000,
            Term: ''
        };
        dispatch(parametrosAguaAction.getParametrosAgua(filterModel));
        dispatch(entidadesAction.getEntidade(filterModel));

        if (params.id) {
            dispatch(pontosMonitoramentoAction.getPontosMonitoramentoById(params.id));
        } else {
            dispatch(pontosMonitoramentoAction.clear());
        }

    }, []);

    useEffect(() => {
        if (props.pontoMonitoramento.idFonteExterna > 0) {
            let filterCemaden = {
                IdPonto: props.pontoMonitoramento.id,
                Periodos: 12
            };
            dispatch(cemadenAction.getCemaden(filterCemaden));
        }

    }, [props.pontoMonitoramento.idFonteExterna]);

    //useEffect(() => {


    //}, [latitude, longitude]);

    const handleChange = prop => event => {
        dispatch(pontosMonitoramentoAction.onChangeProps(prop, event));
    };

    const handleSubmit = event => {

        event.preventDefault();
        const { match: { params } } = props;

        var bool_referencia = props.pontoMonitoramento.codigoReferencia != '' && props.pontoMonitoramento.codigoReferencia != 0 && props.pontoMonitoramento.codigoReferencia != null;

        if (props.pontoMonitoramento.nome == null || props.pontoMonitoramento.nome == '') {
            alerts.warning('Informe um Nome!');
        } else if ((props.pontoMonitoramento.idFonteExterna != null && props.pontoMonitoramento.idFonteExterna != '') && (props.pontoMonitoramento.codigoReferencia == '' || props.pontoMonitoramento.codigoReferencia == 0 || props.pontoMonitoramento.codigoReferencia == null)) {
            alerts.warning('Informe um Código de Referência para a fonte escolhida!');
        } else if ((props.pontoMonitoramento.idFonteExterna === null || props.pontoMonitoramento.idFonteExterna === '') && bool_referencia) {
            alerts.warning('Não informe código da estação quando não existir uma fonte de dados externa!');
        } else if (props.pontoMonitoramento.latitude == null || props.pontoMonitoramento.latitude == '' || props.pontoMonitoramento.latitude == 0) {
            alerts.warning('Informe uma latitude!');
        } else if (props.pontoMonitoramento.longitude == null || props.pontoMonitoramento.longitude == '' || props.pontoMonitoramento.longitude == 0) {
            alerts.warning('Informe uma longitude!');
        } else {

            let payload = {
                id: params.id ? params.id : 0,
                nome: props.pontoMonitoramento.nome,
                codigo: props.pontoMonitoramento.codigo,
                latitude: props.pontoMonitoramento.latitude,
                longitude: props.pontoMonitoramento.longitude,
                codigoReferencia: props.pontoMonitoramento.codigoReferencia,
                idCursoDagua: props.pontoMonitoramento.idCursoDagua,
                idFonteExterna: props.pontoMonitoramento.idFonteExterna,
                pontosEntidades: props.pontoMonitoramento.pontosEntidades,
                pontosParametros: props.pontoMonitoramento.pontosParametros
            };

            //console.log('PAYLOAD', payload)

            if (params.id) {
                dispatch(pontosMonitoramentoAction.editPontosMonitoramentoInfo(params.id, payload));
            } else {
                dispatch(pontosMonitoramentoAction.createPontosMonitoramento(payload));
            }

        }

    };

    const handleSelectChange = prop => event => {

        var event2 = {
            type: 'select',
            target: {
                value: event.value
            }
        };

        dispatch(pontosMonitoramentoAction.onChangeProps(prop, event2));

    };

    const handleVisualizarSiteAna = (event) => {
        event.preventDefault();
        if (props.pontoMonitoramento.codigoReferencia != null && props.pontoMonitoramento.codigoReferencia != '' && props.pontoMonitoramento.codigoReferencia != 0) {
            window.open('https://www.snirh.gov.br/hidrotelemetria/gerarGrafico.aspx');
        }
    }

    const handleVisualizarSiteCemaden = (event) => {
        event.preventDefault();

        if (props.pontoMonitoramento.codigoReferencia != null && props.pontoMonitoramento.codigoReferencia != '' && props.pontoMonitoramento.codigoReferencia != 0) {
            window.open('http://sjc.salvar.cemaden.gov.br/resources/graficos/cemaden/hidro/hidrologica_interativo.html?est=' + props.pontoMonitoramento.codigoReferencia + '&uf=RS');
        }
    }

    const handleClickHistory = event => {

        event.preventDefault();
        const { match: { params } } = props;

        if (params.id) {
            dispatch(logAction.getLog('PontoMonitoramento', params.id));
            toggle();
        }

    };

    function existsParametro(id) {
        if (props && props.pontoMonitoramento && props.pontoMonitoramento.pontosParametros && props.pontoMonitoramento.pontosParametros.length > 0) {
            const exists = props.pontoMonitoramento.pontosParametros.find(parametroAgua => parametroAgua.idParametro === id);
            return exists;
        } else {
            return false;
        }
    }

    function existsEntidade(id) {
        if (props && props.pontoMonitoramento && props.pontoMonitoramento.pontosEntidades && props.pontoMonitoramento.pontosEntidades.length > 0) {
            const exists = props.pontoMonitoramento.pontosEntidades.find(entidade => entidade.idEntidade === id);
            return exists;
        } else {
            return false;
        }
    }

    const handleOnChangeMultiCheckbox = e => {

        const isChecked = e.target.checked;

        if (isChecked) {
            props.pontoMonitoramento.pontosParametros.push({ idParametro: parseInt(e.target.name), idPonto: props.pontoMonitoramento.id != '' ? props.pontoMonitoramento.id : 0 });
        } else {
            props.pontoMonitoramento.pontosParametros = props.pontoMonitoramento.pontosParametros.filter(parametroAgua => parametroAgua.idParametro !== parseInt(e.target.name));
        }

        forceUpdate(n => !n);

    };

    const handleOnChangeMultiCheckboxEntidade = e => {

        const isChecked = e.target.checked;

        if (isChecked) {
            props.pontoMonitoramento.pontosEntidades.push({ idEntidade: parseInt(e.target.name), idPonto: props.pontoMonitoramento.id != '' ? props.pontoMonitoramento.id : 0 });
            //console.log("pontosEntidades", props.pontoMonitoramento.pontosEntidades)
        } else {
            props.pontoMonitoramento.pontosEntidades = props.pontoMonitoramento.pontosEntidades.filter(entidade => entidade.idEntidade !== parseInt(e.target.name));
            // console.log("entrei no filter")
        }

        forceUpdate(n => !n);

    };

    const renderOptionsCursoDagua = () => {
        return props.cursoDagua.cursoDagua.map((p, i) => {
            return (
                { value: p.value, label: p.text }
            );
        });
    };

    const renderOptionsFonteExterna = () => {
        return props.fonteExterna.fonteExterna.map((p, i) => {
            return (
                { value: p.value, label: p.text }
            );
        });
    };

    const onMarkerDragEnd = (event) => {

        let newLat = event.latLng.lat(),
            newLng = event.latLng.lng();

        var event_lat = {
            type: 'text',
            target: {
                value: newLat
            }
        };

        var event_lon = {
            type: 'text',
            target: {
                value: newLng
            }
        };

        //setLatitude(event_lat);
        //setLongitude(event_lon);
        dispatch(pontosMonitoramentoAction.onChangeProps('latitude', event_lat));
        dispatch(pontosMonitoramentoAction.onChangeProps('longitude', event_lon));

    };

    const MapMarkerSearchBox = compose(
        withProps({
            googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDX5xiv8klIvdWN5yvY0zaqFF2M9z_4mgI&v=3.38&libraries=geometry,drawing,places",
            loadingElement: <div style={{ height: `100%` }} />,
            containerElement: <div style={{ height: `490px` }} />,
            mapElement: <div style={{ height: `100%` }} />,
        }),
        withState('zoom', 'onZoomChange', zoom),
        withHandlers(() => {
            const refs = {
                map: undefined,
            }

            return {
                onMapMounted: () => ref => {
                    refs.map = ref
                },
                //onZoomChanged: ({ onZoomChange }) => () => {
                //    onZoomChange(refs.map.getZoom());
                //    setZoom(refs.map.getZoom());
                //},
                componentWillMount() {
                    this.setState({
                        places: [],
                        onSearchBoxMounted: ref => {
                            refs.searchBox = ref;
                        },
                        onPlacesChanged: () => {
                            const places = refs.searchBox.getPlaces();

                            this.setState({
                                places,
                            });

                            const nextMarkers = places.map(place => ({
                                position: place.geometry.location,
                            }));
                            const nextCenter = _.get(nextMarkers, '0.position', this.state.center);

                            this.setState({
                                center: nextCenter,
                                markers: nextMarkers,
                            });
                        },
                    })
                },
            }
        }),
        withScriptjs,
        withGoogleMap
    )(props2 =>
        <div data-standalone-searchbox="">
            <StandaloneSearchBox
                ref={props2.onSearchBoxMounted}
                bounds={props2.bounds}
                onPlacesChanged={props2.onPlacesChanged}
            >
                <input
                    type="text"
                    placeholder="Pesquisa de endereço"
                    style={{
                        boxSizing: `border-box`,
                        border: `1px solid transparent`,
                        width: `100%`,
                        height: `32px`,
                        padding: `0 12px`,
                        borderRadius: `3px`,
                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                        fontSize: `14px`,
                        outline: `none`,
                        textOverflow: `ellipses`,
                    }}
                />
            </StandaloneSearchBox>
            <GoogleMap
                defaultCenter={{ lat: parseFloat(props.pontoMonitoramento.latitude) || -29.6778158, lng: parseFloat(props.pontoMonitoramento.longitude) || -51.0858696 }}
                zoom={props2.zoom}
                ref={props2.onMapMounted}
                onZoomChanged={props2.onZoomChanged}
            >
                <Marker
                    position={{ lat: parseFloat(props.pontoMonitoramento.latitude) || -29.6778158, lng: parseFloat(props.pontoMonitoramento.longitude) || -51.0858696 }}
                    draggable={true}
                    onDragEnd={(event) => onMarkerDragEnd(event)}
                >
                </Marker>
            </GoogleMap>
        </div>
    );

    const { log } = props.log;

    return (
        <div>
            <NavBar />
            <Container>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col md={12}>
                                <Breadcrumb>
                                    <BreadcrumbItem><Link to="/pontosMonitoramento" href="">Lista de Pontos de Monitoramento</Link></BreadcrumbItem>
                                    <BreadcrumbItem active>{(props.pontoMonitoramento.id > 0 ? <span>{props.pontoMonitoramento.id} - {props.pontoMonitoramento.nome}</span> : 'Novo')}</BreadcrumbItem>
                                    {props.pontoMonitoramento.id > 0 ?
                                        <BreadcrumbItem className="pb-0 pt-0 m-0 btn btn-muted">
                                            <a href="#" id="tagHistorico" onClick={(event) => handleClickHistory(event)}>Histórico</a>
                                            <UncontrolledTooltip placement="bottom" target="tagHistorico">
                                                Exibe o histórico de alterações do registro
                                            </UncontrolledTooltip>
                                        </BreadcrumbItem>
                                        : null}
                                </Breadcrumb>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Form onSubmit={(event) => handleSubmit(event)}>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === '1' })}
                                        onClick={() => { toggleTab('1'); }}
                                    >
                                        Dados do Ponto de Monitoramento
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === '2' })}
                                        onClick={() => { toggleTab('2'); }}
                                    >
                                        Parâmetros de Água
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === '3' })}
                                        onClick={() => { toggleTab('3'); }}
                                    >
                                        Entidades
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <Row>
                                        <Col lg={5}>
                                            <Row>
                                                <Col md={12}>
                                                    <FormGroup>
                                                        <Label className="left" for="nome">Nome *</Label>
                                                        <Input
                                                            id="nome"
                                                            label="Nome"
                                                            name="nome"
                                                            maxLength="60"
                                                            className="form-control text-uppercase"
                                                            value={props.pontoMonitoramento.nome}
                                                            onChange={handleChange('nome')}
                                                            onInput={stringUtils.toInputUppercase}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={8}>
                                                    <FormGroup>
                                                        <Label className="left" for="idCursoDagua">Curso D'água</Label>
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            value={renderOptionsCursoDagua().filter(function (option) {
                                                                return option.value == (props.pontoMonitoramento.idCursoDagua || '');
                                                            })}
                                                            onChange={handleSelectChange('idCursoDagua')}
                                                            isSearchable
                                                            id="idCursoDagua"
                                                            name="idCursoDagua"
                                                            options={renderOptionsCursoDagua()}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <Label className="left" for="codigo">Identificação</Label>
                                                        <Input
                                                            id="codigo"
                                                            name="codigo"
                                                            label="Código"
                                                            maxLength="30"
                                                            className="form-control"
                                                            value={props.pontoMonitoramento.codigo || ''}
                                                            onChange={handleChange('codigo')}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label className="left" for="latitude">Latitude *</Label>
                                                        <Input
                                                            id="latitude"
                                                            label="Latitude"
                                                            name="latitude"
                                                            maxLength="30"
                                                            className="form-control"
                                                            value={props.pontoMonitoramento.latitude || ''}
                                                            onChange={handleChange('latitude')}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label className="left" for="longitude">Longitude *</Label>
                                                        <Input
                                                            id="longitude"
                                                            name="longitude"
                                                            label="Longitude"
                                                            maxLength="30"
                                                            className="form-control"
                                                            value={props.pontoMonitoramento.longitude || ''}
                                                            onChange={handleChange('longitude')}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={8}>
                                                    <FormGroup>
                                                        <Label className="left" for="idFonteExterna">Fonte de Dados Externa</Label>
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            value={renderOptionsFonteExterna().filter(function (option) {
                                                                return option.value == (props.pontoMonitoramento.idFonteExterna || '');
                                                            })}
                                                            onChange={handleSelectChange('idFonteExterna')}
                                                            isSearchable
                                                            id="idFonteExterna"
                                                            name="idFonteExterna"
                                                            options={renderOptionsFonteExterna()}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <Label className="left" for="codigoReferencia">Código</Label>
                                                        <Input
                                                            id="codigoReferencia"
                                                            label="Código"
                                                            maxLength="30"
                                                            className="form-control"
                                                            value={props.pontoMonitoramento.codigoReferencia || ''}
                                                            onChange={handleChange('codigoReferencia')}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            {(props.pontoMonitoramento.idFonteExterna != null && props.pontoMonitoramento.idFonteExterna != '' ?
                                                <Row>
                                                    <Col md={12}>
                                                        <Label className="left">Chuva acumulada em 12 horas</Label>
                                                        <Chart
                                                            options={props.cemaden.graficoPluviometriaAcumulada.options}
                                                            series={props.cemaden.graficoPluviometriaAcumulada.series}
                                                            type="line"
                                                            height="200"
                                                        />
                                                    </Col>
                                                </Row>
                                                : '')}
                                        </Col>
                                        <Col lg={7}>
                                            <MapMarkerSearchBox />
                                            {props.pontoMonitoramento.idFonteExterna && props.pontoMonitoramento.idFonteExterna != 0 && props.pontoMonitoramento.idFonteExterna == 1 &&
                                                <Row className="mt-5 mb-2 ml-2">
                                                    <Button variant="contained" onClick={(event) => handleVisualizarSiteAna(event)} color="primary" type="submit">Visualizar no site da ANA <FontAwesomeIcon icon={faEye} /></Button>
                                                </Row>
                                            }
                                            {props.pontoMonitoramento.idFonteExterna && props.pontoMonitoramento.idFonteExterna != 0 && props.pontoMonitoramento.idFonteExterna == 2 &&
                                                <Row className="mt-5 mb-2 ml-2">
                                                    <Button variant="contained" onClick={(event) => handleVisualizarSiteCemaden(event)} color="primary" type="submit">Visualizar no site do CEMADEN <FontAwesomeIcon icon={faEye} /></Button>
                                                </Row>
                                            }
                                        </Col>

                                    </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                    <FormGroup>
                                        <Label className="left mt-2 mb-2" for="nome">Relação dos parâmetros de água analisados neste ponto de monitoramento:</Label>
                                        <div className="selectgroup-pills">
                                            <Row>
                                                {
                                                    //selectgroup 
                                                    props.parametroAgua.parametroAgua.map(n => {
                                                        return (
                                                            (n.id !== '' ?
                                                                <Col sm={12} md={4} lg={4} xl={3} key={`${parseInt(n.id)}`} >
                                                                    <Label className="left" style={{ width: '100%' }} check key={`${parseInt(n.id)}`}>
                                                                        <Input
                                                                            type="checkbox"
                                                                            name={`${parseInt(n.id)}`}
                                                                            className="selectgroup-input"
                                                                            checked={existsParametro(parseInt(n.id)) || false}
                                                                            onChange={handleOnChangeMultiCheckbox} />
                                                                        <span className="selectgroup-button btn-block">{n.nome}</span>
                                                                    </Label>
                                                                </Col>
                                                                : null)
                                                        );
                                                    })
                                                }
                                            </Row>
                                        </div>
                                    </FormGroup>
                                </TabPane>
                                <TabPane tabId="3">
                                    <FormGroup>
                                        <Label className="left mt-2 mb-2" for="nome">Relação das entidades do ponto de monitoramento:</Label>
                                        <div className="selectgroup-pills">
                                            <Row>
                                                {
                                                    //selectgroup 
                                                    props.entidade.entidade.map(n => {
                                                        return (
                                                            (n.id !== '' ?
                                                                <Col sm={12} md={4} lg={4} xl={3} key={`${parseInt(n.id)}`} >
                                                                    <Label className="left" style={{ width: '100%' }} check key={`${parseInt(n.id)}`}>
                                                                        <Input
                                                                            type="checkbox"
                                                                            name={`${parseInt(n.id)}`}
                                                                            className="selectgroup-input"
                                                                            checked={existsEntidade(parseInt(n.id)) || false}
                                                                            onChange={handleOnChangeMultiCheckboxEntidade} />
                                                                        <span className="selectgroup-button btn-block">{n.nome}</span>
                                                                    </Label>
                                                                </Col>
                                                                : null)
                                                        );
                                                    })
                                                }
                                            </Row>
                                        </div>
                                    </FormGroup>
                                </TabPane>
                                <br />
                                <Row>
                                    <Col className="left mt-2" md="8" lg="6">
                                        <span>* Campos obrigatórios</span>
                                    </Col>
                                    <Col className="d-flex flex-row-reverse mt-3" md="4" lg="6">
                                        <Button variant="contained" color="primary" type="submit">Salvar <FontAwesomeIcon icon={faCheck} /></Button>
                                        <Button className="mr-1" variant="contained" color="secondary" component='a' onClick={() => history.push("/pontosMonitoramento/")} href="">Voltar <FontAwesomeIcon icon={faUndo} /></Button>
                                    </Col>
                                </Row>
                            </TabContent>
                        </Form>
                    </CardBody>
                </Card>
            </Container>
            <ModalLog toggle={toggle} modal={modal} log={log} />
        </div >
    );

};

const mapStateToProps = (state) => {
    return state;
};

const connectedPontoMonitoramentosEditPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(PontosMonitoramentosEdit));
export { connectedPontoMonitoramentosEditPage as PontosMonitoramentosEdit };
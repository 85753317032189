import { crudService } from '../_services';
import { history } from '../_helpers';
import alerts from '../_components/alerts';
import Swal from 'sweetalert2';
import { stringUtils } from '../_utils';

export const parametrosAguaAction = {
    getParametrosAgua,
    getParametrosAguaSelect,
    getParametrosAguaTipo1Select,
    getParametrosAguaTipo2Select,
    getParametrosAguaPublic,
    getParametrosAguaById,
    onChangeProps,
    editParametrosAguaInfo,
    createParametrosAgua,
    deleteParametrosAguaById,
    clear
};

let filterModel = {
    Page: 1,
    Limit: 10,
    Term: ''
};

function getParametrosAgua(filterModel) {
    return dispatch => {
        let apiEndpoint = 'parametrosAgua' + stringUtils.serialize(filterModel);
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    dispatch(changeParametrosAguasList(response.data.items, response.data.totalPages));
                }                
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function getParametrosAguaSelect() {
    return dispatch => {
        let apiEndpoint = 'parametrosAgua/select';
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    response.data.unshift({ value: '', text: ' SELECIONE ...' });
                    //console.log("response.data", response.data)
                    dispatch(changeParametrosAguasList(response.data, null));
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}

function getParametrosAguaTipo1Select() {
    return dispatch => {
        let apiEndpoint = 'parametrosAgua/select/tipo1';
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    response.data.unshift({ value: '', text: ' SELECIONE ...' });
                 //   console.log("response.data", response.data)
                    dispatch(changeParametrosAguasList(response.data, null));
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}

function getParametrosAguaTipo2Select() {
    return dispatch => {
        let apiEndpoint = 'parametrosAgua/select/tipo2';
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    response.data.unshift({ value: '', text: ' SELECIONE ...' });
               //     console.log("response.data", response.data)
                    dispatch(changeParametrosAguasList(response.data, null));
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}

function getParametrosAguaPublic() {
    return dispatch => {
        let apiEndpoint = 'parametrosAgua/public';
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    dispatch(changeParametrosAguasList(response.data, null));
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function createParametrosAgua(payload) {
    return dispatch => {
        let apiEndpoint = 'parametrosAgua/';
        crudService.post(apiEndpoint, payload)
            .then((response) => {
                if (response.request.status === 200) {
                    var ret = JSON.parse(response.request.responseText);
                    dispatch(updatedUserInfo());
                    history.push(ret.path);
                    alerts.success(ret.message);
                } else {
                    alerts.warning(response.request.responseText);
                }
            });
    };
}
function getParametrosAguaById(id) {
    return dispatch => {
        let apiEndpoint = 'parametrosAgua/' + id;
        crudService.get(apiEndpoint)
            .then((response) => {
                dispatch(editParametrosAguasDetails(response.data));
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function onChangeProps(props, event) {
    return dispatch => {
        if (event.target.type === 'checkbox') {
            dispatch(handleOnChangeProps(props, event.target.checked));
        } else {
            dispatch(handleOnChangeProps(props, event.target.value));
        }
    };
}
function editParametrosAguaInfo(id, payload) {
    return dispatch => {
        let apiEndpoint = 'parametrosAgua/' + id;
        crudService.put(apiEndpoint, payload)
            .then((response) => {
                if (response.request.status === 200) {
                    var ret = JSON.parse(response.request.responseText);
                    dispatch(updatedUserInfo());
                    history.push(ret.path);
                    alerts.success(ret.message);
                } else {
                    alerts.warning(response.request.responseText);
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function deleteParametrosAguaById(id) {
    return dispatch => {

        Swal.fire({
            title: 'Confirmação',
            text: 'Tem certeza de que deseja excluir o registro?',
            icon: 'warning',
            buttons: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: "Não, cancelar!",
            dangerMode: true,
            heightAuto: false
        })
        .then((willDelete) => {
            if (willDelete.value) {

                let apiEndpoint = 'parametrosAgua/' + id;
                crudService.deleteDetail(apiEndpoint)
                    .then((response) => {
                        if (response.request.status === 200) {
                            var ret = JSON.parse(response.request.responseText);
                            dispatch(deleteParametrosAguasDetails());
                            dispatch(getParametrosAgua(filterModel));
                            alerts.success(ret.message);
                        } else {
                            alerts.warning(response.request.responseText);
                        }
                    }).catch((err) => {
                        console.log(err);
                        alerts.error(err);
                    });

            }
        });

    };
}
export function changeParametrosAguasList(parametroAgua, totalPages) {
    return {
        type: "FETECHED_ALL_PARAMETRO_AGUA",
        parametroAgua: parametroAgua,
        totalPages: totalPages
    };
}
export function clear() {
    return {
        type: "PARAMETRO_AGUA_CLEAR"
    };
}
export function handleOnChangeProps(props, value) {
    return {
        type: "HANDLE_ON_CHANGE_PARAMETRO_AGUA",
        props: props,
        value: value
    };
}
export function editParametrosAguasDetails(parametroAgua) {
    return {
        type: "PARAMETRO_AGUA_DETAIL",
        id: parametroAgua.id,
        nome: parametroAgua.nome,
        idTipo: parametroAgua.idTipo,
        idUnidadeMedida: parametroAgua.idUnidadeMedida,
        tipoValidacao: parametroAgua.tipoValidacao,
        limiteMinimo: parametroAgua.limiteMinimo,
        limiteMaximo: parametroAgua.limiteMaximo,
        unidadeMedida: parametroAgua.unidadeMedida,
        parametrosAguaFaixa: parametroAgua.parametrosAguaFaixa,
        excluido: parametroAgua.excluido
    };
}
export function updatedUserInfo() {
    return {
        type: "PARAMETRO_AGUA_UPDATED"
    };
}
export function createUserInfo() {
    return {
        type: "PARAMETRO_AGUA_CREATED_SUCCESSFULLY"
    };
}
export function deleteParametrosAguasDetails() {
    return {
        type: "DELETED_PARAMETRO_AGUA_DETAILS"
    };
}
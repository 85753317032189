import React, { useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { parametrosAguaAction, parametrosAguaFaixaAction } from '../_actions';
import { withRouter } from 'react-router-dom';
import { Table, Button, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import alerts from '../_components/alerts';
import { faBroom, faCheck, faPen, faTrash, faStar } from '@fortawesome/free-solid-svg-icons';
import 'moment-timezone';
import '././styles.css';
import { stringUtils } from '../_utils';
import NumberFormat from 'react-number-format';
import InputColor from 'react-input-color';

const ParametrosAguaFaixaTab = (props) => {

    const dispatch = useDispatch();
    const [, forceUpdate] = React.useState(0);

    useEffect(() => {

        //console.log(props);

    }, [props.parametroAgua.ParametrosAguaFaixa]);

    const handleChange = prop => event => {

        dispatch(parametrosAguaFaixaAction.onChangeProps(prop, event));

    };

    const handleChangeColor = prop => event => {

        var event2 = {
            type: 'color',
            target: {
                value: event.hex
            }
        };

        dispatch(parametrosAguaFaixaAction.onChangeProps(prop, event2));

    };
    

    const handleClick = event => {

        event.preventDefault();
        const { match: { params } } = props;

        if (props.parametroAgua.limiteMinimo == null) {
            props.parametroAgua.limiteMinimo = 0;
        }

        if (props.parametroAguaFaixa.nome === null || props.parametroAguaFaixa.nome === '') {
            alerts.alert('Informe um nome válido!');
        } else if (props.parametroAguaFaixa.faixaInicial === null || props.parametroAguaFaixa.faixaInicial === '') {
            alerts.alert('Faixa inicial precisa ser preenchida');
        } else if (props.parametroAguaFaixa.faixaFinal === null || props.parametroAguaFaixa.faixaFinal === '') {
            alerts.alert('Faixa final precisa ser preenchida');
        } else if (props.parametroAguaFaixa.faixaInicial < props.parametroAgua.limiteMinimo || props.parametroAgua.limiteMinimo == null) {
            alerts.alert('Faixa está fora do limite mínimo de medição do parâmetro!');
        } else if (props.parametroAguaFaixa.faixaFinal > props.parametroAgua.limiteMaximo || props.parametroAgua.limiteMaximo == null) {
            alerts.alert('Faixa está fora do limite máximo de medição do parâmetro!');
        } else {

            let payload = {
                id: props.parametroAguaFaixa.id ? props.parametroAguaFaixa.id : 0,
                idParametroAgua: params.id ? params.id : 0,
                nome: props.parametroAguaFaixa.nome || '',
                cor: props.parametroAguaFaixa.cor || '',
                faixaInicial: props.parametroAguaFaixa.faixaInicial || 0,
                faixaFinal: props.parametroAguaFaixa.faixaFinal || 0
            };

            if (params.id) {

                if (props.parametroAguaFaixa.id) {
                    dispatch(parametrosAguaFaixaAction.editParametroAguaFaixaInfo(props.parametroAguaFaixa.id, payload));
                } else {
                    dispatch(parametrosAguaFaixaAction.createParametroAguaFaixa(payload));
                }

            } else {

                props.parametroAgua.ParametrosAguaFaixa.push(payload);
                forceUpdate(n => !n);

            }

            //handleClean(event);
        }

    };

    const handleClean = event => {

        event.preventDefault();
        const { match: { params } } = props;

        dispatch(parametrosAguaFaixaAction.clear());

        if (params.id) {
            //dispatch(profissionaisAction.getProfissionalById(params.id));
        } else {
            document.getElementById("nome").focus();
        }

    };

    const handleEdit = (event, id) => {
        if (id) {
            dispatch(parametrosAguaFaixaAction.getParametroAguaFaixaById(id));
            //dispatch(cidadesAction.getCidadeSelect(props.parametroAguaFaixa.idEstado, 2));
        }
    };

    const handleDelete = (event, obj) => {

        if (obj.id) {

            dispatch(parametrosAguaFaixaAction.deleteParametroAguaFaixaById(obj.id));

        }

    };

    return (
        <div>
            <fieldset disabled={props.disabled}>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <Label className="left" for="nome">Nome
                                <InputColor
                                    className="ml-1"
                                    initialValue={props.parametroAguaFaixa.cor || "#5e72e4"}
                                    onChange={handleChangeColor('cor')}
                                    placement="right"
                                /></Label>
                            <Input
                                id="nome"
                                label="Nome"
                                className="form-control"
                                value={props.parametroAguaFaixa.nome || ''}
                                onChange={handleChange('nome')}
                                onInput={stringUtils.toInputUppercase}
                                style={{ color: props.parametroAguaFaixa.cor }}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label className="left" for="faixaInicial">Faixa Inicial *</Label>
                            <Input
                                id="faixaInicial"
                                label="Faixa Inicial"
                                className="form-control"
                                required={true}
                                type="number"
                                value={props.parametroAguaFaixa.faixaInicial || ''}
                                onChange={handleChange('faixaInicial')}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label className="left" for="faixaFinal">Faixa Final *</Label>
                            <Input
                                id="faixaFinal"
                                label="Faixa Inicial"
                                className="form-control"
                                required={true}
                                type="number"
                                value={props.parametroAguaFaixa.faixaFinal || ''}
                                onChange={handleChange('faixaFinal')}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            <Row>
                <Col md={12}>
                    <hr />
                </Col>
            </Row>

            <Row className="mb-3">
                <Col className="d-flex flex-row-reverse" md="12" lg="12">
                    <Button variant="contained" color="success" onClick={(event) => handleClick(event)}>Salvar Faixa <FontAwesomeIcon icon={faCheck} /></Button>
                    <Button className="mr-1" variant="contained" color="secondary" onClick={(event) => handleClean(event)}>Limpar <FontAwesomeIcon icon={faBroom} /></Button>
                </Col>
            </Row>
            <Table className="small" hover striped bordered responsive size="sm">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Faixa Inicial</th>
                        <th>Faixa Final</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.parametroAgua.parametrosAguaFaixa.map(n => {
                            return (
                                <tr key={n.id}>
                                    <td style={{ textAlign: "left" }}>{n.nome}<FontAwesomeIcon className="ml-2" style={{ color: n.cor }} icon={faStar} /></td>
                                    <td style={{ width: "150px", align: "right" }}>{n.faixaInicial}</td>
                                    <td style={{ width: "150px", align: "right" }}>{n.faixaFinal}</td>
                                    <td style={{ width: "80px" }}>
                                        {n.idProfissional !== null ? <Button
                                            color="primary" size="sm"
                                            onClick={(event) => handleEdit(event, n.id)}>
                                            <FontAwesomeIcon icon={faPen} />
                                        </Button> : ''}
                                        <span>{'\u00A0'}</span>
                                        <Button color="danger" size="sm"
                                            onClick={(event) => handleDelete(event, n)}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </Table>
            </fieldset>
        </div >
    );

};

const mapStateToProps = (state) => {
    return state;
};

const connectedParametrosAguaFaixaTabPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(ParametrosAguaFaixaTab));
export { connectedParametrosAguaFaixaTabPage as ParametrosAguaFaixaTab };
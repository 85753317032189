import { crudService } from '../_services';
import { camadasAction } from '../_actions';
import alerts from '../_components/alerts';
import Swal from 'sweetalert2';
import { camada } from '../_reducers/camadas.reducer';

export const uploadsAction = {
    onChangeProps,
    createUpload,
    deleteUploadById,
    clear
};

function createUpload(payload) {
    return dispatch => {
        alerts.waiting();
        let apiEndpoint = 'uploads/';
        crudService.post(apiEndpoint, payload)
            .then((response) => {
                if (response.request.status === 200) {
                    var ret = JSON.parse(response.request.responseText);
                    dispatch(updatedUserInfo());
                    alerts.success(ret.message);

                    dispatch(clear());

                    //refresh parent
                    if (ret.path.includes('camada')) {
                        dispatch(camadasAction.getCamadaById(payload.idCamada));
                    }

                } else {
                    alerts.warning(response.request.responseText);
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function onChangeProps(props, event) {
    return dispatch => {
        if (props === 'arquivo') {
            dispatch(handleOnChangeProps(props, event));
        } else {
            if (event.target.type === 'checkbox') {
                dispatch(handleOnChangeProps(props, event.target.checked));
            } else {
                dispatch(handleOnChangeProps(props, event.target.value));
            }            
        }
    };
}
function deleteUploadById(id) {
    return dispatch => {

        Swal.fire({
            title: 'Confirmação',
            text: 'Tem certeza de que deseja excluir o registro?',
            icon: 'warning',
            buttons: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: "Não, cancelar!",
            dangerMode: true,
            heightAuto: false
        })
        .then((willDelete) => {
            if (willDelete.value) {

                alerts.waiting();
                let apiEndpoint = 'uploads/' + id;
                crudService.deleteDetail(apiEndpoint)
                    .then((response) => {
                        if (response.request.status === 200) {
                            var ret = JSON.parse(response.request.responseText);
                            dispatch(deleteUploadsDetails());
                            alerts.success(ret.message);
                        } else {
                            alerts.warning(response.request.responseText);
                        }

                        //refresh parent
                        if (ret.path.includes('camada')) {
                            dispatch(camadasAction.getCamadaById(ret.key));
                        }

                    }).catch((err) => {
                        console.log(err);
                        alerts.error(err);
                    });

            }
        });

    };
}
export function clear() {
    return {
        type: "UPLOAD_CLEAR"
    };
}
export function handleOnChangeProps(props, value) {
    return {
        type: "HANDLE_ON_CHANGE_UPLOAD",
        props: props,
        value: value
    };
}
export function updatedUserInfo() {
    return {
        type: "UPLOAD_UPDATED"
    };
}
export function createUserInfo() {
    return {
        type: "UPLOAD_CREATED_SUCCESSFULLY"
    };
}
export function deleteUploadsDetails() {
    return {
        type: "DELETED_UPLOAD_DETAILS"
    };
}
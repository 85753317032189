import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { entidadesAction, logAction } from '../_actions';
import { withRouter, Link } from 'react-router-dom';
import { history } from '../_helpers';
import { stringUtils } from '../_utils';
import {
    Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, Button, UncontrolledTooltip, Container, Row, Col, FormGroup, Label, Input, Form, InputGroup, InputGroupAddon, InputGroupText
} from 'reactstrap';
import InputMask from "react-input-mask";
import { NavBar } from '../_components/navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faCheck } from '@fortawesome/free-solid-svg-icons';
import '././styles.css';
import { ModalLog } from '../log/';

const EntidadesEdit = (props) => {

    const dispatch = useDispatch();

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    useEffect(() => {
        const { match: { params } } = props;

        if (params.id) {
            dispatch(entidadesAction.getEntidadeById(params.id));
        } else {
            dispatch(entidadesAction.clear());
        }

    }, []);

    const handleChange = prop => event => {
        dispatch(entidadesAction.onChangeProps(prop, event));
    };

    const handleSubmit = event => {

        event.preventDefault();
        const { match: { params } } = props;

        if (props.entidade.nome === null || props.entidade.nome === '') {
            alert('Informe um Nome válido!');
        } else {

            let payload = {
                id: params.id ? params.id : 0,
                nome: props.entidade.nome,
                responsavel: props.entidade.responsavel,
                email: props.entidade.email,
                telefone: (props.entidade.telefone ? props.entidade.telefone.replace(/\D/g, '') : null),
                celular: (props.entidade.celular ? props.entidade.celular.replace(/\D/g, '') : null)
            };

            if (params.id) {
                dispatch(entidadesAction.editEntidadeInfo(params.id, payload));
            } else {
                dispatch(entidadesAction.createEntidade(payload));
            }

        }

    };

    const handleClickHistory = event => {

        event.preventDefault();
        const { match: { params } } = props;

        if (params.id) {
            dispatch(logAction.getLog('Entidade', params.id));
            toggle();
        }

    };

    const { log } = props.log;

    return (
        <div>
            <NavBar />
            <Container>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col md={12}>
                                <Breadcrumb>
                                    <BreadcrumbItem><Link to="/entidades" href="">Lista de Entidades</Link></BreadcrumbItem>
                                    <BreadcrumbItem active>{(props.entidade.id > 0 ? props.entidade.nome : 'Nova')}</BreadcrumbItem>
                                    {props.entidade.id > 0 ?
                                        <BreadcrumbItem className="pb-0 pt-0 m-0 btn btn-muted">
                                            <a href="#" id="tagHistorico" onClick={(event) => handleClickHistory(event)}>Histórico</a>
                                            <UncontrolledTooltip placement="bottom" target="tagHistorico">
                                                Exibe o histórico de alterações do registro
                                            </UncontrolledTooltip>
                                        </BreadcrumbItem>
                                    : null}
                                </Breadcrumb>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Form onSubmit={(event) => handleSubmit(event)}>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label className="left" for="nome">Nome *</Label>
                                        <Input
                                            id="nome"
                                            label="Nome"
                                            maxLength="60"
                                            required="true"
                                            className="form-control text-uppercase"
                                            value={props.entidade.nome}
                                            onChange={handleChange('nome')}
                                            onInput={stringUtils.toInputUppercase}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label className="left" for="responsavel">Responsável *</Label>
                                        <Input
                                            id="responsavel"
                                            label="Responsável"
                                            maxLength="60"
                                            required="true"
                                            className="form-control text-uppercase"
                                            value={props.entidade.responsavel}
                                            onChange={handleChange('responsavel')}
                                            onInput={stringUtils.toInputUppercase}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label className="left" for="nome">E-mails *</Label>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>@</InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                id="email"
                                                label="E-mail"
                                                maxLength="300"
                                                type="email"
                                                className="form-control"
                                                required="true"
                                                value={props.entidade.email || ''}
                                                onChange={handleChange('email')}
                                            />
                                        </InputGroup>
                                        <Label className="left" size="sm">Para mais de um, separe por ponto e vírgula ( ; )</Label>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label className="left" for="celular">Celular</Label>
                                        <Input
                                            id="celular"
                                            label="celular"
                                            className="form-control"
                                            value={props.entidade.celular || ''}
                                            onChange={handleChange('celular')}
                                            mask={stringUtils.phoneMask(props.entidade.celular)}
                                            maskChar=" "
                                            tag={InputMask}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label className="left" for="telefone">Telefone</Label>
                                        <Input
                                            id="telefone"
                                            label="Telefone"
                                            className="form-control"
                                            value={props.entidade.telefone || ''}
                                            onChange={handleChange('telefone')}
                                            mask={stringUtils.phoneMask(props.entidade.telefone)}
                                            maskChar=" "
                                            tag={InputMask}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>    
                            <br />
                            <Row>
                                <Col className="left" md="8" lg="6">
                                    <span>* Campos obrigatórios</span>
                                </Col>
                                <Col className="d-flex flex-row-reverse" md="4" lg="6">
                                    <Button variant="contained" color="primary" type="submit">Salvar <FontAwesomeIcon icon={faCheck} /></Button>
                                    <Button className="mr-1" variant="contained" color="secondary" component='a' onClick={() => history.push("/entidades/")} href="">Voltar <FontAwesomeIcon icon={faUndo} /></Button>
                                 </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
            </Container>
            <ModalLog toggle={toggle} modal={modal} log={log} />
        </div>
    );

};

const mapStateToProps = (state) => {
    return state;
};

const connectedEntidadesEditPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(EntidadesEdit));
export { connectedEntidadesEditPage as EntidadesEdit };
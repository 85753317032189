import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import InputMask from "react-input-mask";
import { usuariosAction, logAction, tiposUsuariosAction, entidadesAction } from '../_actions';
import { withRouter, Link } from 'react-router-dom';
import {
    Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, Button, Container, Row, Col, FormGroup, Label, Input, Form, UncontrolledTooltip
} from 'reactstrap';
import { NavBar } from '../_components/navbar';
import { history } from '../_helpers';
import { stringUtils } from '../_utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faCheck } from '@fortawesome/free-solid-svg-icons';
import '././styles.css';
import { ModalLog } from '../log/';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import Swal from 'sweetalert2';

const UsuariosEdit = (props) => {

    const dispatch = useDispatch();
    const [, forceUpdate] = React.useState(0);

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [passwordEnabled, setPasswordEnabled] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    
    useEffect(() => {
        const { match: { params } } = props;

        dispatch(tiposUsuariosAction.clear());
        dispatch(tiposUsuariosAction.getTipoUsuarioSelect());

        let filterModel = {
            Page: 1,
            Limit: 1000,
            Term: ''
        };
        dispatch(entidadesAction.getEntidade(filterModel));

        if (params.id) {
            dispatch(usuariosAction.getUsuarioById(params.id));
        } else {
            dispatch(usuariosAction.clear());
        }

    }, []);

    useEffect(() => {

    }, [props.tipoUsuario]);

    function exists(id) {
        if (props && props.usuario && props.usuario.usuariosEntidades && props.usuario.usuariosEntidades.length > 0) {
            const exists = props.usuario.usuariosEntidades.find(entidade => entidade.idEntidade === id);
            return exists;
        } else {
            return false;
        }
    }

    const handleOnChangeMultiCheckbox = e => {

        const isChecked = e.target.checked;

        if (isChecked) {
            props.usuario.usuariosEntidades.push({ idEntidade: parseInt(e.target.name), idUsuario: props.usuario.id });
        } else {
            props.usuario.usuariosEntidades = props.usuario.usuariosEntidades.filter(entidade => entidade.idEntidade !== parseInt(e.target.name));
        }

        forceUpdate(n => !n);

    };

    const handleCheckAll = () => {

        setCheckAll(!checkAll);

        if (!checkAll) {

            props.entidade.entidade.map(n => {
                props.usuario.usuariosEntidades.push({ idEntidade: n.id, idUsuario: props.usuario.id });
            });

        } else {
            props.usuario.usuariosEntidades = [];
        }

        forceUpdate(n => !n);

    };

    const handleChange = prop => event => {
        dispatch(usuariosAction.onChangeProps(prop, event));

        const { match: { params } } = props;
        if (params.id == null || params.id == 0) {

            if (prop === 'nome') {

                if (event.target.value.split(' ').length > 1) {

                    var fullName = event.target.value.split(' '),
                        firstName = fullName[0],
                        lastName = fullName[fullName.length - 1];

                    var event2 = {
                        target: {
                            value: stringUtils.removeDiacritics(firstName.toLowerCase() + '.' + lastName.toLowerCase())
                        }
                    };
                    dispatch(usuariosAction.onChangeProps('login', event2));

                } else {

                    var event3 = {
                        target: {
                            value: stringUtils.removeDiacritics(event.target.value.toLowerCase())
                        }
                    };
                    dispatch(usuariosAction.onChangeProps('login', event3));

                }

            }

        }

    };

    const handleSelectChange = prop => event => {
        var event2 = {
            type: 'select',
            target: {
                value: event.value
            }
        };
        dispatch(usuariosAction.onChangeProps(prop, event2));
    };

    const handleValidation = () => {

        var valid = true;
        var errors = '';

        if (props.usuario.idTipoUsuario === null || props.usuario.idTipoUsuario === '') {
            valid = false;
            errors += '<li style="float:left;">Informe o Tipo de Usuário</li><br>';
        }

        if (props.usuario.nome === null || props.usuario.nome === '') {
            valid = false;
            errors += '<li style="float:left;">Informe o Nome</li><br>';
        }

        if (props.usuario.login === null || props.usuario.login === '') {
            valid = false;
            errors += '<li style="float:left;">Informe o Login</li><br>';
        }

        if (props.usuario.email === null || props.usuario.email === '') {
            valid = false;
            errors += '<li style="float:left;">Informe o E-mail</li><br>';
        }

        if (!valid) {

            Swal.fire({
                icon: 'warning',
                title: 'Erros de validação',
                showCloseButton: true,
                showConfirmButton: true,
                html: '<div style="font-size: 14px; text-align: left"><ul>' + errors + '</ul></div>'
            });

        }

        return valid;

    };

    const handleSubmit = event => {

        event.preventDefault();
        const { match: { params } } = props;

        if (handleValidation()) {

            let payload = {
                id: params.id ? params.id : 0,
                login: props.usuario.login,
                nome: props.usuario.nome,
                senha: props.usuario.senha,
                email: props.usuario.email,
                idTipoUsuario: props.usuario.idTipoUsuario,
                usuariosEntidades: props.usuario.usuariosEntidades,
                telefone: props.usuario.telefone ? props.usuario.telefone.replace(/\D/g, '') : null,
                cargo: props.usuario.cargo,
                informacao: props.usuario.informacao
            };

            if (params.id) {
                dispatch(usuariosAction.editUsuarioInfo(params.id, payload));
            } else {
                dispatch(usuariosAction.createUsuario(payload));
            }

        }

    };

    const handleClickHistory = event => {

        event.preventDefault();
        const { match: { params } } = props;

        if (params.id) {
            dispatch(logAction.getLog('Usuario', params.id));
            toggle();
        }

    };

    const renderOptionsTipoUsuario = () => {
        return props.tipoUsuario.tipoUsuario.map((p, i) => {
            return (
                { value: p.value, label: p.text }
            );
        });
    };

    const { log } = props.log;

    return (
        <div>
            <NavBar />
            <Container>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col md={12}>
                                <Breadcrumb>
                                    <BreadcrumbItem><Link to="/usuarios" href="">Lista de Usuários</Link></BreadcrumbItem>
                                    <BreadcrumbItem active>Usuário {props.usuario.id}</BreadcrumbItem>
                                    <BreadcrumbItem className="pb-0 pt-0 m-0 btn btn-muted">
                                        <a href="#" id="tagHistorico" onClick={(event) => handleClickHistory(event)}>Histórico</a>
                                        <UncontrolledTooltip placement="bottom" target="tagHistorico">
                                            Exibe o histórico de alterações do registro
                                        </UncontrolledTooltip>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Form onSubmit={(event) => handleSubmit(event)}>
                            <Row>
                                <Col md={6} lg={3}>
                                    <FormGroup>
                                        <Label className="left" for="nome">Nome *</Label>
                                        <Input
                                            id="nome"
                                            label="Nome"
                                            maxLength="60"
                                            className="form-control text-uppercase"
                                            required
                                            value={props.usuario.nome}
                                            onChange={handleChange('nome')}
                                            onInput={stringUtils.toInputUppercase}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6} lg={3}>
                                    <FormGroup>
                                        <Label className="left" for="email">E-mail *</Label>
                                        <Input
                                            id="email"
                                            label="E-mail"
                                            maxLength="60"
                                            className="form-control"
                                            required
                                            value={props.usuario.email}
                                            onChange={handleChange('email')}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6} lg={3}>
                                    <FormGroup>
                                        <Label className="left" for="idTipoUsuario">Tipo de Usuário *</Label>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            value={renderOptionsTipoUsuario().filter(function (option) {
                                                return option.value == (props.usuario.idTipoUsuario || '');
                                            })}
                                            onChange={handleSelectChange('idTipoUsuario')}
                                            isSearchable
                                            id="idTipoUsuario"
                                            name="idTipoUsuario"
                                            options={renderOptionsTipoUsuario()}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6} lg={3}>
                                    <FormGroup>
                                        <Label className="left" for="login">Login *</Label>
                                        <Input
                                            id="login"
                                            label="Login"
                                            maxLength="45"
                                            className="form-control"
                                            required
                                            value={props.usuario.login}
                                            onChange={handleChange('login')}
                                        />
                                    </FormGroup>
                                </Col>
                                
                            </Row>
                            <Row>
                                <Col md={6} lg={3}>
                                    <FormGroup>
                                        <Label className="left" for="telefone">Telefone</Label>
                                        <Input
                                            id="telefone"
                                            label="Telefone"
                                            maxLength="20"
                                            className="form-control text-uppercase"
                                            mask={stringUtils.phoneMask(props.usuario.telefone)}
                                            maskChar=" "
                                            tag={InputMask}
                                            value={props.usuario.telefone}
                                            onChange={handleChange('telefone')}
                                            onInput={stringUtils.toInputUppercase}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6} lg={3}>
                                    <FormGroup>
                                        <Label className="left" for="cargo">Cargo</Label>
                                        <Input
                                            id="cargo"
                                            label="Cargo"
                                            maxLength="60"
                                            className="form-control"
                                            value={props.usuario.cargo}
                                            onChange={handleChange('cargo')}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6} lg={6}>
                                    <FormGroup>
                                        <Label className="left" for="informacao">Informações</Label>
                                        <Input
                                            id="informacao"
                                            label="Informacao"
                                            maxLength="200"
                                            className="form-control"
                                            value={props.usuario.informacao}
                                            onChange={handleChange('informacao')}
                                        />
                                    </FormGroup>
                                </Col>

                            </Row>
                            <br />
                            {
                                ((props.tipoUsuario && props.tipoUsuario.todasEntidades === false) ?
                                <div>
                                    <br />
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <Label className="right">Relação de Entidades vinculadas ao Usuário</Label>
                                        </Col>
                                            <Col sm={12} md={6} className="d-flex flex-row-reverse">
                                            <FormGroup check>
                                                <Label className="left" for="deficiencia" check>Marcar/desmarcar todos
                                                <Input
                                                        id="deficiencia"
                                                        label="Tem deficiência?"
                                                        type="checkbox"
                                                        checked={checkAll}
                                                        onChange={(event) => handleCheckAll()}
                                                    />
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                    <div className="selectgroup-pills">
                                                    <Row>
                                                            {
                                                                //selectgroup 
                                                                props.entidade.entidade.map(n => {
                                                                    return (
                                                                        (n.id !== '' ?
                                                                            <Col sm={12} md={4} lg={3} xl={4} key={`${parseInt(n.id)}`} >
                                                                        <Label className="left" style={{ width: '100%' }} check key={`${parseInt(n.id)}`}>
                                                                            <Input
                                                                                type="checkbox"
                                                                                name={`${parseInt(n.id)}`}
                                                                                className="selectgroup-input"
                                                                                checked={exists(parseInt(n.id)) || false}
                                                                                onChange={handleOnChangeMultiCheckbox} />
                                                                            <span className="selectgroup-button btn-block">{n.nome}</span>
                                                                        </Label>
                                                                    </Col>
                                                                    : null)
                                                            );
                                                        })
                                                    }
                                                    </Row>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        </Row>
                                    </div>
                                : 
                                <Row>
                                    <Col md={12}>
                                        <Label className="right">Usuário possui permissões para editar/visualizar todas Entidades, conforme definido no Tipo de Usuário {(props.usuario.tipoUsuario ? props.usuario.tipoUsuario.nome : '')}</Label>
                                    </Col>
                                </Row>
                                )}
                            <br />
                            <Row>
                                <Col className="left" md="6" lg="6">
                                    <span>* Campos obrigatórios</span>
                                </Col>
                                <Col className="d-flex flex-row-reverse" md="6" lg="6">
                                    <Button variant="contained" color="primary" type="submit">Salvar <FontAwesomeIcon icon={faCheck} /></Button>
                                    <Button className="mr-1" variant="contained" color="secondary" component='a' onClick={() => history.push("/usuarios")} href="">Voltar <FontAwesomeIcon icon={faUndo} /></Button>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
            </Container>
            <ModalLog toggle={toggle} modal={modal} log={log} />
        </div>
    );

};

const mapStateToProps = (state) => {
    return state;
};

const connectedUsuariosEditPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(UsuariosEdit));
export { connectedUsuariosEditPage as UsuariosEdit };
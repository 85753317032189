const initialState = {
    parametroAguaFaixa: [],
    id: '',
    nome: '',
    faixaInicial: '',
    faixaFinal: '',
    idParametroAgua: '',
    cor: '',
    excluido: ''
};

export function parametroAguaFaixa(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_PARAMETRO_AGUA_FAIXA':
            return {
                ...state,
                parametroAguaFaixa: action.parametroAguaFaixa,
                totalPages: action.totalPages
            };
        case 'PARAMETRO_AGUA_FAIXA_DETAIL':
            return {
                ...state,
                id: action.id,
                nome: action.nome,
                faixaInicial: action.faixaInicial,
                faixaFinal: action.faixaFinal,
                idParametroAgua: action.idParametroAgua,
                cor: action.cor,
                excluido: action.excluido
            };
        case 'PARAMETRO_AGUA_FAIXA_CLEAR':
            return {
                ...state,
                parametroAguaFaixa: [],
                id: '',
                nome: '',
                faixaInicial: '',
                faixaFinal: '',
                idParametroAgua: '',
                cor: '',
                excluido: ''
            };
        case "PARAMETRO_AGUA_FAIXA_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_PARAMETRO_AGUA_FAIXA":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state;
    }
}

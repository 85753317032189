import axios from 'axios';
import config from '../config/config';
import Swal from 'sweetalert2';

export const authService = {
    post,
    postWithToken
};
function post(apiEndpoint, payload) {
    return axios.post(config.baseUrl + apiEndpoint, payload, getOptions())
        .then((response) => {
            return response;
        }).catch((err) => {
            console.log(err);
        })
}
function postWithToken(apiEndpoint, payload, token) {

    let options = {};
    if (token) {
        options.headers = { 'Authorization': 'Bearer ' + token };
    }

    return axios.post(config.baseUrl + apiEndpoint, payload, options)
        .then((response) => {
            return response;
        }).catch((err) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Login inválido!'
            })
            return err;
        })
}
function getOptions() {
    let options = {};
    let authdata = window.btoa('gestorrp:gestor2414#');
    options.headers = { 'Authorization': 'Basic ' + authdata };
    return options;
}
import React from 'react';
import { PDFViewer, Document, Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import { Header, Footer } from '../_reports';
import moment from 'moment';

const styles = StyleSheet.create({
    page: {
        padding: 20
    },
    table: {
        flexDirection: 'row',
        paddingBottom: 4,
        fontWeight: 'normal',
        fontSize: 10
    },
    tableAnalise: {
        flexDirection: 'row',
        marginLeft: 50,
        paddingBottom: 4,
        fontWeight: 'normal',
        fontSize: 8
    },
    header: {
        flexDirection: 'row',
        fontSize: 11,
        fontWeight: 'bold',
        borderBottom: 1,
        marginBottom: 5
    }
});

class RelatorioAnalises extends React.Component {

    constructor(props) {
        super(props);

        this.setStateFromLink = this.setStateFromLink.bind(this);

        this.state = {
            ready: true,
            data: []
        };
    }

    componentDidMount() {

        this.setStateFromLink(this.props.location.state);

        this.setState({ ready: false });
        setTimeout(() => {
            this.setState({ ready: true });
        }, 1);

    }

    setStateFromLink(state) { // set state from incoming <Link>

        if (state) {
            const { data } = state;
            this.setState({ data });
        }

    }

    render() {
        if (this.state.ready) {
            return (
                <React.Fragment>
                    <PDFViewer height="100%" width="100%" >
                        <Document>
                            <Page size="A4" style={styles.page} >

                                <Header title="Relatório de Análises" subTitle="" />

                                <View style={styles.header}>

                                    <View style={{ width: 100 }}>
                                        <Text>Data da Análise</Text>
                                    </View>
                                    <View style={{ width: 200 }}>
                                        <Text>Entidade</Text>
                                    </View>
                                    <View style={{ width: 200 }}>
                                        <Text>Ponto</Text>
                                    </View>

                                </View>
                                {
                                    this.state.data.map(n => {
                                        return (
                                            <View style={{marginBottom: 10}}>
                                                <View style={styles.table}>
                                                    <View style={{ width: 100 }}>
                                                        <Text>{moment(n.data).format('DD/MM/YYYY HH:mm')}</Text>
                                                    </View>
                                                    <View style={{ width: 200 }}>
                                                        <Text>{n.entidade.nome}</Text>
                                                    </View>
                                                    <View style={{ width: 200 }}>
                                                        <Text>{n.pontoMonitoramento.nome}</Text>
                                                    </View>
                                                </View>
                                                {
                                                    n.analisesParametros.map(a => {
                                                        var excluido = a.excluido;
                                                        return (
                                                            <View style={styles.tableAnalise}>
                                                                {(excluido == false || excluido == null ?
                                                                    <View>
                                                                        <Text>{a.parametroAgua.nome}: {a.valor} {(a.parametroAgua.unidadeMedida != null ? <Text>({a.parametroAgua.unidadeMedida.abreviatura})</Text> : '')}</Text>
                                                                    </View>
                                                                    : '')}
                                                            </View>
                                                        );
                                                    })
                                                }
                                            </View>
                                        );
                                    })
                                }
                                <Footer total={this.state.data.length} />

                            </Page>
                        </Document>
                    </PDFViewer>
                </React.Fragment>
            );
        } else {
            return null;
        }
    }
}

export { RelatorioAnalises };
import { crudService } from '../_services';
import { history } from '../_helpers';
import alerts from '../_components/alerts';

export const analisesParametrosAction = {
    getAnaliseParametroByAnaliseId,
    onChangePropsList,
    createAnalisesParametros,
    clear
};

function getAnaliseParametroByAnaliseId(id, refresh) {
    return dispatch => {
        let apiEndpoint = 'analisesParametros/';
        if (refresh) {
            apiEndpoint += 'atualizar/' + id;
        } else {
            apiEndpoint += '' + id;
        }            
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    dispatch(changeAnalisesParametrosList(response.data, 1));
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function createAnalisesParametros(payload) {
    return dispatch => {
        let apiEndpoint = 'analisesParametros';
        crudService.post(apiEndpoint, payload)
            .then((response) => {
                if (response.request.status === 200) {
                    var ret = JSON.parse(response.request.responseText);
                    dispatch(updatedUserInfo());
                    history.push(ret.path);
                    alerts.success(ret.message);
                } else {
                    alerts.warning(response.request.responseText);
                }
            });
    };
}
function onChangePropsList(props, event, position) {
    return dispatch => {
        dispatch(handleOnChangePropsList(props, event.target.value, position));
    };
}
export function changeAnalisesParametrosList(analiseParametro, totalPages) {
    return {
        type: "FETECHED_ALL_ANALISE_PARAMETRO",
        analiseParametro: analiseParametro,
        totalPages: totalPages
    };
}
export function clear() {
    return {
        type: "ANALISE_PARAMETRO_CLEAR"
    };
}
export function handleOnChangePropsList(props, value, position) {
    return {
        type: "HANDLE_ON_CHANGE_LIST_ANALISE_PARAMETRO",
        props: props,
        value: value,
        position: position
    };
}
export function editAnalisesParametrosDetails(analiseParametro) {
    return {
        type: "ANALISE_PARAMETRO_DETAIL",
        id: analiseParametro.id,
        data: analiseParametro.data,
        //idEntidade: analiseParametro.idEntidade,
        //idPonto: analiseParametro.idPonto,
        parametroAgua: analiseParametro.parametroAgua,
        idParametroAgua: analiseParametro.idParametroAgua,
        analise: analiseParametro.analise,
        //entidade: analiseParametro.entidade,
        //PontoMonitoramento: analiseParametro.pontoMonitoramento
    };
}
export function updatedUserInfo() {
    return {
        type: "ANALISE_PARAMETRO_UPDATED"
    };
}
export function createUserInfo() {
    return {
        type: "ANALISE_PARAMETRO_CREATED_SUCCESSFULLY"
    };
}
export function deleteAnalisesParametrosDetails() {
    return {
        type: "DELETED_ANALISE_PARAMETRO_DETAILS"
    };
}
import Swal from 'sweetalert2';
import { history } from '../_helpers';

const alerts = {
    success,
    warning,
    error,
    html,
    confirmation,
    waiting,
    waitingToast,
    alert,
    clean,
    showImage
};

function alert(message) {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    Toast.fire({
        icon: 'warning',
        title: message
    })
}

function clean() {
    Swal.close()
}

function waiting(message) {
    Swal.fire({
        title: 'Aguarde',
        text: message,
        onBeforeOpen: () => {
            Swal.showLoading();
        }
    })
}

function waitingToast(message) {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 15000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    Toast.fire({
        icon: 'info',
        title: message
    })
}

function success(message, path) {
    Swal.fire({
        title: 'Sucesso!',
        text: message,
        icon: 'success',
        confirmButtonText: 'Ok',
        heightAuto: false
    }).then((result) => {
        history.push(path);
    });

}

function html(title, message) {
    Swal.fire({
        title: title,
        html: message,
        icon: 'info',
        confirmButtonText: 'Ok',
        heightAuto: false
    });

}

function warning(message) {

    Swal.fire({
        title: 'Atenção!',
        text: message,
        icon: 'warning',
        confirmButtonText: 'Ok',
        heightAuto: false
    });

}

function error(message) {

    Swal.fire({
        title: 'Erro!',
        text: message,
        icon: 'error',
        confirmButtonText: 'Ok',
        heightAuto: false
    });

}

function confirmation(message) {

    Swal.fire({
        title: 'Confirmação',
        text: message,
        icon: 'warning',
        buttons: true,
        dangerMode: true,
        heightAuto: false
    })
        .then((willDelete) => {
           // console.log('willDelete', willDelete);
            return willDelete;    
    });

}

function showImage(imagem, titulo) {
    Swal.fire({
        title: '',
        text: titulo,
        imageUrl: imagem,
        imageWidth: '100%',
        imageHeight: 350,
        imageAlt: 'imagem',
    })
}

export default alerts;
import { crudService } from '../_services';
import alerts from '../_components/alerts';
import { stringUtils } from '../_utils';

export const cemadenAction = {
    getCemaden
};

let filterCemaden = {
    IdPonto: 0,
    Periodos: 0
};

function getCemaden(filterCemaden) {
    return dispatch => {
        alerts.waitingToast('Carregando histórico do ponto');
        let apiEndpoint = 'cemaden' + stringUtils.serialize(filterCemaden);
        crudService.get(apiEndpoint)
            .then((response) => {

                if (response.data && response.data.graficoPluviometriaAcumulada != null) {

                    response.data.graficoPluviometriaAcumulada = {
                        options: {
                            chart: {
                                id: "basic-bar",
                                toolbar: {
                                    show: false
                                },
                            },
                            xaxis: {
                                categories: response.data.graficoPluviometriaAcumulada.options,
                                labels: {
                                    show: false,
                                    style: {
                                        fontSize: '9px'
                                    }
                                }
                            },
                        },
                        series: [
                            {
                                name: "Chuva acumulada",
                                data: response.data.graficoPluviometriaAcumulada.series[0]
                            }
                        ]
                    };

                    dispatch(editCemadenDetails(response.data));

                }

                alerts.clean();

            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
export function editCemadenDetails(cemaden) {
    return {
        type: "CEMADEN_DETAIL",
        imagem: cemaden.imagem,
        graficoPluviometriaAcumulada: cemaden.graficoPluviometriaAcumulada,
        //graficoMatriculasFuncoes: cemaden.graficoMatriculasFuncoes,
        //graficoMatriculasClasses: cemaden.graficoMatriculasClasses,
        //graficoMatriculasNiveisCarreira: cemaden.graficoMatriculasNiveisCarreira
    };
}
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { authActions } from '../_actions';
import { history } from '../_helpers';
import { withRouter } from 'react-router-dom';
import alerts from '../_components/alerts';
import { NavBarIndex } from '../_components/navbarIndex';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faKey } from '@fortawesome/free-solid-svg-icons'
import { Button, Form, FormGroup } from 'reactstrap';
import './styles.css';
import { Link } from '@material-ui/core';

const Login = (props) => {

    const [editId, setEditId] = useState(-1);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        if (localStorage.getItem('auth')) {
            history.push('/home');
        }

        document.getElementById("usuario").focus();

    }, []);

    const login = () => {

        setEditId(true);
        if (username === null || username === '') {
            alerts.alert("Preencha o campo de usuário");
            setEditId(false);
        } else if (password === null || password === '') {
            alerts.alert("Preencha o campo de senha");
            setEditId(false);
        } else {
            if (username && password) {
                dispatch(authActions.login(username, password));
                setEditId(false);
            }
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            login();
        }
    };

    return (
        <div id="page-content" className="fundo_login">
            <div>
                <NavBarIndex />
            </div>
            <div id="">
                <div className="container text-center">
                    <div className="row justify-content-center form_container">
                        <div className="user_card">
                            <div className="d-flex justify-content-center brand_img">
                                {<img src={require('../imgs/logo_gestor_bacias.png')} className="brand_logo" alt="logo_gestor_bacias" />}
                            </div>
                            <div className="d-flex justify-content-center">
                                <Form>
                                    <FormGroup className="input-group mb-3">
                                        <div className="input-group-append">
                                            <span className="input-group-text"><FontAwesomeIcon icon={faUser} /></span>
                                        </div>
                                        <input type="text" autoFocus
                                            name="usuario" id="usuario" value={username} className="form-control" onChange={e => setUsername(e.target.value)}
                                            placeholder="usuário" />
                                    </FormGroup>
                                    <FormGroup className="input-group mb-2">
                                        <div className="input-group-append">
                                            <span className="input-group-text"><FontAwesomeIcon icon={faKey} /></span>
                                        </div>
                                        <input type="password"
                                            autoComplete="current-password"
                                            value={password} onKeyDown={handleKeyDown} className="form-control" onChange={e => setPassword(e.target.value)}
                                            placeholder="senha" />
                                    </FormGroup>
                                    <div className="d-flex justify-content-center mt-3 login_container">
                                        <Button block
                                            className={editId === true ? 'login_btn' : 'login_btn'}
                                            onClick={() => { login() }} >
                                            {editId === true ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true">&nbsp;</span> : ''}
                                            Login
                                        </Button>
                                    </div>
                                    <div className="d-flex justify-content-center mt-3 login_container">
                                        <Link href="javascript:;" onClick={() => history.push("/request-password-reset")} variant="body2">
                                            {"Esqueci a senha"}
                                        </Link>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer navbar-fixed-bottom" style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <a href="https://www.fortalezatec.com.br" rel="noopener noreferrer" target="_blank"><img src={require('../imgs/logo_forcontrol.png')} style={{ height: 60 }} className="brand_forcontrol" alt="logoForControl" /></a>
                    <a href="https://www.fortalezatec.com.br" target="_blank"><img src={require('../imgs/brand_fortalezatec_escuro.png')} className="brand_fortalezatec" alt="logoFortalezaTec" style={{ height: 55 }} /> </a>
                    <img src={require('../imgs/logo_prosinos.png')} className="brand_prosinos" alt="logoProSinos" style={{ height: 55 }} />
                </div>
            </div>               
        </div>
    );

};

const mapStateToProps = (state) => {
    const { loggingIn } = state.authentication;
    return {
        loggingIn
    };
};
const connectedLoginPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(Login));

export { connectedLoginPage as Login };
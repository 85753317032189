import React from 'react';

export const tableUtils = {
    renderOptionsLimit
};

function renderOptionsLimit() {

    var limitData = [
        { value: '5', text: '5 registros' },
        { value: '10', text: '10 registros' },
        { value: '20', text: '20 registros' },
        { value: '50', text: '50 registros' }
    ];

    return limitData.map((p, i) => {
        return (
            { value: p.value, label: p.text }
        );
    });

};
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { permissoesUsuariosAction, permissoesTiposUsuariosAction, usuariosAction, tiposUsuariosAction, authActions } from '../_actions';
import { withRouter } from 'react-router-dom';
import { Table, Button, Container, Row, Col, Card, CardBody, CardHeader, Input, Form, Label, TabContent, TabPane, Nav, NavItem, NavLink, FormGroup } from 'reactstrap';
import { NavBar } from '../_components/navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import Select from 'react-select';

const Permissoes = (props) => {

    const dispatch = useDispatch();
    const [, forceUpdate] = React.useState(0);

    const [idUsuario, setidUsuario] = useState(0);
    const [idTipoUsuario, setIdTipoUsuario] = useState(0);

    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const [checkAllUsuario, setCheckAllUsuario] = useState(true);
    const [checkAllTipoUsuario, setCheckAllTipoUsuario] = useState(true);

    useEffect(() => {
        dispatch(usuariosAction.getUsuarioSelect());
        dispatch(tiposUsuariosAction.getTipoUsuarioSelect());

        dispatch(permissoesUsuariosAction.clear());
        dispatch(permissoesTiposUsuariosAction.clear());
    }, []);

    const handleChangeSelected = prop => event => {
        if (prop === 'idUsuario') {
            setidUsuario(event.value);
            if (event.value && event.value !== '') {
                dispatch(permissoesUsuariosAction.getPermissaoUsuario(event.value));
            } else {
                dispatch(permissoesUsuariosAction.clear());
            }            
        } else if (prop === 'idTipoUsuario') {
            setIdTipoUsuario(event.value);
            if (event.value && event.value !== '') {
                dispatch(permissoesTiposUsuariosAction.getPermissaoTipoUsuario(event.value));
            } else {
                dispatch(permissoesTiposUsuariosAction.clear());
            }
        } 
    };

    const handleChangeTipoUsuario = (event, prop, n) => {
        var index = props.permissaoTipoUsuario.permissaoTipoUsuario.findIndex(x => x.idPermissao === n.idPermissao);
        dispatch(permissoesTiposUsuariosAction.onChangePropsList(prop, event, index));
    };

    const handleChangeUsuario = (event, prop, n) => {
        var index = props.permissaoUsuario.permissaoUsuario.findIndex(x => x.idPermissao === n.idPermissao);
        dispatch(permissoesUsuariosAction.onChangePropsList(prop, event, index));
    };

    const handleSubmitTipoUsuario = event => {
        dispatch(permissoesTiposUsuariosAction.createPermissaoTipoUsuarios(props.permissaoTipoUsuario.permissaoTipoUsuario));
    };

    const handleSubmitUsuario = event => {
        dispatch(permissoesUsuariosAction.createPermissaoUsuarios(props.permissaoUsuario.permissaoUsuario));
    };

    const handleCheckAllTipoUsuario = () => {

        setCheckAllTipoUsuario(!checkAllTipoUsuario);

        if (!checkAllTipoUsuario) {
            props.permissaoTipoUsuario.permissaoTipoUsuario.forEach(function (part, index, arr) {
                arr[index].permitido = true;
            });
        } else {
            props.permissaoTipoUsuario.permissaoTipoUsuario.forEach(function (part, index, arr) {
                arr[index].permitido = false;
            });
        }

        forceUpdate(n => !n);

    };

    const handleCheckAllUsuario = () => {

        setCheckAllUsuario(!checkAllUsuario);

        if (!checkAllUsuario) {
            props.permissaoUsuario.permissaoUsuario.forEach(function (part, index, arr) {
                arr[index].permitido = true;
            });
        } else {
            props.permissaoUsuario.permissaoUsuario.forEach(function (part, index, arr) {
                arr[index].permitido = false;
            });
        }

        forceUpdate(n => !n);

    };

    const renderOptionsUsuario = () => {
        return props.usuario.usuario.map((p, i) => {
            return (
                { value: p.value, label: p.text }
            );
        });
    };

    const renderOptionsTipoUsuario = () => {
        return props.tipoUsuario.tipoUsuario.map((p, i) => {
            return (
                { value: p.value, label: p.text }
            );
        });
    };

    const { permissaoTipoUsuario } = props.permissaoTipoUsuario;
    const { permissaoUsuario } = props.permissaoUsuario;

    return (
        <div>
            <NavBar />
            <Container>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col md={8}>
                                <h5 className="left">Lista de Permissões</h5>
                            </Col>
                            <Col md={4} className="d-flex flex-row-reverse">
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    href="#"
                                    className={classnames({ active: activeTab === '1' })}
                                    onClick={() => { toggleTab('1'); }}>
                                    Permissões por Tipo de Usuário
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    href="#"
                                    className={classnames({ active: activeTab === '2' })}
                                    onClick={() => { toggleTab('2'); }}>
                                    Permissões por Usuário
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <br />
                                <Row>
                                    <br />
                                    <Col md={4}>
                                        <FormGroup>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                value={renderOptionsTipoUsuario().filter(function (option) {
                                                    return option.value == (idTipoUsuario || '');
                                                })}
                                                onChange={handleChangeSelected('idTipoUsuario')}
                                                isSearchable
                                                id="idTipoUsuario"
                                                name="idTipoUsuario"
                                                options={renderOptionsTipoUsuario()}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={8} className="d-flex flex-row-reverse">
                                        <FormGroup check>
                                            <Label className="left" for="deficiencia" check>Marcar/desmarcar todos
                                                <Input
                                                    id="deficiencia"
                                                    label="Tem deficiência?"
                                                    type="checkbox"
                                                    checked={checkAllTipoUsuario}
                                                    onChange={(event) => handleCheckAllTipoUsuario()}
                                                />
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                    <br />
                                </Row>
                                <Form>
                                    <Table hover bordered responsive size="sm">
                                        <thead>
                                            <tr>
                                                <th hidden>ID</th>
                                                <th hidden>Nome</th>
                                                <th>Permissão</th>
                                                <th>Permitido</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                permissaoTipoUsuario.map(n => {
                                                    return (
                                                        <tr key={n.permissao.id} style={(n.permissao.nome.includes(".") ? { backgroundColor: "#ffffff" } : { backgroundColor: "#f1f1f1" })} key={n.id}>
                                                          <td style={{ textAlign: "left", verticalAlign: "middle" }} hidden>{n.id}</td>
                                                            <td style={{ textAlign: "left", verticalAlign: "middle" }} hidden>{n.permissao.nome}</td>
                                                            <td style={{ textAlign: "left", verticalAlign: "middle" }}>{n.permissao.descricao}</td>
                                                            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                                {
                                                                    <Input
                                                                        label="Permitido"
                                                                        type="checkbox"
                                                                        checked={n.permitido}
                                                                        onChange={(event) => handleChangeTipoUsuario(event, 'permitido', n)}
                                                                    />
                                                                }
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    <Row>
                                        <Col className="d-flex flex-row-reverse" md="12" lg="12">
                                            <Button variant="contained" color="primary" onClick={(event) => handleSubmitTipoUsuario(event)} disabled={idTipoUsuario === 0 || idTipoUsuario === ''}>Salvar <FontAwesomeIcon icon={faCheck} /></Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </TabPane>
                            <TabPane tabId="2">
                                <br />
                                <Row>
                                    <br />
                                    <Col md={4}>
                                        <FormGroup>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                value={renderOptionsUsuario().filter(function (option) {
                                                    return option.value == (idUsuario || '');
                                                })}
                                                onChange={handleChangeSelected('idUsuario')}
                                                isSearchable
                                                id="idUsuario"
                                                name="idUsuario"
                                                options={renderOptionsUsuario()}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={8} className="d-flex flex-row-reverse">
                                        <FormGroup check>
                                            <Label className="left" for="deficiencia" check>Marcar/desmarcar todos
                                                <Input
                                                    id="deficiencia"
                                                    label="Tem deficiência?"
                                                    type="checkbox"
                                                    checked={checkAllUsuario}
                                                    onChange={(event) => handleCheckAllUsuario()}
                                                />
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                    <br />
                                </Row>                                
                                <Form>
                                    <Table hover bordered responsive size="sm">
                                        <thead>
                                            <tr>
                                                <th hidden>ID</th>
                                                <th hidden>Nome</th>
                                                <th>Permissão</th>
                                                <th>Permitido</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                permissaoUsuario.map(n => {
                                                    return (
                                                        <tr key={n.permissao.id} style={(n.permissao.nome.includes(".") ? { backgroundColor: "#ffffff" } : { backgroundColor: "#f1f1f1" })} key={n.id}>
                                                             <td style={{ textAlign: "left", verticalAlign: "middle" }} hidden>{n.id}</td>
                                                            <td style={{ textAlign: "left", verticalAlign: "middle" }} hidden>{n.permissao.nome}</td>
                                                            <td style={{ textAlign: "left", verticalAlign: "middle" }}>{n.permissao.descricao}</td>
                                                            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                                {
                                                                    <Input
                                                                        id="permitido"
                                                                        label="Permitido"
                                                                        type="checkbox"
                                                                        checked={n.permitido}
                                                                        onChange={(event) => handleChangeUsuario(event, 'permitido', n)}
                                                                    />
                                                                }
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    <Row>
                                        <Col className="d-flex flex-row-reverse" md="12" lg="12">
                                            <Button variant="contained" color="primary" onClick={(event) => handleSubmitUsuario(event)} disabled={idUsuario === 0 || idUsuario === ''}>Salvar <FontAwesomeIcon icon={faCheck} /></Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </TabPane>
                        </TabContent>                        
                    </CardBody>
                </Card>
            </Container>
        </div>
    );

};

const mapStateToProps = (state) => {
    return {
        usuario: state.usuario,
        tipoUsuario: state.tipoUsuario,
        permissaoUsuario: state.permissaoUsuario,
        permissaoTipoUsuario: state.permissaoTipoUsuario
    };
};

const connectedPermissoesPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(Permissoes));
export { connectedPermissoesPage as Permissoes };
 import { authService, crudService } from '../_services/';
import { history } from '../_helpers';
import Swal from 'sweetalert2';

export const authActions = {
    login,
    refresh,
    logout
};

function login(username, password) {
    return dispatch => {
        let apiEndpoint = 'authentication/login';
        let payload = {
            login: username,
            senha: password
        }
        authService.post(apiEndpoint, payload)
            .then((response) => {
                if (response && response.data && response.data.token) {
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('user', JSON.stringify(response.data.usuario));
                    localStorage.setItem('auth', true);

                    let user = {
                        auth: true,
                        token: response.data.token,
                        user: response.data.usuario
                    }
                    dispatch(setUserDetails(user));

                    history.push('/home');
                } else {
                    Swal.fire({
                        title: 'Erro!',
                        text: 'Login Inválido',
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        heightAuto: false
                    });
                }
            });
    };
}

function refresh() {
    return dispatch => {
        let apiEndpoint = 'authentication/refresh';
        crudService.post(apiEndpoint, null)
            .then((response) => {
                if (response && response.data && response.data.usuario) {

                    localStorage.removeItem('user');       
                    localStorage.setItem('user', JSON.stringify(response.data.usuario));
                    let payload = {
                        user: response.data.usuario
                    };
                    dispatch(setUserRefresh(payload));
                }
            });
    };
}

function logout() {
    return dispatch => {
        Swal.fire({
            title: 'Confirmação',
            text: 'Tem certeza de que deseja sair?',
            icon: 'warning',
            buttons: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: "Não",
            dangerMode: true,
            heightAuto: false
        })
            .then((logout) => {
                if (logout.value) {

                    localStorage.removeItem('auth');
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    //dispatch(logoutUser());
                    logoutUser();
                    history.push('/');

                }
            });
    };
}

export function setUserDetails(user) {
    return {
        type: "LOGIN_SUCCESS",
        auth: user.auth,
        token: user.token,
        user: user.user
    };
}

export function setUserRefresh(user) {
    return {
        type: "LOGIN_REFRESH",
        user: user.user
    };
}

export function logoutUser() {
    return {
        type: "LOGOUT_SUCCESS",
        auth: false,
        token: '',
        user: null
    };
}
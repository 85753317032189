import { crudService } from '../_services/';

export const logAction = {
    getLog
};

function getLog(table, id) {
    return dispatch => {
        let apiEndpoint = 'logs/' + table + '/' + id;
        crudService.get(apiEndpoint)
            .then((response) => {
                dispatch(changeLogsList(response.data));
            })
    };
}
export function changeLogsList(log) {
    return {
        type: "FETECHED_ALL_LOG",
        log: log
    }
}
const initialState = {
    dashboard: [],
    totalPontos: 0,
    totalEntidades: 0,
    totalAnalises: 0,
    totalCursos: 0,
    graficoPontosCursos: {
        options: {},
        series: []
    },
    graficoPontosEntidades: {
        options: {},
        series: []
    }
};

export function dashboard(state = initialState, action) {
    switch (action.type) {
        case 'DASHBOARD_DETAIL':
            return {
                ...state,
                totalPontos: action.totalPontos,
                totalEntidades: action.totalEntidades,
                totalAnalises: action.totalAnalises,
                totalCursos: action.totalCursos,
                graficoPontosCursos: action.graficoPontosCursos,
                graficoPontosEntidades: action.graficoPontosEntidades,
                //graficoMatriculasFuncoes: action.graficoMatriculasFuncoes,
                //graficoMatriculasClasses: action.graficoMatriculasClasses,
                //graficoMatriculasNiveisCarreira: action.graficoMatriculasNiveisCarreira
            };
        default:
            return state;
    }
}

const initialState = {
    page: 1,
    limit: 10,
    order: 'nome',
    direction: 'asc',
    parametroAgua: [],
    id: 0,
    nome: '',
    limiteMinimo: 0,
    limiteMaximo: 0,
    idTipo: '',
    idUnidadeMedida: '',
    tipoValidacao: '',
    unidadeMedida: null,
    parametrosAguaFaixa: [],
    excluido: ''
};

export function parametroAgua(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_PARAMETRO_AGUA':
            return {
                ...state,
                parametroAgua: action.parametroAgua,
                totalPages: action.totalPages
            };
        case 'PARAMETRO_AGUA_DETAIL':
            return {
                ...state,
                id: action.id,
                nome: action.nome,
                idTipo: action.idTipo,
                idUnidadeMedida: action.idUnidadeMedida,
                tipoValidacao: action.tipoValidacao,
                limiteMinimo: action.limiteMinimo,
                limiteMaximo: action.limiteMaximo,
                unidadeMedida: action.unidadeMedida,
                parametrosAguaFaixa: action.parametrosAguaFaixa,
                excluido: action.excluido
            };
        case 'PARAMETRO_AGUA_CLEAR':
            return {
                ...state,
                parametroAgua: [],
                id: 0,
                nome: '',
                limiteMinimo: 0,
                limiteMaximo: 0,
                idTipo: '',
                idUnidadeMedida: '',
                tipoValidacao: '',
                unidadeMedida: null,
                parametrosAguaFaixa: [],
                excluido: ''
            };
        case "PARAMETRO_AGUA_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_PARAMETRO_AGUA":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state;
    }
}

import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import { history } from '../_helpers';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { withStyles } from '@material-ui/core/styles';
import { usuariosAction } from '../_actions';
import { Button, Form, FormGroup } from 'reactstrap';

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
        width: theme.spacing(9),
        height: theme.spacing(9),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        color: '#ffffff'
    },
    backButton: {
        marginTop: theme.spacing(1),
    }
});

class RequestPasswordReset extends Component {

    state = {
        email: '',
        submitted: false
    }

    handleChange = prop => event => {
        this.setState({ email: event.target.value });
    };

    handleSubmit = (event) => {
        this.setState({ submitted: true }, () => {
            setTimeout(() => this.setState({ submitted: false }), 5000);
        });
        const { dispatch } = this.props;
        let payload = {
            email: this.state.email
        };
        dispatch(usuariosAction.requestPasswordReset(payload));
    }

    render() {
        const { submitted, email } = this.state;
        const { classes } = this.props;

        return (
            <Container component={Paper} maxWidth="sm">
                <CssBaseline />
                <div className={classes.paper}>
                    <Grid container justify="flex-start">
                        <IconButton className={classes.backButton} onClick={() => history.goBack()} aria-label="Voltar" color="primary">
                            <KeyboardBackspaceIcon />
                        </IconButton>
                    </Grid>
                    <Typography component="h2" variant="h6">
                        Informe o endereço de e-mail utilizado no cadastro, para recuperar a senha.
                    </Typography>

                    <Form style={{ marginTop: 10 }}>

                        <FormGroup className="input-group mb-2">
                            <input
                                autoFocus
                                type="text"
                                name="email"
                                id="email"
                                value={email}
                                className="form-control"
                                onChange={this.handleChange('email')}
                                placeholder="E-mail"
                            />
                        </FormGroup>

                        <div className="d-flex justify-content-center mt-3 login_container" style={{ marginBottom: 10 }}  >

                            <Button block
                                onClick={(event) => this.handleSubmit(event)}
                                variant="contained"
                                color="primary"
                                disabled={submitted}
                                className={submitted === true ? 'login_btn' : 'login_btn'}
                            >
                                {
                                    (submitted && 'Enviando informações...')
                                    || (!submitted && 'ENVIAR E-MAIL DE RECUPERAÇÃO DE SENHA')
                                }
                            </Button>

                        </div>

                    </Form>

                </div>
            </Container>
        );
    }
}
RequestPasswordReset.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return state;
}
const connectedRequestPasswordResetPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(withStyles(styles)(RequestPasswordReset)));
export { connectedRequestPasswordResetPage as RequestPasswordReset };
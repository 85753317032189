import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { entidadesAction } from '../_actions';
import { withRouter } from 'react-router-dom';
import { history } from '../_helpers';
import { stringUtils } from '../_utils';
import { Table, Button, Container, Row, Col, Card, CardBody, CardHeader, UncontrolledTooltip, Pagination, PaginationItem, PaginationLink, Input, Label } from 'reactstrap';
import { NavBar } from '../_components/navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { tableUtils } from '../_utils';
import Select from 'react-select';

const Entidades = (props) => {

    const [editId, setEditId] = useState(-1);
    const [middlePage, setMiddlePage] = useState(3);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [term, setTerm] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {

        const fetchData = (page, limit, term) => {

            let filterModel = {
                Page: page,
                Limit: limit,
                Term: term
            };
            dispatch(entidadesAction.getEntidade(filterModel));

        };

        fetchData(page, limit, term);
        setMiddlePage((Math.floor((page - 1) / 5) * 5) + 3);       

    }, [page, limit, term]);

    const handleClick = (event, id) => {
        dispatch(entidadesAction.deleteEntidadeById(id));
    };

    const handleChange = prop => event => {
        setTerm(event.target.value);
    };

    const handleChangeSelectedLimit = (value) => {
        setLimit(value);
    };

    const { entidade, totalPages } = props.entidade;

    return (
        <div>
            <NavBar />
            <Container>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col md={8}>
                                <h5 className="left">Lista de Entidades</h5>
                            </Col>
                            <Col md={4} className="d-flex flex-row-reverse">
                                <Button id="btnNovo" color="primary" className="btn-sm" component='a' onClick={() => history.push("/entidade")} href="">Nova <FontAwesomeIcon icon={faPlusCircle} /></Button>
                                <UncontrolledTooltip placement="bottom" target="btnNovo">
                                    Adiciona uma nova entidade
                                </UncontrolledTooltip>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={4} >
                                <Input
                                    id="term"
                                    label="term"
                                    placeholder="Filtro"
                                    value={term}
                                    onChange={handleChange('term')}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Table hover striped bordered responsive size="sm">
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Telefone</th>
                                    <th>Celular</th>
                                    <th>E-mail</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    entidade.map(n => {
                                        return (
                                            <tr key={n.id}>
                                                <td style={{ textAlign: "left", verticalAlign: "middle" }}>{n.nome}</td>
                                                <td style={{ textAlign: "left", verticalAlign: "middle" }}>{stringUtils.formataTelefone(n.telefone)}</td>
                                                <td style={{ textAlign: "left", verticalAlign: "middle" }}>{stringUtils.formataTelefone(n.celular)}</td>
                                                <td style={{ textAlign: "left", verticalAlign: "middle" }}>{n.email}</td>
                                                <td style={{ width: "80px" }}>
                                                    <Button
                                                        color="primary" size="sm"
                                                        onClick={() => { setEditId(n.id); history.push(`/entidade/${n.id}`) }}>
                                                        {editId === n.id ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true">&nbsp;</span> : <FontAwesomeIcon icon={faPen} />}
                                                    </Button>
                                                    <span>{'\u00A0'}</span>
                                                    <Button color="danger" size="sm"
                                                        onClick={(event) => handleClick(event, n.id)}>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </Table>
                        <Row>
                            <Col md={2} >
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    value={tableUtils.renderOptionsLimit().filter(function (option) {
                                        return option.value == (limit || '');
                                    })}
                                    onChange={event => handleChangeSelectedLimit(event.value)}
                                    id="limit"
                                    name="limit"
                                    options={tableUtils.renderOptionsLimit()}
                                />
                            </Col>
                            <Col md={6} />
                            <Col md={4} className="d-flex flex-row-reverse" >
                                <Pagination aria-label="Page navigation example">
                                    <PaginationItem disabled={page === 1}>
                                        <PaginationLink first onClick={() => setPage(1)} />
                                    </PaginationItem>
                                    <PaginationItem disabled={page === 1}>
                                        <PaginationLink previous onClick={() => setPage(page - 1) } />
                                    </PaginationItem>
                                    <PaginationItem active={page === (middlePage - 2)} disabled={(middlePage - 2) > totalPages}>
                                        <PaginationLink onClick={() => setPage(middlePage - 2)} >
                                            {middlePage - 2}
                                        </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem active={page === (middlePage - 1)} disabled={(middlePage - 1) > totalPages}>
                                        <PaginationLink onClick={() => setPage(middlePage - 1)} >
                                            {middlePage - 1}
                                        </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem active={page === middlePage} disabled={middlePage > totalPages}>
                                        <PaginationLink onClick={() => setPage(middlePage)} >
                                            {middlePage}
                                        </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem active={page === (middlePage + 1)} disabled={(middlePage + 1) > totalPages}>
                                        <PaginationLink onClick={() => setPage(middlePage + 1)} >
                                            {middlePage + 1}
                                        </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem active={page === (middlePage + 2)} disabled={(middlePage + 2) > totalPages}>
                                        <PaginationLink onClick={() => setPage(middlePage + 2)} >
                                            {middlePage + 2}
                                        </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem disabled={page === totalPages}>
                                        <PaginationLink next onClick={() => setPage(page + 1)} />
                                    </PaginationItem>
                                    <PaginationItem disabled={page === totalPages}>
                                        <PaginationLink last onClick={() => setPage(totalPages)} />
                                    </PaginationItem>
                                </Pagination>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );

};

const mapStateToProps = (state) => {
    return {
        entidade: state.entidade
    };
};

const connectedEntidadesPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(Entidades));
export { connectedEntidadesPage as Entidades };
import { crudService } from '../_services';
import { history } from '../_helpers';
import alerts from '../_components/alerts';
import Swal from 'sweetalert2';
import { stringUtils } from '../_utils';

export const pontosMonitoramentoAction = {
    getPontosMonitoramento,
    getPontosMonitoramentoSelect,
    getPontosMonitoramentoSemFonteExternaSelect,
    getPontosMonitoramentoFonteExternaSelect,
    getPontosMonitoramentoById,
    getPontosMonitoramentoPublicById,
    getPontosMonitoramentoSelectByEntidade,
    onChangeProps,
    editPontosMonitoramentoInfo,
    createPontosMonitoramento,
    deletePontosMonitoramentoById,
    clear
};

let filterModel = {
    Page: 1,
    Limit: 10,
    Term: ''
};

function getPontosMonitoramento(filterModel, props) {
    return dispatch => {
        alerts.waiting();
        let apiEndpoint = 'pontosMonitoramento' + stringUtils.serialize(filterModel);
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    dispatch(changePontosMonitoramentosList(response.data.items, response.data.totalPages));
                }
                alerts.clean();
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function getPontosMonitoramentoSelect() {
    return dispatch => {
        let apiEndpoint = 'pontosMonitoramento/select';
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    response.data.unshift({ value: '', text: ' SELECIONE ...' });
                    dispatch(changePontosMonitoramentosList(response.data, null));
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}

function getPontosMonitoramentoSemFonteExternaSelect() {
    return dispatch => {
        let apiEndpoint = 'pontosMonitoramento/select/semFonteExterna';
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    response.data.unshift({ value: '', text: ' SELECIONE ...' });
                    //console.log("response.data", response.data)
                    dispatch(changePontosMonitoramentosList(response.data, null));
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function getPontosMonitoramentoFonteExternaSelect() {
    return dispatch => {
        let apiEndpoint = 'pontosMonitoramento/select/FonteExterna';
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    response.data.unshift({ value: '', text: ' SELECIONE ...' });
                    //console.log("response.data", response.data)
                    dispatch(changePontosMonitoramentosList(response.data, null));
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}

function getPontosMonitoramentoSelectByEntidade(id) {
    return dispatch => {
        let apiEndpoint = 'pontosMonitoramento/select/entidade/' + id;
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    response.data.unshift({ value: '', text: ' SELECIONE ...' });
                    dispatch(changePontosMonitoramentosList(response.data, null));
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function getPontosMonitoramentoPublicById(id) {
    return dispatch => {
        let apiEndpoint = 'pontosMonitoramento/public/' + id;
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    dispatch(changePontosMonitoramentosList(response.data, null)); //response.data.items ??
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function createPontosMonitoramento(payload) {
    return dispatch => {
        alerts.waiting();
        let apiEndpoint = 'pontosMonitoramento/';
        crudService.post(apiEndpoint, payload)
            .then((response) => {
                if (response.request.status === 200) {
                    var ret = JSON.parse(response.request.responseText);
                    dispatch(updatedUserInfo());
                    //history.push(ret.path);
                    alerts.success(ret.message, ret.path);
                } else {
                    alerts.warning(response.request.responseText);
                }
            });
    };
}
function getPontosMonitoramentoById(id) {
    return dispatch => {
        alerts.waiting();
        let apiEndpoint = 'pontosMonitoramento/' + id;
        crudService.get(apiEndpoint)
            .then((response) => {
                dispatch(editPontosMonitoramentosDetails(response.data));
                alerts.clean();
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function onChangeProps(props, event) {
    return dispatch => {
        if (event.target.type === 'checkbox') {
            dispatch(handleOnChangeProps(props, event.target.checked));
        } else {
            dispatch(handleOnChangeProps(props, event.target.value));
        }
    };
}
function editPontosMonitoramentoInfo(id, payload) {
    return dispatch => {
        let apiEndpoint = 'pontosMonitoramento/' + id;
        crudService.put(apiEndpoint, payload)
            .then((response) => {
                if (response.request.status === 200) {
                    var ret = JSON.parse(response.request.responseText);
                    dispatch(updatedUserInfo());
                    //history.push(ret.path);
                    alerts.success(ret.message, ret.path);
                } else {
                    alerts.warning(response.request.responseText);
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function deletePontosMonitoramentoById(id) {
    return dispatch => {

        Swal.fire({
            title: 'Confirmação',
            text: 'Tem certeza de que deseja excluir o registro?',
            icon: 'warning',
            buttons: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: "Não, cancelar!",
            dangerMode: true,
            heightAuto: false
        })
        .then((willDelete) => {
            if (willDelete.value) {

                let apiEndpoint = 'pontosMonitoramento/' + id;
                crudService.deleteDetail(apiEndpoint)
                    .then((response) => {
                        if (response.request.status === 200) {
                            var ret = JSON.parse(response.request.responseText);
                            dispatch(deletePontosMonitoramentosDetails());
                            dispatch(getPontosMonitoramento(filterModel));
                            alerts.success(ret.message);
                        } else {
                            alerts.warning(response.request.responseText);
                        }
                    }).catch((err) => {
                        console.log(err);
                        alerts.error(err);
                    });

            }
        });

    };
}
export function changePontosMonitoramentosList(pontoMonitoramento, totalPages) {
    return {
        type: "FETECHED_ALL_PONTO_MONITORAMENTO",
        pontoMonitoramento: pontoMonitoramento,
        totalPages: totalPages
    };
}
export function clear() {
    return {
        type: "PONTO_MONITORAMENTO_CLEAR"
    };
}
export function handleOnChangeProps(props, value) {
    return {
        type: "HANDLE_ON_CHANGE_PONTO_MONITORAMENTO",
        props: props,
        value: value
    };
}
export function editPontosMonitoramentosDetails(pontoMonitoramento) {
    return {
        type: "PONTO_MONITORAMENTO_DETAIL",
        id: pontoMonitoramento.id,
        codigo: pontoMonitoramento.codigo,
        nome: pontoMonitoramento.nome,
        latitude: pontoMonitoramento.latitude,
        longitude: pontoMonitoramento.longitude,
        idCursoDagua: pontoMonitoramento.idCursoDagua,
        idFonteExterna: pontoMonitoramento.idFonteExterna,
        codigoReferencia: pontoMonitoramento.codigoReferencia,
        excluido: pontoMonitoramento.excluido,
        pontosParametros: pontoMonitoramento.pontosParametros,
        pontosEntidades: pontoMonitoramento.pontosEntidades,
        fonteExterna: pontoMonitoramento.fonteExterna
    };
}
export function updatedUserInfo() {
    return {
        type: "PONTO_MONITORAMENTO_UPDATED"
    };
}
export function createUserInfo() {
    return {
        type: "PONTO_MONITORAMENTO_CREATED_SUCCESSFULLY"
    };
}
export function deletePontosMonitoramentosDetails() {
    return {
        type: "DELETED_PONTO_MONITORAMENTO_DETAILS"
    };
}
import { cursoDaguasAction } from "../_actions";

const initialState = {
    page: 1,
    limit: 10,
    order: 'nome',
    direction: 'asc',
    cursoDagua: [],
    id: '',
    nome: '',
    excluido: ''
};

export function cursoDagua(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_CURSO_DAGUA':
            return {
                ...state,
                cursoDagua: action.cursoDagua,
                totalPages: action.totalPages
            };
        case 'CURSO_DAGUA_DETAIL':
            return {
                ...state,
                id: action.id,
                nome: action.nome,
                excluido: action.excluido
            };
        case 'CURSO_DAGUA_CLEAR':
            return {
                ...state,
                cursoDagua: [],
                id: '',
                nome: '',
                excluido: ''
            };
        case "CURSO_DAGUA_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_CURSO_DAGUA":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state;
    }
}

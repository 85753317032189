const initialState = {
    ana: [],
    idPonto: 0,
    dataInicio: '',
    dataFim: '',
    graficoChuva: {
        options: {},
        series: []
    },
    graficoNivel: {
        options: {},
        series: []
    },
    graficoVazao: {
        options: {},
        series: []
    }
};

export function ana(state = initialState, action) {
    switch (action.type) {
        case 'ANA_DETAIL':
            return {
                ...state,
                graficoChuva: action.graficoChuva,
                graficoNivel: action.graficoNivel,
                graficoVazao: action.graficoVazao
            };
        default:
            return state;
    }
}

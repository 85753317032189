import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { unidadesMedidasAction, logAction } from '../_actions';
import { withRouter, Link } from 'react-router-dom';
import { history } from '../_helpers';
import { stringUtils } from '../_utils';
import {
    Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, Button, UncontrolledTooltip, Container, Row, Col, FormGroup, Label, Input, Form, InputGroup, InputGroupAddon, InputGroupText
} from 'reactstrap';
import InputMask from "react-input-mask";
import { NavBar } from '../_components/navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faCheck } from '@fortawesome/free-solid-svg-icons';
import '././styles.css';
import { ModalLog } from '../log/';

const UnidadesMedidasEdit = (props) => {

    const dispatch = useDispatch();

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    useEffect(() => {
        const { match: { params } } = props;

        if (params.id) {
            dispatch(unidadesMedidasAction.getUnidadeMedidaById(params.id));
        } else {
            dispatch(unidadesMedidasAction.clear());
        }

    }, []);

    const handleChange = prop => event => {
        dispatch(unidadesMedidasAction.onChangeProps(prop, event));
    };

    const handleSubmit = event => {

        event.preventDefault();
        const { match: { params } } = props;

        if (props.unidadeMedida.nome === null || props.unidadeMedida.nome === '') {
            alert('Informe um Nome válido!');
        } else {

            let payload = {
                id: params.id ? params.id : 0,
                nome: props.unidadeMedida.nome,
                abreviatura: props.unidadeMedida.abreviatura,
            };

            if (params.id) {
                dispatch(unidadesMedidasAction.editUnidadeMedidaInfo(params.id, payload));
            } else {
                dispatch(unidadesMedidasAction.createUnidadeMedida(payload));
            }

        }

    };

    const handleClickHistory = event => {

        event.preventDefault();
        const { match: { params } } = props;

        if (params.id) {
            dispatch(logAction.getLog('UnidadeMedida', params.id));
            toggle();
        }

    };

    const { log } = props.log;

    return (
        <div>
            <NavBar />
            <Container>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col md={12}>
                                <Breadcrumb>
                                    <BreadcrumbItem><Link to="/unidadesMedidas" href="">Lista de UnidadesMedidas</Link></BreadcrumbItem>
                                    <BreadcrumbItem active>{(props.unidadeMedida.id > 0 ? props.unidadeMedida.nome : 'Nova')}</BreadcrumbItem>
                                    {props.unidadeMedida.id > 0 ?
                                        <BreadcrumbItem className="pb-0 pt-0 m-0 btn btn-muted">
                                            <a href="#" id="tagHistorico" onClick={(event) => handleClickHistory(event)}>Histórico</a>
                                            <UncontrolledTooltip placement="bottom" target="tagHistorico">
                                                Exibe o histórico de alterações do registro
                                            </UncontrolledTooltip>
                                        </BreadcrumbItem>
                                    : null}
                                </Breadcrumb>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Form onSubmit={(event) => handleSubmit(event)}>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label className="left" for="nome">Nome *</Label>
                                        <Input
                                            id="nome"
                                            label="Nome"
                                            maxLength="60"
                                            required="true"
                                            className="form-control text-uppercase"
                                            value={props.unidadeMedida.nome}
                                            onChange={handleChange('nome')}
                                            onInput={stringUtils.toInputUppercase}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label className="left" for="abreviatura">Abreviatura</Label>
                                        <Input
                                            id="abreviatura"
                                            label="Abreviatura"
                                            maxLength="10"
                                            className="form-control"
                                            value={props.unidadeMedida.abreviatura}
                                            onChange={handleChange('abreviatura')}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col className="left" md="8" lg="6">
                                    <span>* Campos obrigatórios</span>
                                </Col>
                                <Col className="d-flex flex-row-reverse" md="4" lg="6">
                                    <Button variant="contained" color="primary" type="submit">Salvar <FontAwesomeIcon icon={faCheck} /></Button>
                                    <Button className="mr-1" variant="contained" color="secondary" component='a' onClick={() => history.push("/unidadesMedidas/")} href="">Voltar <FontAwesomeIcon icon={faUndo} /></Button>
                                 </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
            </Container>
            <ModalLog toggle={toggle} modal={modal} log={log} />
        </div>
    );

};

const mapStateToProps = (state) => {
    return state;
};

const connectedUnidadesMedidasEditPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(UnidadesMedidasEdit));
export { connectedUnidadesMedidasEditPage as UnidadesMedidasEdit };
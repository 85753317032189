const initialState = {
    upload: [],
    id: 0,
    idTipoUpload: '',
    arquivo: '',
    observacao: '',
    idCamada: '',
    tipoUpload: null
};

export function upload(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_UPLOAD':
            return {
                ...state,
                upload: action.upload,
                totalPages: action.totalPages
            };
        case 'UPLOAD_DETAIL':
            return {
                ...state,
                id: action.id,
                idTipoUpload: action.idTipoUpload,
                arquivo: action.arquivo,
                observacao: action.observacao,
                idCamada: action.idCamada,
                tipoUpload: action.tipoUpload
            };
        case 'UPLOAD_CLEAR':
            return {
                ...state,
                upload: [],
                id: 0,
                idTipoUpload: '',
                arquivo: '',
                observacao: '',
                idCamada: '',
                tipoUpload: null
            };
        case "UPLOAD_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_UPLOAD":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state;
    }
}

import { crudService } from '../_services';
import alerts from '../_components/alerts';
import { analisesAction } from '../_actions';
import { stringUtils } from '../_utils';

export const relatoriosAction = {
    getRelatorioAnalises
};

function getRelatorioAnalises(filterModel, props) {
    return dispatch => {
        alerts.waiting();
        let apiEndpoint = 'relatorios/relatorioAnalises' + stringUtils.serialize(filterModel);
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    dispatch(analisesAction.changeAnalisesList(response.data, 1));
                    alerts.clean();
                    props.history.push({
                        pathname: "/relatorioAnalises",
                        state: { data: response.data }
                    });
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
const initialState = {
    tipoUsuario: [],
    id: '',
    nome: '',
    todasEntidades: false,
    excluido: ''
};

export function tipoUsuario(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_TIPO_USUARIO':
            return {
                ...state,
                tipoUsuario: action.tipoUsuario,
                totalPages: action.totalPages
            };
        case 'TIPO_USUARIO_DETAIL':
            return {
                ...state,
                id: action.id,
                nome: action.nome,
                todasEntidades: action.todasEntidades,
                excluido: action.excluido
            };
        case 'TIPO_USUARIO_CLEAR':
            return {
                ...state,
                tipoUsuario: [],
                id: '',
                nome: '',
                todasEntidades: false,
                excluido: ''
            };
        case "TIPO_USUARIO_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_TIPO_USUARIO":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state;
    }
}

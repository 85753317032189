import { crudService } from '../_services';
import { history } from '../_helpers';
import alerts from '../_components/alerts';

export const parametrosAction = {
    getParametro,
    getParametroByChave,
    onChangePropsList,
    createParametros,
    clear
};

function getParametro() {
    return dispatch => {
        let apiEndpoint = 'parametros';
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    dispatch(changeParametrosList(response.data, 1));
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function getParametroByChave(chave) {
    return dispatch => {
        let apiEndpoint = 'parametros/' + chave;
        crudService.get(apiEndpoint)
            .then((response) => {
                dispatch(editParametrosDetails(response.data));
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function createParametros(payload) {
    return dispatch => {
        let apiEndpoint = 'parametros';
        crudService.post(apiEndpoint, payload)
            .then((response) => {
                if (response.request.status === 200) {
                    var ret = JSON.parse(response.request.responseText);
                    dispatch(updatedUserInfo());
                    history.push(ret.path);
                    alerts.success(ret.message);
                } else {
                    alerts.warning(response.request.responseText);
                }
            });
    };
}



function onChangePropsList(props, event, position) {
    return dispatch => {
        dispatch(handleOnChangePropsList(props, event.target.value, position));
    };
}
export function changeParametrosList(parametro, totalPages) {
    return {
        type: "FETECHED_ALL_PARAMETRO",
        parametro: parametro,
        totalPages: totalPages
    };
}
export function clear() {
    return {
        type: "PARAMETRO_CLEAR"
    };
}
export function handleOnChangePropsList(props, value, position) {
    return {
        type: "HANDLE_ON_CHANGE_LIST_PARAMETRO",
        props: props,
        value: value,
        position: position
    };
}
export function editParametrosDetails(parametro) {
    return {
        type: "PARAMETRO_DETAIL",
        id: parametro.id,
        descricao: parametro.descricao,
        chave: parametro.chave,
        valor: parametro.valor,
        tipo: parametro.tipo
    };
}
export function updatedUserInfo() {
    return {
        type: "PARAMETRO_UPDATED"
    };
}
export function createUserInfo() {
    return {
        type: "PARAMETRO_CREATED_SUCCESSFULLY"
    };
}
export function deleteParametrosDetails() {
    return {
        type: "DELETED_PARAMETRO_DETAILS"
    };
}
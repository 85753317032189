const initialState = {
    analiseParametro: [],
    id: '',
    idParametroAgua: '',
    idAnalise: '',
    valor: '',
    pontoMonitoramento: null,
    parametroAgua: null,
};

export function analiseParametro(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_ANALISE_PARAMETRO':
            return {
                ...state,
                analiseParametro: action.analiseParametro,
                totalPages: action.totalPages
            };
        case 'ANALISE_PARAMETRO_DETAIL':
            return {
                ...state,
                id: action.id,
                idParametroAgua: action.idParametroAgua,
                idAnalise: action.idAnalise,
                valor: action.valor,
                pontoMonitoramento: action.pontoMonitoramento,
                parametroAgua: action.parametroAgua
            };
        case 'ANALISE_PARAMETRO_CLEAR':
            return {
                ...state,
                analiseParametro: [],
                id: '',
                idParametroAgua: '',
                idAnalise: '',
                valor: '',
                pontoMonitoramento: null,
                parametroAgua: null
            };
        case "ANALISE_PARAMETRO_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_ANALISE_PARAMETRO":
            return {
                ...state,
                [action.props]: action.value
            };
        case "HANDLE_ON_CHANGE_LIST_ANALISE_PARAMETRO":
            return {
                ...state,
                [analiseParametro]: [...state.analiseParametro][action.position].valor = action.value
            };
        default:
            return state;
    }
}

const initialState = {  
    id: 0,
    idParametro: '',
    idCursoDAgua: '',
    volumeChuvaPontos: [],
    data: '',
    graficoQualidadeCursoDagua: {
        options: {},
        series: []
    },
};

export function graficoQualidadeCursoDagua(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_GRAFICO_QUALIDADE_CURSO_DAGUA':
            return {
                ...state,
                graficoQualidadeCursoDagua: action.graficoQualidadeCursoDagua
            };
        case 'GRAFICO_QUALIDADE_CURSO_DAGUA_DETAIL':
            return {
                ...state,
                id: action.id,        
                idParametro: action.idParametro,
                idCursoDAgua: action.idCursoDAgua,
                data: action.data,
                graficoQualidadeCursoDagua: action.graficoQualidadeCursoDagua,
                volumeChuvaPontos: action.graficoQualidadeCursoDagua
            };
        case 'GRAFICO_QUALIDADE_CURSO_DAGUA_CLEAR':
            return {
                ...state,
                id: 0,
                idParametro: '',
                idCursoDAgua: '',
                data: '',
                volumeChuvaPontos: []
            };
        case "GRAFICO_QUALIDADE_CURSO_DAGUA_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_GRAFICO_QUALIDADE_CURSO_DAGUA":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state;
    }
}

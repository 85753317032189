import React, { useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { withRouter, Link } from 'react-router-dom';
import { relatoriosAction, entidadesAction, pontosMonitoramentoAction, cursosDaguaAction } from '../_actions';
import { Container, Row, Col, Card, CardBody, CardHeader, FormGroup, Label, Input, Button } from 'reactstrap';
import { NavBar } from '../_components/navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';

const PesquisaRelatorioAnalises = (props) => {

    const dispatch = useDispatch();
    const [idEntidade, setIdEntidade] = React.useState(0);
    const [idPontoMonitoramento, setIdPontoMonitoramento] = React.useState(0);
    const [idCursoDagua, setIdCursoDagua] = React.useState(0);
    const [dataInicio, setDataInicio] = React.useState('');
    const [dataTermino, setDataTermino] = React.useState('');

    useEffect(() => {
        dispatch(cursosDaguaAction.getCursoDaguaSelect());
        dispatch(entidadesAction.getEntidadeSelect());
        dispatch(pontosMonitoramentoAction.getPontosMonitoramentoSelect());
    }, []);

    const handleChange = prop => event => {
        if (prop === 'dataInicio') {
            setDataInicio(event.target.value);
        } else if (prop === 'dataTermino') {
            setDataTermino(event.target.value);
        }
    };

    const handleSelectChange = prop => event => {
        if (prop === 'idEntidade') {
            setIdEntidade(event.value);
        } else if (prop === 'idPontoMonitoramento') {
            setIdPontoMonitoramento(event.value);
        } else if (prop === 'idCursoDagua') {
            setIdCursoDagua(event.value);
        }
    };

    const renderOptionsCursosDagua = () => {
        return props.state.cursoDagua.cursoDagua.map((p, i) => {
            return (
                { value: p.value, label: p.text }
            );
        });
    };

    const renderOptionsEntidades = () => {
        return props.state.entidade.entidade.map((p, i) => {
            return (
                { value: p.value, label: p.text }
            );
        });
    };

    const renderOptionsPontoMonitoramentos = () => {
        return props.state.pontoMonitoramento.pontoMonitoramento.map((p, i) => {
            return (
                { value: p.value, label: p.text }
            );
        });
    };

    const handleClick = () => {

        let filterModel = {
            IdPontoMonitoramento: (idPontoMonitoramento > 0 ? idPontoMonitoramento : 0),
            IdEntidade: (idEntidade > 0 ? idEntidade : 0),
            IdCursoDagua: (idCursoDagua > 0 ? idCursoDagua : 0),
            DataInicio: dataInicio,
            DataTermino: dataTermino
        };

        //const win = window.open("/relatorioAnalises", "_blank");
        //win.focus();
        dispatch(relatoriosAction.getRelatorioAnalises(filterModel, props));

    };

    return (
        <div>
            <NavBar />
            <Container>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col md={12}>
                                <h5 className="left">Relatório de Análises</h5>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={4} lg={3} xl={2}>
                                <FormGroup>
                                    <Label className="left" for="dataInicio">Data de Início</Label>
                                    <Input
                                        id="dataInicio"
                                        label="Data de Início"
                                        className="form-control"
                                        type="date"
                                        value={dataInicio || ''}
                                        onChange={handleChange('dataInicio')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4} lg={3} xl={2}>
                                <FormGroup>
                                    <Label className="left" for="dataTermino">Data de Término</Label>
                                    <Input
                                        id="dataTermino"
                                        label="Data de Término"
                                        className="form-control"
                                        type="date"
                                        value={dataTermino || ''}
                                        onChange={handleChange('dataTermino')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4} lg={3} xl={3}>
                                <FormGroup>
                                    <Label className="left" for="idCursoDagua">Curso de água</Label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        value={renderOptionsCursosDagua().filter(function (option) {
                                            return option.value == (idCursoDagua || '');
                                        })}
                                        onChange={handleSelectChange('idCursoDagua')}
                                        isSearchable
                                        id="idCursoDagua"
                                        name="idCursoDagua"
                                        options={renderOptionsCursosDagua()}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={5} lg={2}>
                                <FormGroup>
                                    <Label className="left" for="idEntidade">Entidade</Label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        value={renderOptionsEntidades().filter(function (option) {
                                            return option.value == (idEntidade || '');
                                        })}
                                        onChange={handleSelectChange('idEntidade')}
                                        isSearchable
                                        id="idEntidade"
                                        name="idEntidade"
                                        options={renderOptionsEntidades()}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={5} lg={3}>
                                <FormGroup>
                                    <Label className="left" for="idPontoMonitoramento">Ponto de Monitoramento</Label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        value={renderOptionsPontoMonitoramentos().filter(function (option) {
                                            return option.value == (idPontoMonitoramento || '');
                                        })}
                                        onChange={handleSelectChange('idPontoMonitoramento')}
                                        isSearchable
                                        id="idPontoMonitoramento"
                                        name="idPontoMonitoramento"
                                        options={renderOptionsPontoMonitoramentos()}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col className="left" md="6" lg="6">
                                <span>* Campos obrigatórios</span>
                            </Col>
                            <Col className="d-flex flex-row-reverse" md="6" lg="6">
                                <Button variant="contained" color="primary" onClick={() => handleClick()} >Gerar <FontAwesomeIcon icon={faFilePdf} /></Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );

}

const mapStateToProps = (state) => {
    return {
        state: state
    };
};

const connectedPesquisaRelatorioAnalisesPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(PesquisaRelatorioAnalises));
export { connectedPesquisaRelatorioAnalisesPage as PesquisaRelatorioAnalises };
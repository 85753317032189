import { crudService } from '../_services';
import { history } from '../_helpers';
import alerts from '../_components/alerts';
import Swal from 'sweetalert2';
import { stringUtils } from '../_utils';

export const unidadesMedidasAction = {
    getUnidadeMedida,
    getUnidadeMedidaSelect,
    getUnidadeMedidaById,
    onChangeProps,
    editUnidadeMedidaInfo,
    createUnidadeMedida,
    deleteUnidadeMedidaById,
    clear
};

let filterModel = {
    Page: 1,
    Limit: 10,
    Term: ''
};

function getUnidadeMedida(filterModel) {
    return dispatch => {
        alerts.waiting();
        let apiEndpoint = 'unidadesMedidas' + stringUtils.serialize(filterModel);
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    dispatch(changeUnidadesMedidasList(response.data.items, response.data.totalPages));
                }
                alerts.clean();
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function getUnidadeMedidaSelect() {
    return dispatch => {
        let apiEndpoint = 'unidadesMedidas/select';
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    response.data.unshift({ value: '', text: ' SELECIONE ...' });
                    dispatch(changeUnidadesMedidasList(response.data, null));
                } else {
                    dispatch(clear());
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function createUnidadeMedida(payload) {
    return dispatch => {
        let apiEndpoint = 'unidadesMedidas/';
        crudService.post(apiEndpoint, payload)
            .then((response) => {
                if (response.request.status === 200) {
                    var ret = JSON.parse(response.request.responseText);
                    dispatch(updatedUserInfo());
                    alerts.success(ret.message, ret.path);
                } else {
                    alerts.warning(response.request.responseText);
                }
            });
    };
}
function getUnidadeMedidaById(id) {
    return dispatch => {
        let apiEndpoint = 'unidadesMedidas/' + id;
        crudService.get(apiEndpoint)
            .then((response) => {
                dispatch(editUnidadesMedidasDetails(response.data));
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function onChangeProps(props, event) {
    return dispatch => {
        if (event.target.type === 'checkbox') {
            dispatch(handleOnChangeProps(props, event.target.checked));
        } else {
            dispatch(handleOnChangeProps(props, event.target.value));
        }
    };
}
function editUnidadeMedidaInfo(id, payload) {
    return dispatch => {
        let apiEndpoint = 'unidadesMedidas/' + id;
        crudService.put(apiEndpoint, payload)
            .then((response) => {
                if (response.request.status === 200) {
                    var ret = JSON.parse(response.request.responseText);
                    dispatch(updatedUserInfo());
                    alerts.success(ret.message, ret.path);
                } else {
                    alerts.warning(response.request.responseText);
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    }
}
function deleteUnidadeMedidaById(id) {
    return dispatch => {

        Swal.fire({
            title: 'Confirmação',
            text: 'Tem certeza de que deseja excluir o registro?',
            icon: 'warning',
            buttons: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: "Não, cancelar!",
            dangerMode: true,
            heightAuto: false
        })
            .then((willDelete) => {
                if (willDelete.value) {

                    let apiEndpoint = 'unidadesMedidas/' + id;
                    crudService.deleteDetail(apiEndpoint)
                        .then((response) => {
                            if (response.request.status === 200) {
                                var ret = JSON.parse(response.request.responseText);
                                dispatch(deleteUnidadesMedidasDetails());
                                dispatch(getUnidadeMedida(filterModel));
                                alerts.success(ret.message);
                            } else {
                                alerts.warning(response.request.responseText);
                            }
                        }).catch((err) => {
                            console.log(err);
                            alerts.error(err);
                        });

                }
            });

    };
}
export function changeUnidadesMedidasList(unidadeMedida, totalPages) {
    return {
        type: "FETECHED_ALL_UNIDADE_MEDIDA",
        unidadeMedida: unidadeMedida,
        totalPages: totalPages
    };
}
export function clear() {
    return {
        type: "UNIDADE_MEDIDA_CLEAR"
    };
}
export function handleOnChangeProps(props, value) {
    return {
        type: "HANDLE_ON_CHANGE_UNIDADE_MEDIDA",
        props: props,
        value: value
    };
}
export function editUnidadesMedidasDetails(unidadeMedida) {
    return {
        type: "UNIDADE_MEDIDA_DETAIL",
        id: unidadeMedida.id,
        nome: unidadeMedida.nome,
        abreviatura: unidadeMedida.abreviatura,
        excluido: unidadeMedida.excluido
    }
}
export function updatedUserInfo() {
    return {
        type: "UNIDADE_MEDIDA_UPDATED"
    };
}
export function createUserInfo() {
    return {
        type: "UNIDADE_MEDIDA_CREATED_SUCCESSFULLY"
    };
}
export function deleteUnidadesMedidasDetails() {
    return {
        type: "DELETED_UNIDADE_MEDIDA_DETAILS"
    };
}
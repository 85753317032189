const initialState = {
    parametro: [],
    id: '',
    descricao: '',
    chave: '',
    valor: '',
    tipo: ''
};

export function parametro(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_PARAMETRO':
            return {
                ...state,
                parametro: action.parametro,
                totalPages: action.totalPages
            };
        case 'PARAMETRO_DETAIL':
            return {
                ...state,
                id: action.id,
                descricao: action.descricao,
                chave: action.chave,
                valor: action.valor,
                tipo: action.tipo
            };
        case 'PARAMETRO_CLEAR':
            return {
                ...state,
                parametro: [],
                id: '',
                descricao: '',
                chave: '',
                valor: '',
                tipo: ''
            };
        case "PARAMETRO_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_PARAMETRO":
            return {
                ...state,
                [action.props]: action.value
            };
        case "HANDLE_ON_CHANGE_LIST_PARAMETRO":
            return {
                ...state,
                [parametro]: [...state.parametro][action.position].valor = action.value
            };
        default:
            return state;
    }
}

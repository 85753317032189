const initialState = {
    page: 1,
    limit: 10,
    order: 'nome',
    direction: 'desc',
    camada: [],
    id: 0,
    nome: '',
    uploads: [],
    excluido: ''
};

export function camada(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_CAMADA':
            return {
                ...state,
                camada: action.camada,
                totalPages: action.totalPages
            };
        case 'CAMADA_DETAIL':
            return {
                ...state,
                id: action.id,
                nome: action.nome,
                uploads: action.uploads,
                excluido: action.excluido,
            };
        case 'CAMADA_CLEAR':
            return {
                ...state,
                camada: [],
                id: 0,
                nome: '',
                uploads: [],
                excluido: '',
            };
        case "CAMADA_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_CAMADA":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state;
    }
}

import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { NavBarIndex } from '../_components/navbarIndex';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { analisesAction } from '../_actions';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    KmlLayer,
    Marker,
    InfoWindow
} from "react-google-maps";
import {
    Row, Col, Button
} from 'reactstrap';
import { stringUtils } from '../_utils';
import Chart from 'react-apexcharts';
import alerts from '../_components/alerts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faImage, faLayerGroup, faFilter } from '@fortawesome/free-solid-svg-icons';
import { ModalParametros } from '../_components/modal';
import { crudService } from '../_services';
import {
    Navbar, Nav, NavItem, UncontrolledDropdown,
    DropdownToggle, DropdownItem, DropdownMenu
} from 'reactstrap';

const styles = theme => ({
    mapFrame: {
        height: 'auto',
        left: '0',
        right: '0',
        top: '40',
        position: 'absolute',
        display: 'block'
    },
    appFrame: {
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
    }
});

const mapStyles = {
    width: '100%',
    height: '100%'
};

const exampleMapStyles = [
    {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
            {
                color: "#eeeeee",
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "labels.text",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#9e9e9e",
            },
        ],
    },
];

const markerStyle = {
    height: 25,
    width: 25,
    cursor: 'pointer',
    zIndex: 10,
    marginTop: '0px', //-15 centralizar no ponto
    marginLeft: '0px', //-12 centralizar no ponto
};

const infoWindowStyle = {
    top: '0px',
    overflow: 'hidden',
    backgroundColor: 'white',
    padding: 8,
    fontSize: 12,
};

const infoWindowStyleAna = {
    top: '0px',
    left: -250,
    width: 500,
    backgroundColor: 'white',
    padding: 8,
    fontSize: 12,
};

const infoWindowStyleCemaden = {
    top: '0px',
    overflow: 'hidden',
    backgroundColor: 'white',
    padding: 8,
    fontSize: 12,
};

const infoDados = {
    textAlign: 'left',
    verticalAlign: 'middle',
    fontSize: 13,
    padding: 3,
    margin: 0,
};

const infoCurso = {
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: 11,
    paddingTop: 2,
    margin: 0,
};

const infoParametros = {
    textAlign: 'left',
    verticalAlign: 'middle',
    fontSize: 12,
    margin: 0,
    padding: 2,
};

const modalLegenda = {
    borderWidth: '2',
    borderColor: '#000000',
    borderRadius: '50',
    position: 'absolute',
    bottom: 30,
    left: 10,
    backgroundColor: '#FFFFFF',
};

const buttonModalLegenda = {
    borderWidth: '2',
    borderColor: '#909090',
    borderRadius: '50',
    position: 'absolute',
    bottom: 30,
    left: 10,
    backgroundColor: '#ffffff'
};

const Index = (props) => {

    const dispatch = useDispatch();
    const [places, setPlaces] = useState([]);
    const [camadas, setCamadas] = useState([]);

    const _ = require("lodash");
    const { compose, lifecycle, withProps, withState, withHandlers, withStateHandlers } = require("recompose");

    const [zoom, setZoom] = useState(10);
    const [zoomMap, setZoomMap] = useState(10);
    const [lat, setLat] = useState(-29.6378158);
    const [lon, setLon] = useState(-50.7858696);

    const [perimetro, setPerimetro] = useState(true);
    const [arroios, setArroios] = useState(true);
    const [municipios, setMunicipios] = useState(true);
    const [rioSinos, setRioSinos] = useState(true);
    const [principais, setPrincipais] = useState(true);
    const [legenda, setLegenda] = useState(false);
    const [pontosProSinos, setPontosProSinos] = useState(false);
    const [pontosAna, setPontosAna] = useState(false);
    const [pontosCemaden, setPontosCemaden] = useState(false);
    const [data, setData] = useState('');

    const { classes } = props;

    const handleSelectChange = prop => event => {
        if (prop == 'data') {
            setData(event.value);
        }
    };

    useEffect(() => {

        var apiEndpoint = 'pontosMonitoramento/public';
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    setPlaces(response.data.pontosMonitoramento);
                }
            }).catch((err) => {
                // console.log(err);
                alerts.error(err);
            });

        apiEndpoint = 'camadas/public';
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    setCamadas(response.data.camadas);
                    //console.log('CAMADAS2>', response.data.camadas)
                }
            }).catch((err) => {
                //   console.log(err);
                alerts.error(err);
            });

        /*        getDataAtual();*/

        dispatch(analisesAction.getAnaliseSelectDatas());

    }, []);

    const renderOptionsData = () => {
        //traz somente as datas distintas
        var unique = [...new Map(props.analise.analise.map(item =>
            [item['text'], item])).values()];

        return unique.map((p, i) => {
            return (
                { value: p.value, label: p.text }
            );
        });
    };

    /*useEffect(() => {
        getDataAtual();
    }, [places]);*/

    const openImage = (place, imagem) => {
        alerts.showImage(imagem, place.nome);
    }

    /*const getDataAtual = () => {
        var today = new Date();  
        console.log("today", today)
        setDataMesPassado(today - 2628000000);
        setDataAtual(today);
    }*/

    const retornaClasseCursoAgua = () => {
        //  console.log("PROPS", props)
        const { cor } = props.state.parametroAguaFaixa.cor;

        //console.log("COR", cor)
        var classe;

        try {
            if (cor == "#e74c3c") {  //vermelho
                classe = "Classe 4";
            } else if (cor == "#f1c40f") {  //amarelo
                classe = "Classe 3";
            } else if (cor == "#f1c40f" && cor == "#2ecc71") {  //amarelo e verde
                classe = "Classe 2";
            } else if (cor == "#3498db") { //azul
                classe = "Classe 1";
            }

        } catch (error) {
            return "";
        }

        return classe;
    }

    const existeAnaliseRecente = (dataAnalise) => {

        var dataReferencia = moment().subtract(1, 'months');

        if (Date.parse(dataAnalise) <= Date.parse(dataReferencia)) {
            return false;
        } else {
            return true;
        }
    }

    const onZoomChanged = (prop) => {
        //   console.log("zoom: " + prop);
        //setZoom2(prop);
    };

    const changeVisible = (id_camada) => {
        //console.log("CAMADA::", camadas)

        //find in array
        var obj = camadas.find((element) => {
            return element.camada.id === id_camada;
        })

        //obj.v = !obj.v
        //console.log("CAMADA obj::", obj)

        var newCamadas = camadas.map(
            el => el.camada.id === id_camada ? { ...el, v: !obj.v } : el
        )

        /*setCamadas(prevState => ({
            camadas: prevState.map(
                el => el.camada.id === id_camada ? { ...el, v: false } : el
            )
        }))*/

        setCamadas(newCamadas);

        //console.log("CAMADAS NEW::", newCamadas)

    };

    useEffect(() => {
        //console.log("CAMADAS NEW::", camadas)
    }, [camadas]);

    const MapMarkerSearchBox = compose(
        withProps({
            googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDX5xiv8klIvdWN5yvY0zaqFF2M9z_4mgI&v=3.38",
            loadingElement: <div style={{ height: `100%` }} />,
            containerElement: <div style={{ height: `92vh` }} />,
            mapElement: <div style={{ height: `100%` }} />
        }),
        withStateHandlers(() => ({
            selectedMarker: null,
            modal: false,
            analise: null,
            cemaden: null,
            ana: null
        }), {
            onToggleOpen: ({ selectedMarker }) => (index) => ({
                selectedMarker: index
            }),
            toggle: ({ modal }) => () => ({
                modal: !modal
            }),
            setCemaden: ({ cemaden }) => (value) => ({
                cemaden: value
            }),
            setAna: ({ ana }) => (value) => ({
                ana: value
            }),
            setAnalise: ({ analise }) => (value) => ({
                analise: value
            })
        }),
        withState('zoom', 'onZoomChange', zoom),
        withHandlers(() => {

            const refs = {
                map: undefined,
            }

            return {
                onMapMounted: () => ref => {
                    refs.map = ref
                },
                /*onZoomChanged: ({ onZoomChange }) => () => {
                    setLat(refs.map.props.defaultCenter.lat)
                    setLon(refs.map.props.defaultCenter.lng)
                    setZoomMap(refs.map.getZoom())
                },*/
                componentWillMount() {
                    this.setState({
                        places: [],
                        onPlacesChanged: () => {
                            const places = refs.searchBox.getPlaces();

                            this.setState({
                                places,
                            });

                            const nextMarkers = places.map(place => ({
                                position: place.geometry.location,
                            }));
                            const nextCenter = _.get(nextMarkers, '0.position', this.state.center);

                            this.setState({
                                center: nextCenter,
                                markers: nextMarkers,
                            });
                        },
                    })
                },

                getInfo: (props) => (key, fonte) => {

                    const { setAnalise, setCemaden, setAna } = props;

                    if (fonte == '') {

                        setAnalise(null);
                        let filterCemaden = {
                            IdPonto: key,
                            Periodos: data
                        };

                        alerts.waitingToast('Carregando histórico de IQA');
                        let apiEndpoint = 'analises/grafico' + stringUtils.serialize(filterCemaden);
                        crudService.get(apiEndpoint)
                            .then((response) => {

                                if (response.data && response.data.graficoIQAPontoMonitoramento != null) {

                                    response.data.graficoIQAPontoMonitoramento = {
                                        options: {
                                            chart: {
                                                id: "basic-bar",
                                                toolbar: {
                                                    show: false
                                                },
                                            },
                                            xaxis: {
                                                categories: response.data.graficoIQAPontoMonitoramento.options,
                                                labels: {
                                                    show: false,
                                                    style: {
                                                        fontSize: '9px'
                                                    }
                                                }
                                            },
                                        },
                                        series: [
                                            {
                                                name: "IQA",
                                                data: response.data.graficoIQAPontoMonitoramento.series[0]
                                            }
                                        ]
                                    };

                                    setAnalise(response.data);
                                    alerts.clean();

                                }

                            }).catch((err) => {
                                // console.log(err);
                                alerts.error(err);
                            });

                    } else if (fonte == 'CEMADEN') {

                        setCemaden(null);
                        let filterCemaden = {
                            IdPonto: key,
                            Periodos: 96
                        };

                        alerts.waitingToast('Carregando histórico do ponto');
                        let apiEndpoint = 'cemaden' + stringUtils.serialize(filterCemaden);
                        crudService.get(apiEndpoint)
                            .then((response) => {

                                if (response.data && response.data.graficoPluviometriaAcumulada != null) {

                                    response.data.graficoPluviometriaAcumulada = {
                                        options: {
                                            chart: {
                                                id: "basic-bar",
                                                toolbar: {
                                                    show: false
                                                },
                                            },
                                            xaxis: {
                                                categories: response.data.graficoPluviometriaAcumulada.options,
                                                labels: {
                                                    show: false,
                                                    style: {
                                                        fontSize: '9px'
                                                    }
                                                }
                                            },
                                        },
                                        series: [
                                            {
                                                name: "Chuva acumulada",
                                                data: response.data.graficoPluviometriaAcumulada.series[0]
                                            }
                                        ]
                                    };

                                    setCemaden(response.data);
                                    alerts.clean();

                                }

                            }).catch((err) => {
                                //   console.log(err);
                                alerts.error(err);
                            });

                    } else if (fonte == 'ANA') {

                        setAna(null);
                        let filterAna = {
                            IdPonto: key,
                            Datainicio: '',
                            DataFim: ''
                        };

                        alerts.waitingToast('Carregando histórico do ponto');
                        let apiEndpoint = 'ana' + stringUtils.serialize(filterAna);
                        crudService.get(apiEndpoint)
                            .then((response) => {

                                if (response.data && response.data.graficoChuva != null && response.data.graficoNivel != null && response.data.graficoVazao != null) {

                                    response.data.graficoChuva = {
                                        options: {
                                            stroke: {
                                                curve: 'smooth',
                                            },
                                            chart: {
                                                id: "basic-bar",
                                                toolbar: {
                                                    show: false
                                                },
                                            },
                                            xaxis: {
                                                categories: response.data.graficoChuva.options,
                                                labels: {
                                                    show: false,
                                                    style: {
                                                        fontSize: '9px'
                                                    }
                                                }
                                            },
                                        },
                                        series: [
                                            {
                                                name: "Chuva acumulada",
                                                data: response.data.graficoChuva.series[0]
                                            }
                                        ]
                                    };

                                    response.data.graficoNivel = {
                                        options: {
                                            stroke: {
                                                curve: 'smooth',
                                            },
                                            chart: {
                                                id: "basic-bar",
                                                toolbar: {
                                                    show: false
                                                },
                                            },
                                            xaxis: {
                                                categories: response.data.graficoNivel.options,
                                                labels: {
                                                    show: false,
                                                    style: {
                                                        fontSize: '9px'
                                                    }
                                                }
                                            },
                                        },
                                        series: [
                                            {
                                                name: "Nível",
                                                data: response.data.graficoNivel.series[0]
                                            }
                                        ]
                                    };

                                    response.data.graficoVazao = {
                                        options: {
                                            stroke: {
                                                curve: 'smooth',
                                            },
                                            chart: {
                                                id: "basic-bar",
                                                toolbar: {
                                                    show: false
                                                },
                                            },
                                            xaxis: {
                                                categories: response.data.graficoVazao.options,
                                                labels: {
                                                    show: false,
                                                    style: {
                                                        fontSize: '9px'
                                                    }
                                                }
                                            },
                                        },
                                        series: [
                                            {
                                                name: "Vazão",
                                                data: response.data.graficoVazao.series[0]
                                            }
                                        ]
                                    };

                                    setAna(response.data);
                                    alerts.clean();

                                }

                            }).catch((err) => {
                                //   console.log(err);
                                alerts.error(err);
                            });
                    }
                }
            }
        }),
        withScriptjs,
        withGoogleMap
    )(props2 =>
        <div className={classes.mapStyles}>
            <Navbar style={{ borderWidth: 1, position: 'absolute', top: 114, left: 0, alignSelf: 'flex-end' }} className="navbar" expand="md">
                <Nav className="ml-auto">
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle className="btn bg-light m-0 p-2" nav caret>
                            <FontAwesomeIcon icon={faLayerGroup} />
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem>
                                <input onChange={() => setRioSinos(!rioSinos)} checked={rioSinos} type="checkbox" /> Rio dos Sinos
                            </DropdownItem>
                            <DropdownItem>
                                <input onChange={() => setPrincipais(!principais)} checked={principais} type="checkbox" /> Cursos d'água Principais
                            </DropdownItem>
                            <DropdownItem>
                                <input onChange={() => setArroios(!arroios)} checked={arroios} type="checkbox" /> Arroios
                            </DropdownItem>
                            <DropdownItem>
                                <input onChange={() => setMunicipios(!municipios)} checked={municipios} type="checkbox" /> Delimitação de Municípios
                            </DropdownItem>
                            <DropdownItem>
                                <input onChange={() => setPerimetro(!perimetro)} checked={perimetro} type="checkbox" /> Perímetro da Bacia
                            </DropdownItem>
                            <DropdownItem>
                                <input onChange={() => setPontosProSinos(!pontosProSinos)} checked={pontosProSinos} type="checkbox" /> Pontos Pró Sinos
                            </DropdownItem>
                            <DropdownItem>
                                <input onChange={() => setPontosAna(!pontosAna)} checked={pontosAna} type="checkbox" /> Pontos ANA
                            </DropdownItem>
                            <DropdownItem>
                                <input onChange={() => setPontosCemaden(!pontosCemaden)} checked={pontosCemaden} type="checkbox" /> Pontos CEMADEN
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
            </Navbar>
            <Navbar style={{ borderWidth: 1, position: 'absolute', top: 114, left: 50, alignSelf: 'flex-end' }} className="navbar" expand="md">
                <Nav className="ml-auto">
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle className="btn bg-light m-0 p-2" nav caret>
                            <FontAwesomeIcon icon={faFilter} />
                        </DropdownToggle>
                        <DropdownMenu>
                            {camadas.map((n, index) => {
                                return (
                                    <DropdownItem key={index}>
                                        <input checked={n.v} onChange={() => changeVisible(n.camada.id)} type="checkbox" /> {n.camada.nome}
                                    </DropdownItem>
                                )
                            })
                            }
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    {pontosProSinos &&
                        <UncontrolledDropdown nav inNavbar style={{ width: 150, marginLeft: 3 }}>
                            <Select style={{ width: 150, marginLeft: 3 }}
                                value={renderOptionsData().filter(function (option) {
                                    return option.value == (data || '');
                                })}
                                onChange={handleSelectChange('data')}
                                isSearchable
                                id="data"
                                name="data"
                                options={renderOptionsData()}
                            />
                        </UncontrolledDropdown>
                    }
                </Nav>
            </Navbar>
            {
                legenda ?
                    <div
                        style={modalLegenda} className="navbar" expand="md">
                        < table >
                            <tbody>
                                <img src={require('../imgs/fechar.png')} width="10px" height="10px" onClick={() => setLegenda(false)} />
                                <tr>
                                    <td colSpan="2"><b>Legenda IQA</b></td>
                                </tr>
                                <tr>
                                    <td><img src={require('../imgs/ponto_quali_mapa_25.png')} width="25px" height="21px" /></td>
                                    <td>Acima de 0 até 25</td>
                                </tr>
                                <tr>
                                    <td><img src={require('../imgs/ponto_quali_mapa_50.png')} width="25px" height="21px" /></td>
                                    <td>Acima de 25 e até 50</td>
                                </tr>
                                <tr>
                                    <td><img src={require('../imgs/ponto_quali_mapa_70.png')} width="25px" height="21px" /></td>
                                    <td>Acima de 50 e até 70</td>
                                </tr>
                                <tr>
                                    <td><img src={require('../imgs/ponto_quali_mapa_90.png')} width="25px" height="21px" /></td>
                                    <td>Acima de 70 e até 90</td>
                                </tr>
                                <tr>
                                    <td><img src={require('../imgs/ponto_quali_mapa_100.png')} width="25px" height="21px" /></td>
                                    <td>Acima de 90 e até 100</td>
                                </tr>
                            </tbody>
                        </table >

                    </div >
                    :
                    <div style={buttonModalLegenda} className="navbar" expand="md">
                        <table>
                            <tbody>
                                <tr>
                                    <td><img src={require('../imgs/abrir.png')} width="20px" height="20px" onClick={() => setLegenda(true)} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
            }
            {
                ((places != null && places.length > 0) ? (
                    <GoogleMap
                        defaultCenter={{ lat: lat, lng: lon }}
                        options={{
                            styles:
                                [
                                    {
                                        "featureType": "all",
                                        "elementType": "geometry",
                                        "stylers": [
                                            {
                                                "color": "#CCE0C9"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "administrative.country",
                                        "elementType": "labels.text",
                                        "stylers": [
                                            {
                                                "visibility": "on"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "poi.park",
                                        "elementType": "geometry",
                                        "stylers": [
                                            {
                                                "hue": "#93C89B"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "poi",
                                        "elementType": "geometry",
                                        "stylers": [
                                            {
                                                "hue": "#00FF6A"
                                            },
                                            {
                                                "saturation": -1.0989010989011234
                                            },
                                            {
                                                "lightness": 11.200000000000017
                                            },
                                            {
                                                "gamma": 1
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "road.highway",
                                        "elementType": "geometry.fill",
                                        "stylers": [
                                            {
                                                "color": "#FFE06B"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "road.highway",
                                        "elementType": "geometry.stroke",
                                        "stylers": [
                                            {
                                                "color": "#FFE06B"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "road.highway.controlled_access",
                                        "elementType": "geometry.fill",
                                        "stylers": [
                                            {
                                                "visibility": "on"
                                            },
                                            {
                                                "color": "#FFE06B"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "road.highway.controlled_access",
                                        "elementType": "geometry.stroke",
                                        "stylers": [
                                            {
                                                "color": "#FFE06B"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "road.arterial",
                                        "elementType": "geometry.fill",
                                        "stylers": [
                                            {
                                                "visibility": "on"
                                            },
                                            {
                                                "color": "#FFE06B"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "road.arterial",
                                        "elementType": "geometry.stroke",
                                        "stylers": [
                                            {
                                                "color": "#FFE06B"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "transit.station.airport",
                                        "elementType": "geometry.fill",
                                        "stylers": [
                                            {
                                                "color": "#cdcdcd"
                                            },
                                            {
                                                "gamma": "1"
                                            },
                                            {
                                                "lightness": "0"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "water",
                                        "elementType": "all",
                                        "stylers": [
                                            {
                                                "color": "#659ee6"
                                            },
                                            {
                                                "visibility": "on"
                                            }
                                        ]
                                    }
                                ]
                        }}
                        zoom={zoomMap}
                        ref={props2.onMapMounted}
                        onZoomChanged={props2.onZoomChanged}
                    >
                        {

                            places.map((n, index) => {
                                var pontoMonitoramento = n.pontoMonitoramento;
                                var ultimaAnalise = n.ultimaAnalise;
                                var iqaCalculado = n.iqaCalculado;
                                var iqaExcel = (ultimaAnalise != null ? ultimaAnalise.iqa : 0);

                                if (pontoMonitoramento.latitude == null || pontoMonitoramento.latitude === '') {
                                    return;
                                }

                                if (pontoMonitoramento.longitude == null || pontoMonitoramento.longitude === '') {
                                    return;
                                }

                                let varCemaden;
                                if (pontosCemaden == true) {
                                    if (pontoMonitoramento.fonteExterna != null && pontoMonitoramento.fonteExterna.nome == 'CEMADEN') {
                                        varCemaden = props2.cemaden;
                                    } else {
                                        varCemaden = null;
                                    }
                                }

                                let varAna;
                                if (pontoMonitoramento.fonteExterna != null && pontoMonitoramento.fonteExterna.nome == 'ANA') {
                                    varAna = props2.ana;
                                } else {
                                    varAna = null;
                                }

                                let varAnalise;

                                if (pontoMonitoramento.fonteExterna == null) {
                                    varAnalise = props2.analise;
                                } else {
                                    varAnalise = null;
                                }

                                let icon;

                                if (pontoMonitoramento.fonteExterna != null && pontoMonitoramento.fonteExterna.nome == 'CEMADEN') {
                                    icon = {
                                        url: require('../imgs/ponto_cemaden.png'),
                                        scaledSize: new window.google.maps.Size(25, 25),
                                    };

                                } else if (pontoMonitoramento.fonteExterna != null && pontoMonitoramento.fonteExterna.nome == 'ANA') {
                                    icon = {
                                        url: require('../imgs/ponto_ana.png'),
                                        scaledSize: new window.google.maps.Size(25, 25),
                                    };

                                }

                                else {

                                    if ((iqaExcel) <= 25) {
                                        icon = {
                                            url: require('../imgs/ponto_quali_mapa_25.png'),
                                            scaledSize: new window.google.maps.Size(25, 21),
                                        };
                                    } else if ((iqaExcel) <= 50) {
                                        icon = {
                                            url: require('../imgs/ponto_quali_mapa_50.png'),
                                            scaledSize: new window.google.maps.Size(25, 21),
                                        };
                                    } else if ((iqaExcel) <= 70) {
                                        icon = {
                                            url: require('../imgs/ponto_quali_mapa_70.png'),
                                            scaledSize: new window.google.maps.Size(25, 21),
                                        };
                                    } else if ((iqaExcel) <= 90) {
                                        icon = {
                                            url: require('../imgs/ponto_quali_mapa_90.png'),
                                            scaledSize: new window.google.maps.Size(25, 21),
                                        };
                                    } else if ((iqaExcel) <= 100) {
                                        icon = {
                                            url: require('../imgs/ponto_quali_mapa_100.png'),
                                            scaledSize: new window.google.maps.Size(25, 21),
                                        };
                                    } else {
                                        icon = {
                                            url: require('../imgs/ponto_quali_mapa.png'),
                                            scaledSize: new window.google.maps.Size(21, 21),
                                        };
                                    }

                                }

                                let place = {
                                    nome: pontoMonitoramento.nome,
                                    ana: varAna,
                                    cemaden: varCemaden,
                                    analise: varAnalise,
                                    curso: (pontoMonitoramento.cursoDagua != null ? pontoMonitoramento.cursoDagua.nome : ''),
                                    codigoReferencia: pontoMonitoramento.codigoReferencia,
                                    data: (ultimaAnalise ? ultimaAnalise.data : null),
                                    entidade: (ultimaAnalise ? ultimaAnalise.entidade : null),
                                    info: (ultimaAnalise ? ultimaAnalise.analisesParametros : null),
                                    iqa: (ultimaAnalise ? ultimaAnalise.iqa : null),
                                    iqaCalculado: (iqaCalculado ? iqaCalculado : null),
                                    fonteExterna: pontoMonitoramento.fonteExterna,
                                    cursoAgua: pontoMonitoramento.cursoAgua,
                                    cor: (ultimaAnalise ? ultimaAnalise.analisesParametros.parametroAgua : null)
                                }

                                if ((place.fonteExterna != null && place.fonteExterna.nome == 'ANA' && pontosAna)
                                    || (place.fonteExterna != null && place.fonteExterna.nome == 'CEMADEN' && pontosCemaden)
                                    || (place.fonteExterna == null && pontosProSinos)) {
                                    return (
                                        <Marker
                                            key={pontoMonitoramento.id}
                                            title={pontoMonitoramento.codigo || ''}
                                            position={{ lat: pontoMonitoramento.latitude, lng: pontoMonitoramento.longitude }}
                                            icon={icon}
                                            style={markerStyle}
                                            onClick={(event) => {
                                                props2.onToggleOpen(index);
                                                props2.getInfo(pontoMonitoramento.id, (pontoMonitoramento.fonteExterna ? pontoMonitoramento.fonteExterna.nome : ''));
                                            }}
                                            draggable={false}
                                        >


                                            {props2.selectedMarker == index &&
                                                <InfoWindow onCloseClick={() => props2.onToggleOpen(null)} >
                                                    <>
                                                        <div style={(place.fonteExterna != undefined && place.fonteExterna.nome == 'ANA' ? (place.fonteExterna != undefined && place.fonteExterna.nome == 'CEMADEN' ? infoWindowStyleCemaden : infoWindowStyle) : infoWindowStyle)}>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <span className="h6">{pontoMonitoramento.codigo || ''} - {place.nome}</span>
                                                                    {(place.curso != '' ? <p style={infoCurso}><b>CURSO D'ÁGUA: </b> {place.curso} {(place.codigoReferencia != '' ? '(' + place.codigoReferencia + ')' : '')}</p> : '')}
                                                                    {data != '' && place.analise != null ? <span className="badge ml-0 p-1" style={{ backgroundColor: '#90bafc', fontWeight: 500, fontSize: 12, paddingTop: 5 }}>Dados obtidos referente o Ano/Mês {data ? data.replace("-", "/") : ''}</span> :
                                                                        data != '' && place.analise == null && place.data != undefined ? <span className="badge ml-0 p-1" style={{ backgroundColor: '#90bafc', fontWeight: 500, fontSize: 12, paddingTop: 5 }}>Sem análise para o período selecionado, exibindo a última análise cadastrada</span> :
                                                                            (data == '' || data != undefined) && place.data != undefined ? <span className="badge ml-0 p-1" style={{ backgroundColor: '#90bafc', fontWeight: 500, fontSize: 12, paddingTop: 5 }}>Exibindo a última análise cadastrada</span> : null}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <hr style={{ margin: 5 }} />
                                                        <div>
                                                            <Row style={{ marginLeft: 0, marginRight: 0 }}>
                                                                <Col style={{ textAlign: 'left', padding: '0' }} md={6}>
                                                                    {data != '' && place.analise != null ? <div style={infoDados}><b>DATA DA ANÁLISE: </b><br />{place.analise.graficoIQAPontoMonitoramento ? place.analise.graficoIQAPontoMonitoramento.options.xaxis.categories[0] : ''}</div>
                                                                        : (place.data != undefined && (existeAnaliseRecente(place.data) || (place.ana == null && place.cemaden == null))) ? <div style={infoDados}><b>DATA DA ANÁLISE: </b><br />{stringUtils.formataDataHora(place.data)}</div>
                                                                            : (<label style={{ fontWeight: 'bold' }}>Sem análise nos últimos 30 dias</label>)}
                                                                    {(place.entidade != undefined ? <div style={infoDados}>{(place.fonteExterna == undefined || place.fonteExterna.nome != 'ANA' || place.fonteExterna.nome != 'CEMADEN' ? <><b>LABORATÓRIO: </b><br /></> : '')}{place.entidade.nome}
                                                                        {(place.fonteExterna != undefined && place.fonteExterna.nome == 'CEMADEN' ?
                                                                            <a href={"http://sjc.salvar.cemaden.gov.br/resources/graficos/cemaden/hidro/hidrologica_interativo.html?est=" + place.codigoReferencia + "&uf=RS"} target="_blank"><FontAwesomeIcon style={{ marginLeft: 5 }} icon={faInfoCircle} /></a> : '')}</div> : '')}
                                                                </Col>
                                                                <Col style={{ textAlign: 'left', padding: '0' }} left="true" md={6}>
                                                                    {data != '' & place.analise != null ? <p style={infoDados}><b>IQA: <span className="mr-2">{stringUtils.formataValor(place.analise.graficoIQAPontoMonitoramento.series[0].data[0])}</span></b>
                                                                        <span className="badge btn ml-0 p-1" style={{ color: '#FFFFFF', backgroundColor: stringUtils.retornaCorIqa(place.analise.graficoIQAPontoMonitoramento.series[0].data[0]) }}>{stringUtils.retornaTextoIqa(place.analise.graficoIQAPontoMonitoramento.series[0].data[0])}</span>
                                                                    </p> : (place.iqa != undefined && place.iqa != '0' ?
                                                                        <p style={infoDados}><b>IQA: <span className="mr-2">{stringUtils.formataValor(place.iqa)}</span></b>
                                                                            <span className="badge btn ml-0 p-1" style={{ color: '#FFFFFF', backgroundColor: stringUtils.retornaCorIqa(place.iqa) }}>{stringUtils.retornaTextoIqa(place.iqa)}</span>
                                                                        </p> : '')}
                                                                    {(place.iqa != undefined && place.iqa != '0' ?
                                                                        <p style={infoDados}><b>CURSO D'ÁGUA: </b><span className="mr-2">CLASSE {stringUtils.retornaClassificacao(place.info)}</span>
                                                                        </p> : '')}
                                                                    <p style={{
                                                                        textAlign: 'left',
                                                                        verticalAlign: 'middle',
                                                                        fontSize: 10,
                                                                        padding: 3,
                                                                        margin: 0,
                                                                    }}><span className="mr-2">* Considerando os parâmetros acompanhados</span> </p>
                                                                    {(place.info != undefined ?
                                                                        <Button className="m-0 p-0" onClick={props2.toggle} color="link">Detalhes</Button> : "")}
                                                                </Col>

                                                                <hr style={{ margin: '3px', borderTop: '2px dotted #bebebe' }} />
                                                            </Row>
                                                        </div>
                                                        {(place.analise != null ?
                                                            <div>
                                                                <hr style={{ margin: '3px', borderTop: '2px dotted #bebebe' }} />
                                                                <h6>Histórico do IQA</h6>
                                                                <Chart
                                                                    options={place.analise.graficoIQAPontoMonitoramento.options}
                                                                    series={place.analise.graficoIQAPontoMonitoramento.series}
                                                                    type="line"
                                                                    height="180"
                                                                />
                                                            </div>
                                                            : '')}
                                                        {(place.cemaden != null && existeAnaliseRecente(place.data)) ?
                                                            <div>
                                                                <hr style={{ margin: '3px', borderTop: '2px dotted #bebebe' }} />
                                                                <h6>Acumulado de chuva <FontAwesomeIcon onClick={() => openImage(place.nome, place.cemaden.imagem)} style={{ marginLeft: 3 }} icon={faImage} size="lg" /></h6>
                                                                <Chart
                                                                    options={place.cemaden.graficoPluviometriaAcumulada.options}
                                                                    series={place.cemaden.graficoPluviometriaAcumulada.series}
                                                                    type="line"
                                                                    height="180"
                                                                />
                                                            </div>
                                                            : ('')}
                                                        {(place.ana != null && existeAnaliseRecente(place.data)) ?
                                                            <div>
                                                                <hr style={{ margin: '3px', borderTop: '2px dotted #bebebe' }} />
                                                                <small className="text-center">(Últimas 24 horas)</small>
                                                                <Row style={{ marginLeft: 0, marginRight: 0 }}>
                                                                    <Col sm="4">
                                                                        <h6 className="text-center pb-0 mb-0">Chuva Acumulada</h6>
                                                                        <Chart
                                                                            options={place.ana.graficoChuva.options}
                                                                            series={place.ana.graficoChuva.series}
                                                                            type="line"
                                                                            height="150"
                                                                        />
                                                                    </Col>
                                                                    <Col sm="4">
                                                                        <h6 className="text-center pb-0 mb-0">Nível do Curso</h6>
                                                                        <Chart
                                                                            options={place.ana.graficoNivel.options}
                                                                            series={place.ana.graficoNivel.series}
                                                                            type="line"
                                                                            height="150"
                                                                        />
                                                                    </Col>
                                                                    <Col sm="4">
                                                                        <h6 className="text-center pb-0 mb-0">Vazão do Curso</h6>
                                                                        <Chart
                                                                            options={place.ana.graficoVazao.options}
                                                                            series={place.ana.graficoVazao.series}
                                                                            type="line"
                                                                            height="150"
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            : ('')}
                                                        <ModalParametros toggle={props2.toggle} modal={props2.modal} parametros={place.info} fonte={place.fonteExterna} />

                                                    </>
                                                </InfoWindow>
                                            }

                                        </Marker>
                                    )

                                } else {
                                    return null;
                                }

                            })
                        }

                        {(perimetro ?
                            <KmlLayer
                                url='https://prosinos-s3-files.s3.sa-east-1.amazonaws.com/camadas/production/bacia_sinos.kml'
                                options={{ preserveViewport: true }}
                            />
                            : '')}
                        {(municipios ?
                            <KmlLayer
                                url='https://prosinos-s3-files.s3.sa-east-1.amazonaws.com/camadas/production/municipios.kmz'
                                options={{ preserveViewport: true }}
                            />
                            : '')}
                        {(arroios ?
                            <KmlLayer
                                url='https://prosinos-s3-files.s3.sa-east-1.amazonaws.com/camadas/production/hidrografia_v6.kmz'
                                options={{ preserveViewport: true }}
                            />
                            : '')}
                        {(principais ?
                            <>
                                <KmlLayer
                                    url='https://prosinos-s3-files.s3.sa-east-1.amazonaws.com/camadas/production/cursos_principais.kmz'
                                    options={{ preserveViewport: true }}
                                />
                                <KmlLayer
                                    url='https://prosinos-s3-files.s3.sa-east-1.amazonaws.com/camadas/production/cursos_secundarios.kmz'
                                    options={{ preserveViewport: true }}
                                />
                                <KmlLayer
                                    url='https://prosinos-s3-files.s3.sa-east-1.amazonaws.com/camadas/production/rolante.kmz'
                                    options={{ preserveViewport: true }}
                                />
                                <KmlLayer
                                    url='https://prosinos-s3-files.s3.sa-east-1.amazonaws.com/camadas/production/arroio_areia.kmz'
                                    options={{ preserveViewport: true }}
                                />
                            </>
                            : '')}
                        {(rioSinos ?
                            <KmlLayer
                                url='https://prosinos-s3-files.s3.sa-east-1.amazonaws.com/camadas/production/sinos_v6.kml'
                                options={{ preserveViewport: true }}
                            />
                            : '')}

                        {camadas.map((n, index) => {
                            return (
                                (n.camada.uploads != null && n.v && n.camada.uploads.map((item, ix) => {
                                    return (
                                        <KmlLayer
                                            key={ix}
                                            url={item.arquivo}
                                            options={{ preserveViewport: true }}
                                        />
                                    )
                                })
                                )
                            )
                        })
                        }
                    </GoogleMap>
                )
                    :
                    null)
            }
        </div >
    );

    return (
        <div>
            <div>
                <NavBarIndex />
            </div>

            <div className={classes.mapStyles}>
                <MapMarkerSearchBox />
            </div>

        </div>
    );

};

Index.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
    return state;
}
const connectedIndexPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(withStyles(styles)(Index)));
export { connectedIndexPage as Index };
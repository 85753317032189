const initialState = {
    cemaden: [],
    idPonto: 0,
    periodos: 0,
    imagem: '',
    graficoPluviometriaAcumulada: {
        options: {},
        series: []
    }
    /*graficoMatriculasFuncoes: {
        options: {},
        series: []
    },
    graficoMatriculasClasses: {
        options: {},
        series: []
    },
    graficoMatriculasNiveisCarreira: {
        options: {},
        series: []
    }*/
};

export function cemaden(state = initialState, action) {
    switch (action.type) {
        case 'CEMADEN_DETAIL':
            return {
                ...state,
                imagem: action.imagem,
                graficoPluviometriaAcumulada: action.graficoPluviometriaAcumulada,
                //graficoMatriculasFuncoes: action.graficoMatriculasFuncoes,
                //graficoMatriculasClasses: action.graficoMatriculasClasses,
                //graficoMatriculasNiveisCarreira: action.graficoMatriculasNiveisCarreira
            };
        default:
            return state;
    }
}

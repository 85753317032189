import React, { Component } from "react";
import { uniqueId } from "lodash";
import filesize from "filesize";
import { Typography } from '@material-ui/core';
import api from "./services/api";
//import { toast } from 'react-toastify';
import GlobalStyle from "./styles/global";
import { Container, Content } from "./styles";
import Upload from "./components/Upload";
import FileList from "./components/FileList";

class UploadField extends Component {
    state = {
        uploadedFiles: []
    };

    sendURLToParent = (url) => {
        this.props.parentCallback(url);
    };

    handleUpload = files => {

        const uploadedFiles = files.map(file => ({
            file,
            id: uniqueId(),
            name: file.name,
            readableSize: filesize(file.size),
            preview: URL.createObjectURL(file),
            progress: 0,
            uploaded: false,
            error: false,
            url: null
        }));

        this.setState({
            uploadedFiles
            // Várias imagens
            // uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles)
        });

        uploadedFiles.forEach(this.processUpload);
    };

    updateFile = (id, data) => {

        this.setState({
            uploadedFiles: this.state.uploadedFiles.map(uploadedFile => {
                return id === uploadedFile.id
                    ? { ...uploadedFile, ...data }
                    : uploadedFile;
            })
        });
    };

    processUpload = uploadedFile => {

        const data = new FormData();

        data.append("file", uploadedFile.file, uploadedFile.name);

        api.post("api/uploads/S3", data, {
            onUploadProgress: e => {
                const progress = parseInt(Math.round((e.loaded * 100) / e.total));

                this.updateFile(uploadedFile.id, {
                    progress
                });
            },
            headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` }
        })
            .then(response => {
                this.updateFile(uploadedFile.id, {
                    uploaded: true,
                    id: response.data._id,
                    url: response.data.url
                });
                this.sendURLToParent(response.data.url);
                //toast.success('Feito! Upload de imagem finalizado! 👏');
            })
            .catch(() => {
                this.updateFile(uploadedFile.id, {
                    error: true
                });
            });
    };

    handleDelete = async id => {
        await api.delete(`uploads/${id}`);

        this.setState({
            uploadedFiles: this.state.uploadedFiles.filter(file => file.id !== id)
        });
    };

    componentWillUnmount() {
        this.state.uploadedFiles.forEach(file => URL.revokeObjectURL(file.preview));
    }

    render() {

        const { uploadedFiles } = this.state;
        if (this.props.url) {

            if (!uploadedFiles.some(e => e.url === this.props.url)) {
                uploadedFiles.push({
                    id: this.props.url,
                    name: this.props.url,
                    readableSize: 0,
                    preview: this.props.url,
                    uploaded: true,
                    url: this.props.url
                });
            }

        }

        return (
            <Container>
                <Content>
                    <Typography variant="inherit">{this.props.labelText}</Typography>
                    <Upload onUpload={this.handleUpload} />
                    {!!uploadedFiles.length && (
                        <FileList files={uploadedFiles} onDelete={this.handleDelete} />
                    )}
                </Content>
                <GlobalStyle />
            </Container>
        );
    }
}

export default UploadField;

import { fontesExternasAction } from "../_actions";

const initialState = {
    page: 1,
    limit: 10,
    order: 'nome',
    direction: 'asc',
    fonteExterna: [],
    id: '',
    nome: '',
    link: '',
    linkAcumulado: '',
    excluido: ''
};

export function fonteExterna(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_FONTE_EXTERNA':
            return {
                ...state,
                fonteExterna: action.fonteExterna,
                totalPages: action.totalPages
            };
        case 'FONTE_EXTERNA_DETAIL':
            return {
                ...state,
                id: action.id,
                nome: action.nome,
                link: action.link,
                linkAcumulado: action.linkAcumulado,
                excluido: action.excluido
            };
        case 'FONTE_EXTERNA_CLEAR':
            return {
                ...state,
                fonteExterna: [],
                id: '',
                nome: '',
                link: '',
                linkAcumulado: '',
                excluido: ''
            };
        case "FONTE_EXTERNA_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_FONTE_EXTERNA":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state;
    }
}

import { crudService } from '../_services';
import alerts from '../_components/alerts';
import { stringUtils } from '../_utils';

export const graficoParametrosQuantitativosAction = {
    getGrafico,
    onChangeProps,
    handleOnChangeProps,
    clear
};

function getGrafico(filterModel, showWaiting) {
    return dispatch => {
        if (showWaiting) {
            alerts.waiting();
        }
        let apiEndpoint = 'graficoParametrosQuantitativos';
        crudService.get(apiEndpoint + stringUtils.serialize(filterModel))
            .then((response) => {
                if (response.data) {
                    if (response.data.graficoParametrosQuantitativosDataAtual != null) {
                        response.data.graficoParametrosQuantitativosDataAtual = {
                            options: {
                                chart: {
                                    id: "basic-bar",
                                    animations: {
                                        speed: 300
                                    },
                                },
                                dataLabels: {
                                    position: 'top',
                                    enabled: true,
                                    style: {
                                        fontWeight: 'normal',
                                        colors: ['#000']
                                    },
                                    offsetY: -20
                                },
                                plotOptions: {
                                    bar: {
                                        distributed: false,
                                        horizontal: false,
                                        dataLabels: {
                                            position: 'top'
                                        }
                                    }
                                },
                                xaxis: {
                                    enabled: false,
                                    labels: {
                                        rotate: -45,
                                        type: 'category'
                                    },
                                    style: {
                                        fontSize: '7px'
                                    },
                                    categories: response.data.graficoParametrosQuantitativosDataAtual.options
                                }
                            },
                            series: [
                                {
                                    name: "Valor",
                                    data: response.data.graficoParametrosQuantitativosDataAtual.series[0],
                                    colors: '#4d3a96'
                                }
                            ]
                        };
                    }
                    else {
                        response.data.graficoParametrosQuantitativosDataAtual = {
                            options: {},
                            series: []
                        }
                    }

                    if (response.data.graficoParametrosQuantitativosDataUmAnoAtras != null) {
                        response.data.graficoParametrosQuantitativosDataUmAnoAtras = {
                            options: {
                                chart: {
                                    id: "basic-bar",
                                    animations: {
                                        speed: 300
                                    },
                                },
                                dataLabels: {
                                    position: 'top',
                                    enabled: true,
                                    style: {
                                        fontWeight: 'normal',
                                        colors: ['#000']
                                    },
                                    offsetY: -20
                                },
                                plotOptions: {
                                    bar: {
                                        distributed: false,
                                        horizontal: false,
                                        dataLabels: {
                                            position: 'top'
                                        }
                                    }
                                },
                                xaxis: {
                                    enabled: false,
                                    labels: {
                                        rotate: -45,
                                        type: 'category'
                                    },
                                    style: {
                                        fontSize: '7px'
                                    },
                                    categories: response.data.graficoParametrosQuantitativosDataUmAnoAtras.options
                                }
                            },
                            series: [
                                {
                                    name: "Valor",
                                    data: response.data.graficoParametrosQuantitativosDataUmAnoAtras.series[0],
                                    colors: '#4d3a96'
                                }
                            ]
                        };
                    }
                    else {
                        response.data.graficoParametrosQuantitativosDataUmAnoAtras = {
                            options: {},
                            series: []
                        }
                    }

                    if (response.data.graficoParametrosQuantitativosDataDoisAnosAtras != null) {
                        response.data.graficoParametrosQuantitativosDataDoisAnosAtras = {
                            options: {
                                chart: {
                                    id: "basic-bar",
                                    animations: {
                                        speed: 300
                                    },
                                },
                                dataLabels: {
                                    position: 'top',
                                    enabled: true,
                                    style: {
                                        fontWeight: 'normal',
                                        colors: ['#000']
                                    },
                                    offsetY: -20
                                },
                                plotOptions: {
                                    bar: {
                                        distributed: false,
                                        horizontal: false,
                                        dataLabels: {
                                            position: 'top'
                                        }
                                    }
                                },
                                xaxis: {
                                    enabled: false,
                                    labels: {
                                        rotate: -45,
                                        type: 'category'
                                    },
                                    style: {
                                        fontSize: '7px'
                                    },
                                    categories: response.data.graficoParametrosQuantitativosDataDoisAnosAtras.options
                                }
                            },
                            series: [
                                {
                                    name: "Valor",
                                    data: response.data.graficoParametrosQuantitativosDataDoisAnosAtras.series[0],
                                    colors: '#4d3a96'
                                }
                            ]
                        };
                    }
                    else {
                        response.data.graficoParametrosQuantitativosDataDoisAnosAtras = {
                            options: {},
                            series: []
                        }
                    }

                    dispatch(editGraficosDetails(response.data));
                }
                if (showWaiting) {
                    alerts.clean();
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}

function onChangeProps(props, event) {
    return dispatch => {
        if (event.target.type === 'checkbox') {
            dispatch(handleOnChangeProps(props, event.target.checked));
        } else {
            dispatch(handleOnChangeProps(props, event.target.value));

        }
    };
}

export function changeGraficosList(graficoParametrosQuantitativos, totalPages) {
    return {
        type: "FETECHED_ALL_GRAFICO_PARAMETROS_QUANTITATIVOS",
        graficoParametrosQuantitativosDataAtual: graficoParametrosQuantitativos.graficoParametrosQuantitativosDataAtual,
        graficoParametrosQuantitativosDataUmAnoAtras: graficoParametrosQuantitativos.graficoParametrosQuantitativosDataUmAnoAtras,
        graficoParametrosQuantitativosDataDoisAnosAtras: graficoParametrosQuantitativos.graficoParametrosQuantitativosDataDoisAnosAtras,
        totalPages: totalPages
    };
}
export function clear() {
    return {
        type: "GRAFICO_PARAMETROS_QUANTITATIVOS_CLEAR"
    };
}
export function handleOnChangeProps(props, value) {
    return {
        type: "HANDLE_ON_CHANGE_GRAFICO_PARAMETROS_QUANTITATIVOS",
        props: props,
        value: value
    };
}
export function editGraficosDetails(graficoParametrosQuantitativos) {
    return {
        type: "GRAFICO_PARAMETROS_QUANTITATIVOS_DETAIL",
        graficoParametrosQuantitativosDataAtual: graficoParametrosQuantitativos.graficoParametrosQuantitativosDataAtual,
        graficoParametrosQuantitativosDataUmAnoAtras: graficoParametrosQuantitativos.graficoParametrosQuantitativosDataUmAnoAtras,
        graficoParametrosQuantitativosDataDoisAnosAtras: graficoParametrosQuantitativos.graficoParametrosQuantitativosDataDoisAnosAtras,
        dataInicial: graficoParametrosQuantitativos.dataInicial,
        dataFinal: graficoParametrosQuantitativos.dataFinal,
        idPontoMonitoramento: graficoParametrosQuantitativos.idPontoMonitoramento,
        idParametro: graficoParametrosQuantitativos.idParametro
    };
}
export function updatedUserInfo() {
    return {
        type: "GRAFICO_PARAMETROS_QUANTITATIVOS_UPDATED"
    };
}
export function createUserInfo() {
    return {
        type: "GRAFICO_PARAMETROS_QUANTITATIVOS_CREATED_SUCCESSFULLY"
    };
}
export function deleteGraficosDetails() {
    return {
        type: "DELETED_GRAFICO_PARAMETROS_QUANTITATIVOS_DETAILS"
    };
}
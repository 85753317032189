const initialState = {
    log: [],
    id: '',
    tipo: '',
    tabela: '',
    chave: '',
    usuario: '',
    data: '',
    campo: '',
    valorAntigo: '',
    valorNovo: ''
};

export function log(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_LOG':
            return {
                ...state,
                log: action.log
            };
        case 'LOG_DETAIL':
            return {
                ...state,
                id: action.id,
                tipo: action.tipo,
                tabela: action.tabela,
                chave: action.chave,
                usuario: action.usuario,
                data: action.data,
                campo: action.campo,
                valorAntigo: action.valorAntigo,
                valorNovo: action.valorNovo
            };
        case "LOG_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_LOG":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state
    }
}

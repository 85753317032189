import React, { useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { parametrosAguaAction, graficoQualidadeCursoDaguaAction, cursosDaguaAction, analisesAction } from '../_actions';
import { withRouter } from 'react-router-dom';
import {
    Card, CardBody, CardHeader, Container, Row, Col, FormGroup, Label } from 'reactstrap';
import { NavBar } from '../_components/navbar';
import '././styles.css';
import Select from 'react-select';
import { Button } from '@material-ui/core';
import Chart from 'react-apexcharts';
import alerts from '../_components/alerts';

const GraficoQualidadeCursoDagua = (props) => {
    const dispatch = useDispatch();
    const [idCursoDagua, setIdCursoDagua] = React.useState(0);
    const [idParametro, setIdParametro] = React.useState(0);
    const [data, setData] = React.useState('');

    useEffect(() => {
        const { match: { params } } = props;
        dispatch(cursosDaguaAction.getCursoDaguaSelect());
        dispatch(parametrosAguaAction.getParametrosAguaTipo1Select());
        dispatch(analisesAction.getAnaliseSelectDatas());
    }, []);

    const handleSelectChange = prop => event => {
        if (prop === 'data') {
            setData(event.value);
        } else if (prop === 'idCursoDagua') {
            setIdCursoDagua(event.value);
        } else if (prop === 'idParametro') {
            setIdParametro(event.value);
        }
    };

    const renderOptionsData = () => {
        //traz somente as datas distintas
        var unique = [...new Map(props.analise.analise.map(item =>
            [item['text'], item])).values()];

        return unique.map((p, i) => {
            return (
                { value: p.value, label: p.text }
            );
        });
    };

    const renderOptionsCursosDagua = () => {
        return props.cursoDagua.cursoDagua.map((p, i) => {
            return (
                { value: p.value, label: p.text }
            );
        });
    };

    const renderOptionsParametro = () => {
        return props.parametroAgua.parametroAgua.map((p, i) => {
            return (
                { value: p.value, label: p.text }
            );
        });
    };

    const buscarDadosQualidadeCursoDagua = () => {

        if (idParametro != '' && idCursoDagua != '' && data != '') {

               var filterModel = {
                    idParametro: idParametro,
                    idCursoDagua: idCursoDagua,
                    data: data
               } 
                dispatch(graficoQualidadeCursoDaguaAction.getGrafico(filterModel, true));
          
        }
        else {
        alerts.alert('Preencha os campos!');
        }
    }

    return (
        <div>
        <NavBar />
        <Container>
    <Card>
        <CardHeader>
            <Row>
                <Col md={12}>
                    <Label className="center" for="data">Gráfico de Acompanhamento da Qualidade do Curso D'Água</Label>
                </Col>
            </Row>
        </CardHeader>
        <CardBody>
           <Row>
               <Col sm={12} md={4}>
                   <FormGroup>
                       <Label className="left" for="idCursoDagua">Curso D'Água *</Label>
                       <Select
                           className="basic-single"
                           classNamePrefix="select"
                           value={renderOptionsCursosDagua().filter(function (option) {
                               return option.value == (idCursoDagua || '');
                           })}
                           onChange={handleSelectChange('idCursoDagua')}
                           isSearchable
                           id="idCursoDagua"
                           name="idCursoDagua"
                           options={renderOptionsCursosDagua()}
                       />
                   </FormGroup>
               </Col>               
               <Col sm={12} md={2}>
                   <FormGroup>
                      <Label className="left" for="data">Período da Análise *</Label>
                       <Select
                           className="basic-single"
                           classNamePrefix="select"
                           value={renderOptionsData().filter(function (option) {
                               return option.value == (data || '');
                           })}
                           onChange={handleSelectChange('data')}
                           isSearchable
                           id="data"
                           name="data"
                           options={renderOptionsData()}
                       />
                </FormGroup>
                </Col>
               <Col sm={12} md={4}>
                    <FormGroup>
                        <Label className="left" for="idParametro">Parâmetro *</Label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            value={renderOptionsParametro().filter(function (option) {
                                return option.value == (idParametro || '');
                            })}
                            onChange={handleSelectChange('idParametro')}
                            isSearchable
                            id="idParametro"
                            name="idParametro"
                            options={renderOptionsParametro()}
                        />
                    </FormGroup>
                </Col>             
                <Col sm={12} md={2} style={{ alignSelf: 'end' }}>
                    <FormGroup>
                      <Button onClick={() => buscarDadosQualidadeCursoDagua()} variant="contained" color="primary">Buscar</Button>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <div style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                      <div>
                           <Chart
                              options={props.graficoQualidadeCursoDagua.graficoQualidadeCursoDagua.options}
                              series={props.graficoQualidadeCursoDagua.graficoQualidadeCursoDagua.series}
                              type="bar"
                              height="270"
                            />
                        </div>
                    </div>
                </Col>
             </Row>
            <Row>
                <Col sm={12}>
                    <div style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', fontSize: 13 }}>
                        <p>* Clique sobre as expressões 'Valor' ou 'IQA' para visualizar o gráfico do PARÂMETRO ou do IQA isoladamente.</p>
                    </div>
                </Col>
            </Row>
        </CardBody>
    </Card>
  </Container>
 </div>
    );

};

const mapStateToProps = (state) => {
    return state;
};

const connectedGraficoQualidadeCursoDaguaPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(GraficoQualidadeCursoDagua));
export { connectedGraficoQualidadeCursoDaguaPage as GraficoQualidadeCursoDagua };
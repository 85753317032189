import { crudService } from '../_services';
import alerts from '../_components/alerts';
import Swal from 'sweetalert2';
import { stringUtils } from '../_utils';

export const tiposUploadsAction = {
    getTipoUpload,
    getTipoUploadSelect,
    getTipoUploadById,
    onChangeProps,
    editTipoUploadInfo,
    createTipoUpload,
    deleteTipoUploadById,
    clear
};

let filterModel = {
    Page: 1,
    Limit: 10,
    Term: ''
};

function getTipoUpload(filterModel, showWaiting) {
    return dispatch => {
        if (showWaiting) {
            alerts.waiting();
        }
        let apiEndpoint = 'tiposUploads' + stringUtils.serialize(filterModel);
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    dispatch(changeTiposUploadsList(response.data.items, response.data.totalPages));
                }
                if (showWaiting) {
                    alerts.clean();
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function getTipoUploadSelect() {
    return dispatch => {
        let apiEndpoint = 'tiposUploads/select';
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    response.data.unshift({ value: '', text: ' SELECIONE ...' });
                    dispatch(changeTiposUploadsList(response.data, null));
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function createTipoUpload(payload) {
    return dispatch => {
        alerts.waiting();
        let apiEndpoint = 'tiposUploads/';
        crudService.post(apiEndpoint, payload)
            .then((response) => {
                if (response.request.status === 200) {
                    var ret = JSON.parse(response.request.responseText);
                    dispatch(updatedUserInfo());
                    alerts.success(ret.message, ret.path);
                } else {
                    alerts.warning(response.request.responseText);
                }
            });
    };
}
function getTipoUploadById(id, showWaiting) {
    return dispatch => {
        if (showWaiting) {
            alerts.waiting();
        }
        let apiEndpoint = 'tiposUploads/' + id;
        crudService.get(apiEndpoint)
            .then((response) => {
                dispatch(editTiposUploadsDetails(response.data));
                if (showWaiting) {
                    alerts.clean();
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function onChangeProps(props, event) {
    return dispatch => {
        if (event.target.type === 'checkbox') {
            dispatch(handleOnChangeProps(props, event.target.checked));
        } else {
            dispatch(handleOnChangeProps(props, event.target.value));
        }
    };
}
function editTipoUploadInfo(id, payload) {
    return dispatch => {
        alerts.waiting();
        let apiEndpoint = 'tiposUploads/' + id;
        crudService.put(apiEndpoint, payload)
            .then((response) => {
                if (response.request.status === 200) {
                    var ret = JSON.parse(response.request.responseText);
                    dispatch(updatedUserInfo());
                    alerts.success(ret.message, ret.path);
                } else {
                    alerts.warning(response.request.responseText);
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    }
}
function deleteTipoUploadById(id) {
    return dispatch => {

        Swal.fire({
            title: 'Confirmação',
            text: 'Tem certeza de que deseja excluir o registro?',
            icon: 'warning',
            buttons: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: "Não, cancelar!",
            dangerMode: true,
            heightAuto: false
        })
            .then((willDelete) => {
                if (willDelete.value) {

                    alerts.waiting();
                    let apiEndpoint = 'tiposUploads/' + id;
                    crudService.deleteDetail(apiEndpoint)
                        .then((response) => {
                            if (response.request.status === 200) {
                                var ret = JSON.parse(response.request.responseText);
                                dispatch(deleteTiposUploadsDetails());
                                dispatch(getTipoUpload(filterModel));
                                alerts.success(ret.message);
                            } else {
                                alerts.warning(response.request.responseText);
                            }
                        }).catch((err) => {
                            console.log(err);
                            alerts.error(err);
                        });

                }
            });

    };
}
export function changeTiposUploadsList(tipoUpload, totalPages) {
    return {
        type: "FETECHED_ALL_TIPO_UPLOAD",
        tipoUpload: tipoUpload,
        totalPages: totalPages
    };
}
export function clear() {
    return {
        type: "TIPO_UPLOAD_CLEAR"
    };
}
export function handleOnChangeProps(props, value) {
    return {
        type: "HANDLE_ON_CHANGE_TIPO_UPLOAD",
        props: props,
        value: value
    };
}
export function editTiposUploadsDetails(tipoUpload) {
    return {
        type: "TIPO_UPLOAD_DETAIL",
        id: tipoUpload.id,
        nome: tipoUpload.nome,
        excluido: tipoUpload.excluido
    }
}
export function updatedUserInfo() {
    return {
        type: "TIPO_UPLOAD_UPDATED"
    };
}
export function createUserInfo() {
    return {
        type: "TIPO_UPLOAD_CREATED_SUCCESSFULLY"
    };
}
export function deleteTiposUploadsDetails() {
    return {
        type: "DELETED_TIPO_UPLOAD_DETAILS"
    };
}
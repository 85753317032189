import { crudService } from '../_services';
import { history } from '../_helpers';
import alerts from '../_components/alerts';
import Swal from 'sweetalert2';
import { stringUtils } from '../_utils';

export const tiposParametrosAction = {
    getTipoParametro,
    getTipoParametroSelect,
    getTipoParametroById,
    onChangeProps,
    editTipoParametroInfo,
    createTipoParametro,
    deleteTipoParametroById,
    clear
};

let filterModel = {
    Page: 1,
    Limit: 10,
    Term: ''
};

function getTipoParametro(filterModel) {
    return dispatch => {
        alerts.waiting();
        let apiEndpoint = 'tiposParametros' + stringUtils.serialize(filterModel);
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    dispatch(changeTiposParametrosList(response.data.items, response.data.totalPages));
                }
                alerts.clean();
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function getTipoParametroSelect() {
    return dispatch => {
        let apiEndpoint = 'tiposParametros/select';
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    response.data.unshift({ value: '', text: ' SELECIONE ...' });
                    dispatch(changeTiposParametrosListSelect(response.data, null));
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function createTipoParametro(payload) {
    return dispatch => {
        let apiEndpoint = 'tiposParametros/';
        crudService.post(apiEndpoint, payload)
            .then((response) => {
                if (response.request.status === 200) {
                    var ret = JSON.parse(response.request.responseText);
                    dispatch(updatedUserInfo());
                    history.push(ret.path);
                    alerts.success(ret.message);
                } else {
                    alerts.warning(response.request.responseText);
                }
            });
    };
}
function getTipoParametroById(id) {
    return dispatch => {
        let apiEndpoint = 'tiposParametros/' + id;
        crudService.get(apiEndpoint)
            .then((response) => {
                dispatch(editTiposParametrosDetails(response.data));
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function onChangeProps(props, event) {
    return dispatch => {
        if (event.target.type === 'checkbox') {
            dispatch(handleOnChangeProps(props, event.target.checked));
        } else {
            dispatch(handleOnChangeProps(props, event.target.value));
        }
    };
}
function editTipoParametroInfo(id, payload) {
    return dispatch => {
        let apiEndpoint = 'tiposParametros/' + id;
        crudService.put(apiEndpoint, payload)
            .then((response) => {
                if (response.request.status === 200) {
                    var ret = JSON.parse(response.request.responseText);
                    dispatch(updatedUserInfo());
                    history.push(ret.path);
                    alerts.success(ret.message);
                } else {
                    alerts.warning(response.request.responseText);
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    }
}
function deleteTipoParametroById(id) {
    return dispatch => {

        Swal.fire({
            title: 'Confirmação',
            text: 'Tem certeza de que deseja excluir o registro?',
            icon: 'warning',
            buttons: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: "Não, cancelar!",
            dangerMode: true,
            heightAuto: false
        })
            .then((willDelete) => {
                if (willDelete.value) {

                    let apiEndpoint = 'tiposParametros/' + id;
                    crudService.deleteDetail(apiEndpoint)
                        .then((response) => {
                            if (response.request.status === 200) {
                                var ret = JSON.parse(response.request.responseText);
                                dispatch(deleteTiposParametrosDetails());
                                dispatch(getTipoParametro(filterModel));
                                alerts.success(ret.message);
                            } else {
                                alerts.warning(response.request.responseText);
                            }
                        }).catch((err) => {
                            console.log(err);
                            alerts.error(err);
                        });

                }
            });

    };
}
export function changeTiposParametrosList(tipoParametro, totalPages) {
    return {
        type: "FETECHED_ALL_TIPO_PARAMETRO",
        tipoParametro: tipoParametro,
        totalPages: totalPages
    };
}
export function changeTiposParametrosListSelect(tipoParametro) {
    return {
        type: "FETECHED_ALL_TIPO_PARAMETRO_SELECT",
        tipoParametro: tipoParametro
    };
}
export function clear() {
    return {
        type: "TIPO_PARAMETRO_CLEAR"
    };
}
export function handleOnChangeProps(props, value) {
    return {
        type: "HANDLE_ON_CHANGE_TIPO_PARAMETRO",
        props: props,
        value: value
    };
}
export function editTiposParametrosDetails(tipoParametro) {
    return {
        type: "TIPO_PARAMETRO_DETAIL",
        id: tipoParametro.id,
        nome: tipoParametro.nome,
        excluido: tipoParametro.excluido
    }
}
export function updatedUserInfo() {
    return {
        type: "TIPO_PARAMETRO_UPDATED"
    };
}
export function createUserInfo() {
    return {
        type: "TIPO_PARAMETRO_CREATED_SUCCESSFULLY"
    };
}
export function deleteTiposParametrosDetails() {
    return {
        type: "DELETED_TIPO_PARAMETRO_DETAILS"
    };
}
import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    footer: {
        flexDirection: 'row',
        fontSize: 11,
        fontWeight: 'bold',
        borderTop: 1,
        marginTop: 5
    }
});

const Footer = (props) => {

    return (
        <View style={styles.footer}>
            <Text>Total: {props.total} registro(s)</Text>
        </View>
    )

};

export { Footer };
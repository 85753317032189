const initialState = {
    tipoUpload: [],
    id: '',
    nome: '',
    excluido: ''
};

export function tipoUpload(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_TIPO_UPLOAD':
            return {
                ...state,
                tipoUpload: action.tipoUpload,
                totalPages: action.totalPages
            };
        case 'TIPO_UPLOAD_DETAIL':
            return {
                ...state,
                id: action.id,
                nome: action.nome,
                excluido: action.excluido
            };
        case 'TIPO_UPLOAD_CLEAR':
            return {
                ...state,
                tipoUpload: [],
                id: '',
                nome: '',
                excluido: ''
            };
        case "TIPO_UPLOAD_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_TIPO_UPLOAD":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state;
    }
}

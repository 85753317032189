import { crudService, authService } from '../_services';
import { history } from '../_helpers';
import alerts from '../_components/alerts';
import Swal from 'sweetalert2';
import { stringUtils } from '../_utils';
import { tiposUsuariosAction } from './tiposUsuarios.actions';

export const usuariosAction = {
    getUsuario,
    getUsuarioSelect,
    getUsuarioById,
    onChangeProps,
    editUsuarioInfo,
    createUsuario,
    deleteUsuarioById,
    clear,
    requestPasswordReset,
    passwordReset
};

let filterModel = {
    Page: 1,
    Limit: 10,
    Term: ''
};

function getUsuario(filterModel) {
    return dispatch => {
        alerts.waiting();
        let apiEndpoint = 'usuarios' + stringUtils.serialize(filterModel);
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    dispatch(changeUsuariosList(response.data.items, response.data.totalPages));
                    //console.log("entrei aquiiiiii")
                }
                alerts.clean();
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function getUsuarioSelect() {
    return dispatch => {
        let apiEndpoint = 'usuarios/select';
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    response.data.unshift({ value: '', text: ' SELECIONE ...' });
                    dispatch(changeUsuariosList(response.data, null));
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function createUsuario(payload) {
    return dispatch => {
        let apiEndpoint = 'usuarios/';
        alerts.waiting();
        crudService.post(apiEndpoint, payload)
            .then((response) => {
                if (response.request.status === 200) {
                    var ret = JSON.parse(response.request.responseText);
                    dispatch(updatedUserInfo());
                    //history.push();
                    alerts.success(ret.message, ret.path);
                } else {
                    alerts.warning(response.request.responseText);
                }
            });
    };
}
function getUsuarioById(id) {
    return dispatch => {
        let apiEndpoint = 'usuarios/' + id;
        crudService.get(apiEndpoint)
            .then((response) => {
                dispatch(editUsuariosDetails(response.data));

                //carregar objeto relacionado
                dispatch(tiposUsuariosAction.getTipoUsuarioById(response.data.idTipoUsuario));

            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function onChangeProps(props, event) {
    return dispatch => {
        if (event.target.type === 'checkbox') {
            dispatch(handleOnChangeProps(props, event.target.checked));
        } else {
            dispatch(handleOnChangeProps(props, event.target.value));

            //carregar objeto relacionado
            if (props === 'idTipoUsuario') {
                dispatch(tiposUsuariosAction.getTipoUsuarioById(event.target.value));
            }

        }
    };
}
function editUsuarioInfo(id, payload) {
    return dispatch => {
        let apiEndpoint = 'usuarios/' + id;
        crudService.put(apiEndpoint, payload)
            .then((response) => {
                if (response.request.status === 200) {
                    var ret = JSON.parse(response.request.responseText);
                    dispatch(updatedUserInfo());
                    history.push(ret.path);
                    alerts.success(ret.message);
                } else {
                    alerts.warning(response.request.responseText);
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function deleteUsuarioById(id) {
    return dispatch => {

        Swal.fire({
            title: 'Confirmação',
            text: 'Tem certeza de que deseja excluir o registro?',
            icon: 'warning',
            buttons: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: "Não, cancelar!",
            dangerMode: true,
            heightAuto: false
        })
            .then((willDelete) => {
                if (willDelete.value) {

                    let apiEndpoint = 'usuarios/' + id;
                    crudService.deleteDetail(apiEndpoint)
                        .then((response) => {
                            if (response.request.status === 200) {
                                var ret = JSON.parse(response.request.responseText);
                                dispatch(deleteUsuariosDetails());
                                dispatch(getUsuario(filterModel));
                                alerts.success(ret.message);
                            } else {
                                alerts.warning(response.request.responseText);
                            }
                        }).catch((err) => {
                            console.log(err);
                            alerts.error(err);
                        });

                }
            });

    };
}
export function changeUsuariosList(usuario, totalPages) {
    return {
        type: "FETECHED_ALL_USUARIO",
        usuario: usuario,
        totalPages: totalPages
    };
}
export function clear() {
    return {
        type: "USUARIO_CLEAR"
    };
}
function requestPasswordReset(payload) {
    return dispatch => {
        let apiEndpoint = 'usuarios/request-reset-password/';
        crudService.post(apiEndpoint, payload)
            .then((response) => {
                if (response && response.request.status === 200) {
                    dispatch(createUserInfo());
                    Swal.fire({
                        icon: 'success',
                        title: 'Sucesso',
                        text: 'E-mail de recuperação enviado!'
                    });
                    history.push('/');
                }
                else if (response && response.request.status === 404) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Atenção',
                        text: 'Não existe nehumum usuário com este e-mail!'
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Erro ao recuperar senha do usuário!'
                    });
                }
            }).catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Erro ao cadastrar usuário!'
                });
            })
    }
}
function passwordReset(payload, token) {
    return dispatch => {
        let apiEndpoint = 'usuarios/reset-password/';
        authService.postWithToken(apiEndpoint, payload, token)
            .then((response) => {
                if (response && response.request.status === 200) {
                    dispatch(createUserInfo());
                    Swal.fire({
                        icon: 'success',
                        title: 'Sucesso',
                        text: 'Senha redefinida com sucesso!'
                    })
                    history.push('/usuarios');
                }
                else if (response && response.request.status === 404) {
                    Swal.fire({
                        title: 'Atenção',
                        text: 'A validade desse link expirou. Deseja gerar um novo link de redefinição de senha?',
                        icon: 'warning',
                        buttons: true,
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: "Não",
                        dangerMode: true,
                        heightAuto: false
                    })
                    .then((willDelete) => {
                        if (willDelete.value) {
                            history.push("/request-password-reset");
                        }
                    });
                }else if (response && response.request.status === 404) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Atenção',
                        text: 'Não existe nehumum usuário com o e-mail informado!'
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Erro ao recuperar senha do usuário!'
                    })
                }
            }).catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Erro ao cadastrar usuário!'
                })
            })
    }
}
export function handleOnChangeProps(props, value) {
    return {
        type: "HANDLE_ON_CHANGE_USUARIO",
        props: props,
        value: value
    };
}
export function editUsuariosDetails(usuario) {
    return {
        type: "USUARIO_DETAIL",
        id: usuario.id,
        login: usuario.login,
        nome: usuario.nome,
        senha: '',
        idTipoUsuario: usuario.idTipoUsuario,
        email: usuario.email,
        excluido: usuario.excluido,
        tipoUsuario: usuario.tipoUsuario,
        usuariosEntidades: usuario.usuariosEntidades,
        telefone: usuario.telefone,
        cargo: usuario.cargo,
        informacao: usuario.informacao
    };
}
export function updatedUserInfo() {
    return {
        type: "USUARIO_UPDATED"
    };
}
export function createUserInfo() {
    return {
        type: "USUARIO_CREATED_SUCCESSFULLY"
    };
}
export function deleteUsuariosDetails() {
    return {
        type: "DELETED_USUARIO_DETAILS"
    };
}
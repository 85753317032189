import { crudService } from '../_services';
import { history } from '../_helpers';
import alerts from '../_components/alerts';
import Swal from 'sweetalert2';
import { stringUtils } from '../_utils';

export const permissoesUsuariosAction = {
    getPermissaoUsuario,
    getPermissaoUsuarioByChave,
    onChangePropsList,
    createPermissaoUsuarios,
    clear
};

function getPermissaoUsuario(id, showWaiting) {
    return dispatch => {
        if (showWaiting) {
            alerts.waiting();
        }
        let apiEndpoint = 'permissoesUsuarios/' + id;
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    dispatch(changePermissaoUsuariosList(response.data, 1));
                }
                if (showWaiting) {
                    alerts.clean();
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function getPermissaoUsuarioByChave(chave) {
    return dispatch => {
        let apiEndpoint = 'permissoesUsuarios/' + chave;
        crudService.get(apiEndpoint)
            .then((response) => {
                dispatch(editPermissaoUsuariosDetails(response.data));
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function createPermissaoUsuarios(payload) {
    return dispatch => {
        alerts.waiting();
        let apiEndpoint = 'permissoesUsuarios';
        crudService.post(apiEndpoint, payload)
            .then((response) => {
                if (response.request.status === 200) {
                    var ret = JSON.parse(response.request.responseText);
                    dispatch(updatedUserInfo());
                    alerts.success(ret.message, ret.path);
                } else {
                    alerts.warning(response.request.responseText);
                }
            });
    };
}
function onChangePropsList(props, event, position) {
    return dispatch => {
        dispatch(handleOnChangePropsList(props, event.target.checked, position));
    };
}
export function changePermissaoUsuariosList(permissaoUsuario, totalPages) {
    return {
        type: "FETECHED_ALL_PERMISSAO_USUARIO",
        permissaoUsuario: permissaoUsuario,
        totalPages: totalPages
    };
}
export function clear() {
    return {
        type: "PERMISSAO_USUARIO_CLEAR"
    };
}
export function handleOnChangePropsList(props, value, position) {
    return {
        type: "HANDLE_ON_CHANGE_LIST_PERMISSAO_USUARIO",
        props: props,
        value: value,
        position: position
    };
}
export function editPermissaoUsuariosDetails(permissaoUsuario) {
    return {
        type: "PERMISSAO_USUARIO_DETAIL",
        id: permissaoUsuario.id,
        idPermissao: permissaoUsuario.idPermissao,
        idUsuario: permissaoUsuario.idUsuario,
        permitido: permissaoUsuario.permitido
    };
}
export function updatedUserInfo() {
    return {
        type: "PERMISSAO_USUARIO_UPDATED"
    };
}
export function createUserInfo() {
    return {
        type: "PERMISSAO_USUARIO_CREATED_SUCCESSFULLY"
    };
}
export function deletePermissaoUsuariosDetails() {
    return {
        type: "DELETED_PERMISSAO_USUARIO_DETAILS"
    };
}
import { crudService, authService } from '../_services';
import { history } from '../_helpers';
import alerts from '../_components/alerts';
import Swal from 'sweetalert2';
import { stringUtils } from '../_utils';

export const analisesAction = {
    getAnalise,
    getAnaliseSelect,
    getAnaliseSelectDatas,
    getUltimaAnaliseByPontoPublic,
    getAnaliseById,
    onChangeProps,
    editAnaliseInfo,
    createAnalise,
    deleteAnaliseById,
    clear,
    importAnalise,
    changeAnalisesList
};

let filterModel = {
    Page: 1,
    Limit: 10,
    Term: ''
};

function importAnalise(payload) {
    return dispatch => {
        let apiEndpoint = 'analises/import/';
        alerts.waiting('Importando análises ...');
        crudService.post(apiEndpoint, payload)
            .then((response) => {
                if (response.request.status === 200) {
                    var ret = JSON.parse(response.request.responseText);
                    dispatch(updatedUserInfo());
                    alerts.success(ret.message, ret.path);
                } else {
                    alerts.error(response.request.responseText);
                }

            })
    }
}

function getAnalise(filterModel) {
    return dispatch => {
        let apiEndpoint = 'analises' + stringUtils.serialize(filterModel);
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    dispatch(changeAnalisesList(response.data.items, response.data.totalPages));
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function getAnaliseSelect() {
    return dispatch => {
        let apiEndpoint = 'analises/select';
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    response.data.unshift({ value: '', text: ' SELECIONE ...' });
                    dispatch(changeAnalisesList(response.data, null));
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}

function getAnaliseSelectDatas() {
    return dispatch => {
        let apiEndpoint = 'analises/selectDatas';
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    //console.log("DATAS ANALISES:", response.data)
                    response.data.unshift({ value: '', text: ' SELECIONE ...' });
                    dispatch(changeAnalisesList(response.data, null));
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function createAnalise(payload) {
    return dispatch => {
        alerts.waiting();
        let apiEndpoint = 'analises/';
        crudService.post(apiEndpoint, payload)
            .then((response) => {
                if (response.request.status === 200) {
                    var ret = JSON.parse(response.request.responseText);
                    dispatch(updatedUserInfo());
                    //history.push(ret.path);
                    alerts.success(ret.message, ret.path);
                } else {
                    alerts.warning(response.request.responseText);
                }
            });
    };
}
function getUltimaAnaliseByPontoPublic(id) {
    return dispatch => {
        let apiEndpoint = 'analises/public/' + id;
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.request.status === 200) {
                    dispatch(changeAnalisesList(response.data, null));
                } else {
                    dispatch(clear());
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function getAnaliseById(id) {
    return dispatch => {
        let apiEndpoint = 'analises/' + id;
        crudService.get(apiEndpoint)
            .then((response) => {
                dispatch(editAnalisesDetails(response.data));

            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function onChangeProps(props, event) {
    return dispatch => {
        if (event.target.type === 'checkbox') {
            dispatch(handleOnChangeProps(props, event.target.checked));
        } else {
            dispatch(handleOnChangeProps(props, event.target.value));

        }
    };
}
function editAnaliseInfo(id, payload) {
    return dispatch => {
        alerts.waiting();
        let apiEndpoint = 'analises/' + id;
        crudService.put(apiEndpoint, payload)
            .then((response) => {
                if (response.request.status === 200) {
                    var ret = JSON.parse(response.request.responseText);
                    dispatch(updatedUserInfo());
                    //history.push(ret.path);
                    alerts.success(ret.message, ret.path);
                } else {
                    alerts.warning(response.request.responseText);
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function deleteAnaliseById(id) {
    return dispatch => {

        Swal.fire({
            title: 'Confirmação',
            text: 'Tem certeza de que deseja excluir o registro?',
            icon: 'warning',
            buttons: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: "Não, cancelar!",
            dangerMode: true,
            heightAuto: false
        })
            .then((willDelete) => {
                if (willDelete.value) {
                    alerts.waiting();
                    let apiEndpoint = 'analises/' + id;
                    crudService.deleteDetail(apiEndpoint)
                        .then((response) => {
                            if (response.request.status === 200) {
                                var ret = JSON.parse(response.request.responseText);
                                dispatch(deleteAnalisesDetails());
                                dispatch(getAnalise(filterModel));
                                alerts.success(ret.message);
                            } else {
                                alerts.warning(response.request.responseText);
                            }
                        }).catch((err) => {
                            console.log(err);
                            alerts.error(err);
                        });

                }
            });

    };
}
export function changeAnalisesList(analise, totalPages) {
    return {
        type: "FETECHED_ALL_ANALISE",
        analise: analise,
        totalPages: totalPages
    };
}
export function clear() {
    return {
        type: "ANALISE_CLEAR"
    };
}
export function handleOnChangeProps(props, value) {
    return {
        type: "HANDLE_ON_CHANGE_ANALISE",
        props: props,
        value: value
    };
}
export function editAnalisesDetails(analise) {
    return {
        type: "ANALISE_DETAIL",
        id: analise.id,
        data: analise.data,
        idEntidade: analise.idEntidade,
        idPonto: analise.idPonto,
        iqa: analise.iqa,
        iqaCalculado: analise.iqaCalculado,
        excluido: analise.excluido,
        analisesParametros: analise.analisesParametros
    };
}
export function updatedUserInfo() {
    return {
        type: "ANALISE_UPDATED"
    };
}
export function createUserInfo() {
    return {
        type: "ANALISE_CREATED_SUCCESSFULLY"
    };
}
export function deleteAnalisesDetails() {
    return {
        type: "DELETED_ANALISE_DETAILS"
    };
}
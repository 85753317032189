const initialState = {
    tipoParametro: [],
    tipoParametroSelect: [],
    id: '',
    nome: '',
    excluido: ''
};

export function tipoParametro(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_TIPO_PARAMETRO':
            return {
                ...state,
                tipoParametro: action.tipoParametro,
                totalPages: action.totalPages
            };
        case 'FETECHED_ALL_TIPO_PARAMETRO_SELECT':
            return {
                ...state,
                tipoParametroSelect: action.tipoParametro
            };
        case 'TIPO_PARAMETRO_DETAIL':
            return {
                ...state,
                id: action.id,
                nome: action.nome,
                excluido: action.excluido
            };
        case 'TIPO_PARAMETRO_CLEAR':
            return {
                ...state,
                tipoParametro: [],
                id: '',
                nome: '',
                excluido: ''
            };
        case "TIPO_PARAMETRO_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_TIPO_PARAMETRO":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state;
    }
}

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const check = (rule, permissaoTipoUsuario, permissaoUsuario) => {

    if (permissaoTipoUsuario) {

        var allowedTipoUsuario = permissaoTipoUsuario.find(item => item.permissao.nome === rule);
        if (allowedTipoUsuario && allowedTipoUsuario.permitido) {

            if (permissaoUsuario) {

                const allowedUsuario = permissaoUsuario.find(item => item.permissao.nome === rule);
                if (allowedUsuario) {

                    if (allowedUsuario.permitido) {
                        return allowedUsuario;
                    } else {
                        return false;
                    }

                } else {
                    return true;
                }

            } else {
                return true;
            }

        } else {
            return false;
        } 
        
    } else {
        return false;
    }
};

const AccessControl = props =>
  check(props.rule, props.permissaoTipoUsuario, props.permissaoUsuario)
    ? props.yes()
    : props.no()

AccessControl.defaultProps = {
  yes: () => null,
  no: () => null
};

AccessControl.propTypes = {
  rule: PropTypes.string.isRequired
};

const mapStateToProps = (state) => { 
    return {
        permissaoTipoUsuario: (state.authentication.user ? (state.authentication.user.tipoUsuario ? state.authentication.user.tipoUsuario.permissoesTiposUsuarios : null) : null),
        permissaoUsuario: (state.authentication.user ? state.authentication.user.permissoesUsuarios : null)
    };
}
const connectedAcessControlPage = connect(mapStateToProps)(AccessControl);
export { connectedAcessControlPage as AccessControl };

import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { parametrosAguaAction, pontosMonitoramentoAction, graficoParametrosQuantitativosAction } from '../_actions';
import { withRouter } from 'react-router-dom';
import {
    Card, CardBody, CardHeader, Container, Row, Col, FormGroup, Label, Input
} from 'reactstrap';
import { NavBar } from '../_components/navbar';
import '././styles.css';
import Select from 'react-select';
import { Button } from '@material-ui/core';
import Chart from 'react-apexcharts';
import alerts from '../_components/alerts';

const GraficoParametrosQuantitativos = (props) => {
    const dispatch = useDispatch();

    const [agrupar, setAgrupar] = useState(false);

    useEffect(() => {
        const { match: { params } } = props;

        dispatch(pontosMonitoramentoAction.getPontosMonitoramentoFonteExternaSelect());
        dispatch(parametrosAguaAction.getParametrosAguaTipo2Select());
    }, []);

    const handleSelectChange = prop => event => {
        var event2 = {
            type: 'select',
            target: {
                value: event.value
            }
        };
        dispatch(graficoParametrosQuantitativosAction.onChangeProps(prop, event2));
    };

    const handleChange = prop => event => {
        dispatch(graficoParametrosQuantitativosAction.onChangeProps(prop, event));
    };

    const renderOptionsPontoFonteExterna = () => {
        return props.pontoMonitoramento.pontoMonitoramento.map((p, i) => {
            return (
                { value: p.value, label: p.text }
            );
        });
    };

    const renderOptionsParametro = () => {
        return props.parametroAgua.parametroAgua.map((p, i) => {
            return (
                { value: p.value, label: p.text }
            );
        });
    };

    const buscarDadosNiveisChuva = event => {

        if (props.graficoParametrosQuantitativos.idParametro != '' && props.graficoParametrosQuantitativos.idPontoMonitoramento != ''
            && props.graficoParametrosQuantitativos.dataInicial != null && props.graficoParametrosQuantitativos.dataFinal != null) {

            if (props.graficoParametrosQuantitativos.dataInicial < props.graficoParametrosQuantitativos.dataFinal) {

                var filterModel = {
                    idParametro: props.graficoParametrosQuantitativos.idParametro,
                    idPontoMonitoramento: props.graficoParametrosQuantitativos.idPontoMonitoramento,
                    dataInicial: props.graficoParametrosQuantitativos.dataInicial,
                    dataFinal: props.graficoParametrosQuantitativos.dataFinal,
                    agrupar: agrupar
                }

                dispatch(graficoParametrosQuantitativosAction.getGrafico(filterModel, true));

                console.log('props.graficoParametrosQuantitativos', props.graficoParametrosQuantitativos)
            } else {
                alerts.alert('Data final não pode ser menor que a data inicial!');
            }
        }
        else {
            alerts.alert('Preencha os campos!');
        }
    }

    return (
        <div>
            <NavBar />
            <Container>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col md={12}>
                                <Label className="center" for="data">Gráfico dos Parâmetros Quantitativos</Label>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col sm={12} md={5}>
                                <FormGroup>
                                    <Label className="left" for="idPontoMonitoramento">Ponto de Monitoramento *</Label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        value={renderOptionsPontoFonteExterna().filter(function (option) {
                                            return option.value == (props.graficoParametrosQuantitativos.idPontoMonitoramento);
                                        })}
                                        onChange={handleSelectChange('idPontoMonitoramento')}
                                        isSearchable
                                        id="idPontoMonitoramento"
                                        name="idPontoMonitoramento"
                                        options={renderOptionsPontoFonteExterna()}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={5}>
                                <FormGroup>
                                    <Label className="left" for="idParametro">Parâmetro *</Label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        value={renderOptionsParametro().filter(function (option) {
                                            return option.value == (props.graficoParametrosQuantitativos.idParametro);
                                        })}
                                        onChange={handleSelectChange('idParametro')}
                                        isSearchable
                                        id="idParametro"
                                        name="idParametro"
                                        options={renderOptionsParametro()}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={4}>
                                <FormGroup>
                                    <Label className="left" for="dataInicial">Data inicial *</Label>
                                    <Input
                                        id="dataInicial"
                                        label="dataInicial"
                                        autoFocus
                                        className="form-control"
                                        type="date"
                                        value={props.graficoParametrosQuantitativos.dataInicial}
                                        onChange={handleChange('dataInicial')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={4}>
                                <FormGroup>
                                    <Label className="left" for="dataFinal">Data final *</Label>
                                    <Input
                                        id="dataFinal"
                                        label="dataFinal"
                                        autoFocus
                                        className="form-control"
                                        type="date"
                                        value={props.graficoParametrosQuantitativos.dataFinal}
                                        onChange={handleChange('dataFinal')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={2} style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                <Label className="left" style={{ marginLeft: '20px' }} for="agrupar">Agrupar por mês</Label>
                                <FormGroup>
                                    <Input
                                        id="agrupar"
                                        label="agrupar"
                                        type="checkbox"
                                        checked={agrupar}
                                        onChange={() => setAgrupar(!agrupar)}
                                        style={{ justifyContent: 'center', alignItems: 'center' }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={2} style={{ alignSelf: 'end' }}>
                                <FormGroup>
                                    <Button onClick={() => buscarDadosNiveisChuva()} variant="contained" color="primary">Buscar</Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card className="mt-2">
                    <CardBody>
                        <Row>
                            <Col sm={12}>
                                <div style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                                    <div>
                                        <label>Dados referentes a data informada</label>
                                        <Chart
                                            options={props.graficoParametrosQuantitativos.graficoParametrosQuantitativosDataAtual.options}
                                            series={props.graficoParametrosQuantitativos.graficoParametrosQuantitativosDataAtual.series}
                                            type="bar"
                                            height="270"
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                {props.graficoParametrosQuantitativos.graficoParametrosQuantitativosDataUmAnoAtras &&
                    props.graficoParametrosQuantitativos.graficoParametrosQuantitativosDataUmAnoAtras.series &&
                    props.graficoParametrosQuantitativos.graficoParametrosQuantitativosDataUmAnoAtras.series[0] &&
                    props.graficoParametrosQuantitativos.graficoParametrosQuantitativosDataUmAnoAtras.series[0].data &&
                    props.graficoParametrosQuantitativos.graficoParametrosQuantitativosDataUmAnoAtras.series[0].data.length > 0 &&
                    <Card className="mt-3">
                        <CardBody>
                            <Row>
                                <Col sm={12}>
                                    <div style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                                        <div>
                                            <label>Dados referentes a um ano atrás a partir da data informada</label>
                                            <Chart
                                                options={props.graficoParametrosQuantitativos.graficoParametrosQuantitativosDataUmAnoAtras.options}
                                                series={props.graficoParametrosQuantitativos.graficoParametrosQuantitativosDataUmAnoAtras.series}
                                                type="bar"
                                                height="270"
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                }
                {props.graficoParametrosQuantitativos.graficoParametrosQuantitativosDataDoisAnosAtras &&
                    props.graficoParametrosQuantitativos.graficoParametrosQuantitativosDataDoisAnosAtras.series &&
                    props.graficoParametrosQuantitativos.graficoParametrosQuantitativosDataDoisAnosAtras.series[0] &&
                    props.graficoParametrosQuantitativos.graficoParametrosQuantitativosDataDoisAnosAtras.series[0].data &&
                    props.graficoParametrosQuantitativos.graficoParametrosQuantitativosDataDoisAnosAtras.series[0].data.length > 0 &&
                    <Card className="mt-3">
                        <CardBody>
                            <Row>
                                <Col sm={12}>
                                    <div style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                                        <div>
                                            <label>Dados referentes a dois anos atrás a partir da data informada</label>
                                            <Chart
                                                options={props.graficoParametrosQuantitativos.graficoParametrosQuantitativosDataDoisAnosAtras.options}
                                                series={props.graficoParametrosQuantitativos.graficoParametrosQuantitativosDataDoisAnosAtras.series}
                                                type="bar"
                                                height="270"
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                }
            </Container>
        </div>
    );

};

const mapStateToProps = (state) => {
    return state;
};

const connectedGraficoParametrosQuantitativosPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(GraficoParametrosQuantitativos));
export { connectedGraficoParametrosQuantitativosPage as GraficoParametrosQuantitativos };
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import {
    Card, CardBody, CardHeader, Button, Container, Row, Col
} from 'reactstrap';
import { NavBar } from '../_components/navbar';
import { history } from '../_helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import alerts from '../_components/alerts';
import '././styles.css';
import { ModalLog } from '../log/';

const Erro403 = (props) => {  

    return (
        <div>
            <NavBar />
            <Container style={{ height: "100vh", verticalAlign: "middle", minHeight: 500 }}>
                <Row>
                    <Col className="center" md="12" lg="12">
                        <span style={{ fontSize: 60, fontWeight: "bold" }}>403</span>
                    </Col>
                    <Col className="center" md="12" lg="12">
                        <span style={{ fontSize: 20 }}>Ops... parece que você não tem permissão para acessar essa tela!</span>
                    </Col>
                    <Col className="center" md="12" lg="12" style={{ marginTop: 10 }}>
                        <Button className="mr-1" variant="contained" color="secondary" component='a' onClick={() => history.goBack()} href="">Voltar <FontAwesomeIcon icon={faUndo} /></Button>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return state;
};

const connectedErro403Page = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(Erro403));

export { connectedErro403Page as Erro403 };
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { camadasAction, tiposUploadsAction, logAction } from '../_actions';
import { withRouter, Link } from 'react-router-dom';
import { history } from '../_helpers';
import { stringUtils } from '../_utils';
import {
    Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, Button, UncontrolledTooltip, Container, Row, Col, FormGroup, Label, Input, Form, InputGroup, InputGroupAddon, InputGroupText
} from 'reactstrap';
import InputMask from "react-input-mask";
import { NavBar } from '../_components/navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faUndo, faCheck } from '@fortawesome/free-solid-svg-icons';
import './styles.css';
import { ModalLog } from '../log';
import { ModalUploads } from '../uploads';

const CamadasEdit = (props) => {

    const dispatch = useDispatch();

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [modalUpload, setModalUpload] = useState(false);
    const toggleUpload = () => setModalUpload(!modalUpload);

    useEffect(() => {
        const { match: { params } } = props;

        //SELECTS
        dispatch(tiposUploadsAction.getTipoUploadSelect());

        if (params.id) {
            dispatch(camadasAction.getCamadaById(params.id));
        } else {
            dispatch(camadasAction.clear());
        }

    }, []);

    const handleChange = prop => event => {
        dispatch(camadasAction.onChangeProps(prop, event));
    };

    const handleOpenModalUpload = event => {
        toggleUpload();
    };

    const handleSubmit = event => {

        event.preventDefault();
        const { match: { params } } = props;

        if (props.camada.nome === null || props.camada.nome === '') {
            alert('Informe um Nome válido!');
        } else {

            let payload = {
                id: params.id ? params.id : 0,
                nome: props.camada.nome
            };

            if (params.id) {
                dispatch(camadasAction.editCamadaInfo(params.id, payload));
            } else {
                dispatch(camadasAction.createCamada(payload));
            }

        }

    };

    const handleClickHistory = event => {

        event.preventDefault();
        const { match: { params } } = props;

        if (params.id) {
            dispatch(logAction.getLog('Camada', params.id));
            toggle();
        }

    };

    const { log } = props.log;

    return (
        <div>
            <NavBar />
            <Container>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col md={12}>
                                <Breadcrumb>
                                    <BreadcrumbItem><Link to="/camadas" href="">Lista de Camadas</Link></BreadcrumbItem>
                                    <BreadcrumbItem active>{(props.camada.id > 0 ? props.camada.nome : 'Nova')}</BreadcrumbItem>
                                    {props.camada.id > 0 ?
                                        <BreadcrumbItem className="pb-0 pt-0 m-0 btn btn-muted">
                                            <a href="#" id="tagHistorico" onClick={(event) => handleClickHistory(event)}>Histórico</a>
                                            <UncontrolledTooltip placement="bottom" target="tagHistorico">
                                                Exibe o histórico de alterações do registro
                                            </UncontrolledTooltip>
                                        </BreadcrumbItem>
                                    : null}
                                </Breadcrumb>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Form onSubmit={(event) => handleSubmit(event)}>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label className="left" for="nome">Nome *</Label>
                                        <Input
                                            id="nome"
                                            label="Nome"
                                            maxLength="60"
                                            required="true"
                                            className="form-control text-uppercase"
                                            value={props.camada.nome}
                                            onChange={handleChange('nome')}
                                            onInput={stringUtils.toInputUppercase}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col className="left" md="8" lg="6">
                                    <span>* Campos obrigatórios</span>
                                </Col>
                                <Col className="d-flex flex-row-reverse" md="4" lg="6">
                                    <Button variant="contained" color="primary" type="submit">Salvar <FontAwesomeIcon icon={faCheck} /></Button>
                                    <Button id="btnArquivos" className="mr-1" variant="contained" color="success" disabled={props.camada.id == null || props.camada.id == 0} onClick={(event) => handleOpenModalUpload(event)}>Arquivos <FontAwesomeIcon icon={faFile} /></Button>
                                    <Button className="mr-1" variant="contained" color="secondary" component='a' onClick={() => history.push("/camadas/")} href="">Voltar <FontAwesomeIcon icon={faUndo} /></Button>
                                 </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
            </Container>
            <ModalLog toggle={toggle} modal={modal} log={log} />
            {(props.camada != null) ?
                <ModalUploads toggle={toggleUpload} modal={modalUpload} uploads={props.camada.uploads} tipo={'camada'} id={props.camada.id} props={props} />
                :
                null}
        </div>
    );

};

const mapStateToProps = (state) => {
    return state;
};

const connectedCamadasEditPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(CamadasEdit));
export { connectedCamadasEditPage as CamadasEdit };
const initialState = {
    usuario: [],
    id: 0,
    login: '',
    nome: '',
    senha: '',
    idTipoUsuario: '',
    email: '',
    excluido: '',
    tipoUsuario: null,
    telefone: '',
    cargo: '',
    informacao: '',
    usuariosEntidades: []
};

export function usuario(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_USUARIO':
            return {
                ...state,
                usuario: action.usuario,
                totalPages: action.totalPages
            };
        case 'USUARIO_DETAIL':
            return {
                ...state,
                id: action.id,
                login: action.login,
                nome: action.nome,
                senha: action.senha,
                idTipoUsuario: action.idTipoUsuario,
                excluido: action.excluido,
                tipoUsuario: action.tipoUsuario,
                email: action.email,
                usuariosEntidades: action.usuariosEntidades,
                telefone: action.telefone,
                cargo: action.cargo,
                informacao: action.informacao,
            };
        case 'USUARIO_CLEAR':
            return {
                ...state,
                usuario: [],
                id: 0,
                login: '',
                nome: '',
                senha: '',
                idTipoUsuario: '',
                email: '',
                excluido: '',
                tipoUsuario: null,
                telefone: '',
                cargo: '',
                informacao: '',
                usuariosEntidades: []
            };
        case "USUARIO_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_USUARIO":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state;
    }
}

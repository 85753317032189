import { entidadesAction } from "../_actions";

const initialState = {
    page: 1,
    limit: 10,
    order: 'nome',
    direction: 'asc',
    entidade: [],
    id: '',
    nome: '',
    responsavel: '',
    telefone: '',
    celular: '',
    email: '',
    excluido: ''
};

export function entidade(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_ENTIDADE':
            return {
                ...state,
                entidade: action.entidade,
                totalPages: action.totalPages
            };
        case 'ENTIDADE_DETAIL':
            return {
                ...state,
                id: action.id,
                nome: action.nome,
                responsavel: action.responsavel,
                telefone: action.telefone,
                celular: action.celular,
                email: action.email,
                excluido: action.excluido
            };
        case 'ENTIDADE_CLEAR':
            return {
                ...state,
                entidade: [],
                id: '',
                nome: '',
                responsavel: '',
                telefone: '',
                celular: '',
                email: '',
                excluido: ''
            };
        case "ENTIDADE_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_ENTIDADE":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state;
    }
}

const initialState = {
    permissaoTipoUsuario: [],
    id: '',
    idPermissao: '',
    idTipoUsuario: '',
    permitido: ''
};

export function permissaoTipoUsuario(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_PERMISSAO_TIPO_USUARIO':
            return {
                ...state,
                permissaoTipoUsuario: action.permissaoTipoUsuario,
                totalPages: action.totalPages
            };
        case 'PERMISSAO_TIPO_USUARIO_DETAIL':
            return {
                ...state,
                id: action.id,
                idPermissao: action.idPermissao,
                idTipoUsuario: action.idTipoUsuario,
                permitido: action.permitido
            };
        case 'PERMISSAO_TIPO_USUARIO_CLEAR':
            return {
                ...state,
                permissaoTipoUsuario: [],
                id: '',
                idPermissao: '',
                idTipoUsuario: '',
                permitido: ''
            };
        case "PERMISSAO_TIPO_USUARIO_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_PERMISSAO_TIPO_USUARIO":
            return {
                ...state,
                [action.props]: action.value
            };
        case "HANDLE_ON_CHANGE_LIST_PERMISSAO_TIPO_USUARIO":
            return {
                ...state,
                [permissaoTipoUsuario]: [...state.permissaoTipoUsuario][action.position].permitido = action.value
            };
        default:
            return state;
    }
}

import { crudService } from '../_services';
import { history } from '../_helpers';
import alerts from '../_components/alerts';

export const permissoesTiposUsuariosAction = {
    getPermissaoTipoUsuario,
    getPermissaoTipoUsuarioByChave,
    onChangePropsList,
    createPermissaoTipoUsuarios,
    clear
};

function getPermissaoTipoUsuario(id, showWaiting) {
    return dispatch => {
        if (showWaiting) {
            alerts.waiting();
        }
        let apiEndpoint = 'permissoesTiposUsuarios/' + id;
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    dispatch(changePermissaoTipoUsuariosList(response.data, 1));
                }
                if (showWaiting) {
                    alerts.clean();
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function getPermissaoTipoUsuarioByChave(chave) {
    return dispatch => {
        let apiEndpoint = 'permissoesTiposUsuarios/' + chave;
        crudService.get(apiEndpoint)
            .then((response) => {
                dispatch(editPermissaoTipoUsuariosDetails(response.data));
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function createPermissaoTipoUsuarios(payload) {
    return dispatch => {
        alerts.waiting();
        let apiEndpoint = 'permissoesTiposUsuarios';
        crudService.post(apiEndpoint, payload)
            .then((response) => {
                if (response.request.status === 200) {
                    var ret = JSON.parse(response.request.responseText);
                    dispatch(updatedUserInfo());
                    alerts.success(ret.message, ret.path);
                } else {
                    alerts.warning(response.request.responseText);
                }
            }).catch((err) => {
                return err;
            });
    };
}
function onChangePropsList(props, event, position) {
    return dispatch => {
      //  console.log('event', event);
        dispatch(handleOnChangePropsList(props, event.target.checked, position));
    };
}
export function changePermissaoTipoUsuariosList(permissaoTipoUsuario, totalPages) {
    return {
        type: "FETECHED_ALL_PERMISSAO_TIPO_USUARIO",
        permissaoTipoUsuario: permissaoTipoUsuario,
        totalPages: totalPages
    };
}
export function clear() {
    return {
        type: "PERMISSAO_TIPO_USUARIO_CLEAR"
    };
}
export function handleOnChangePropsList(props, value, position) {
    return {
        type: "HANDLE_ON_CHANGE_LIST_PERMISSAO_TIPO_USUARIO",
        props: props,
        value: value,
        position: position
    };
}
export function editPermissaoTipoUsuariosDetails(permissaoTipoUsuario) {
    return {
        type: "PERMISSAO_TIPO_USUARIO_DETAIL",
        id: permissaoTipoUsuario.id,
        idPermissao: permissaoTipoUsuario.idPermissao,
        idTipoUsuario: permissaoTipoUsuario.idTipoUsuario,
        permitido: permissaoTipoUsuario.permitido
    };
}
export function updatedUserInfo() {
    return {
        type: "PERMISSAO_TIPO_USUARIO_UPDATED"
    };
}
export function createUserInfo() {
    return {
        type: "PERMISSAO_TIPO_USUARIO_CREATED_SUCCESSFULLY"
    };
}
export function deletePermissaoTipoUsuariosDetails() {
    return {
        type: "DELETED_PERMISSAO_TIPO_USUARIO_DETAILS"
    };
}
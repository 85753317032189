import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { analisesAction, entidadesAction } from '../_actions';
import { withRouter } from 'react-router-dom';
import { history } from '../_helpers';
import { Table, Button, Container, Row, Col, Card, CardBody, CardHeader, FormGroup, Modal, ModalBody, ModalHeader, ModalFooter, UncontrolledTooltip, Pagination, PaginationItem, PaginationLink, Input, Label } from 'reactstrap';
import { NavBar } from '../_components/navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faPen, faTrash, faFileExcel, faStar, faSign, faSignal } from '@fortawesome/free-solid-svg-icons';
import { stringUtils, tableUtils } from '../_utils';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import { useDebounce } from 'use-debounce';

const Analises = (props) => {

    const [editId, setEditId] = useState(-1);
    const [middlePage, setMiddlePage] = useState(3);
    const [page, setPage] = useState(props.analise.page);
    const [limit, setLimit] = useState(props.analise.limit);
    const [order, setOrder] = useState(props.analise.order);
    const [direction, setDirection] = useState(props.analise.direction);
    const [term, setTerm] = useState('');
    const [debounceTerm] = useDebounce(term, 1000);
    const [open, setOpen] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(entidadesAction.clear());
        dispatch(entidadesAction.getEntidadeSelect());

        const fetchData = (page, limit, term) => {

            let filterModel = {
                Page: page,
                Limit: limit,
                Term: term,
                Order: order,
                Direction: direction
            };
            dispatch(analisesAction.getAnalise(filterModel));

        };

        //fetchData(page, limit, term);
        props.analise.page = page;
        props.analise.limit = limit;
        props.analise.order = order;
        props.analise.direction = direction;

        fetchData(page, limit, debounceTerm, order, direction);
        setMiddlePage((Math.floor((page - 1) / 5) * 5) + 3);

        //}, [page, limit, term]);
    }, [page, limit, debounceTerm, order, direction]);

    const handleClick = (event, id) => {
        dispatch(analisesAction.deleteAnaliseById(id));
    };

    const handleChange = prop => event => {
        setTerm(event.target.value);
    };

    const handleOrder = (value) => {
        if (value === order) {
            if (direction === 'asc') {
                setDirection('desc');
            } else {
                setDirection('asc');
            }
        }
        setOrder(value);
    };

    const renderDirection = (value) => {
        if (value === order) {
            if (direction === 'asc') {
                return "↓";
            } else {
                return "↑";
            }
        } else {
            return "";
        }
    };

    const handleChangeSelectedLimit = (value) => {
        setLimit(value);
    };

    const { analise, totalPages } = props.analise;


    const setFile = (e) => {
        props.analise.arquivo = e.target.files[0];
    }

    const handleImport = event => {


        if (props.analise.idEntidade === null || props.analise.idEntidade === '') {
            alert('Informe uma entidade!')
        }
        if (props.analise.arquivo === null || props.analise.arquivo === '' || props.analise.arquivo === undefined) {
            alert('Informe um Arquivo válido!');
        } else {

            var formData = new FormData();
            //formData.append('login', this.props.analise.login);
            //formData.append('senha', this.props.analise.senha);
            formData.append('file', props.analise.arquivo);
            formData.append('idEntidade', props.analise.idEntidade)

            dispatch(analisesAction.importAnalise(formData));

        }

    };

    const handleClickOpen = event => {
        setOpen(true);
    };

    const handleClose = event => {
        setOpen(false);
    };

    const renderOptionsEntidade = () => {
        return props.entidade.entidade.map((p, i) => {
            return (
                { value: p.value, label: p.text }
            );
        });
    };


    const handleSelectChange = prop => event => {

        var event2 = {
            type: 'select',
            target: {
                value: event.value
            }
        };

        dispatch(analisesAction.onChangeProps(prop, event2));

    };

    return (
        <div>
            <NavBar />
            <Container>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col md={8}>
                                <h5 className="left">Lista de Análises de Água</h5>
                            </Col>
                            <Col md={4} className="d-flex flex-row-reverse">
                                <Button id="btnExcel" color="success" className="btn-sm" component='a' onClick={() => handleClickOpen()} href="">Importar <FontAwesomeIcon icon={faFileExcel} /></Button>
                                &nbsp;<Button id="btnNovo" color="primary" className="btn-sm" component='a' onClick={() => history.push("/analise")} href="">Nova <FontAwesomeIcon icon={faPlusCircle} /></Button>
                                <UncontrolledTooltip placement="bottom" target="btnNovo">
                                    Adiciona uma nova análise
                                </UncontrolledTooltip>
                                <UncontrolledTooltip placement="bottom" target="btnExcel">
                                    Importar análises de um arquivo Excel
                                </UncontrolledTooltip>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={4} >
                                <Input
                                    id="term"
                                    label="term"
                                    placeholder="Filtro"
                                    value={term}
                                    onChange={handleChange('term')}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Table hover striped bordered responsive size="sm">
                            <thead>
                                <tr>
                                    <th><span style={{ cursor: "pointer" }} onClick={() => handleOrder('data')}>Data {renderDirection('data')}</span></th>
                                    <th><span style={{ cursor: "pointer" }} onClick={() => handleOrder('entidade')}>Entidade {renderDirection('entidade')}</span></th>
                                    <th><span style={{ cursor: "pointer" }} onClick={() => handleOrder('ponto')}>Ponto {renderDirection('ponto')}</span></th>
                                    <th><span style={{ cursor: "pointer" }} onClick={() => handleOrder('codigoPonto')}>Código {renderDirection('codigoPonto')}</span></th>
                                    <th><span style={{ cursor: "pointer" }} onClick={() => handleOrder('iqa')}>IQA {renderDirection('iqa')}</span></th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    analise && analise.length ? analise.map(n => {
                                        return (
                                            <tr key={n.id}>
                                                <td style={{ textAlign: "left", verticalAlign: "middle" }}>{n.data ? stringUtils.formataDataHora(n.data) : ''}</td>
                                                <td style={{ textAlign: "left", verticalAlign: "middle" }}>{n.entidade ? n.entidade.nome : ''}</td>
                                                <td style={{ textAlign: "left", verticalAlign: "middle" }}>{(n.pontoMonitoramento ? n.pontoMonitoramento.nome : '')}</td>
                                                <td style={{ textAlign: "left", verticalAlign: "middle" }}>{(n.pontoMonitoramento ? n.pontoMonitoramento.codigo : '')}</td>
                                                <td style={{ textAlign: "right", verticalAlign: "middle" }}>
                                                    {(n.iqa > 0 ? <><NumberFormat value={n.iqa} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} renderText={value => <span>{value}</span>} />
                                                        <FontAwesomeIcon className="ml-2" style={{ color: stringUtils.retornaCorIqa(n.iqa) }} icon={faStar} /></> : '')}
                                                </td>
                                                <td style={{ width: "80px" }}>
                                                    <Button
                                                        color="primary" size="sm"
                                                        onClick={() => { setEditId(n.id); history.push(`/analise/${n.id}`) }}>
                                                        {editId === n.id ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true">&nbsp;</span> : <FontAwesomeIcon icon={faPen} />}
                                                    </Button>
                                                    <span>{'\u00A0'}</span>
                                                    <Button color="danger" size="sm"
                                                        onClick={(event) => handleClick(event, n.id)}>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                </td>
                                            </tr>
                                        );
                                    })
                                        : null
                                }
                            </tbody>
                        </Table>
                        <Row>
                            <Col md={2} >
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    value={tableUtils.renderOptionsLimit().filter(function (option) {
                                        return option.value == (limit || '');
                                    })}
                                    onChange={event => handleChangeSelectedLimit(event.value)}
                                    id="limit"
                                    name="limit"
                                    options={tableUtils.renderOptionsLimit()}
                                />
                            </Col>
                            <Col md={6} />
                            <Col md={4} className="d-flex flex-row-reverse" >
                                <Pagination aria-label="Page navigation example">
                                    <PaginationItem disabled={page === 1}>
                                        <PaginationLink first onClick={() => setPage(1)} />
                                    </PaginationItem>
                                    <PaginationItem disabled={page === 1}>
                                        <PaginationLink previous onClick={() => setPage(page - 1)} />
                                    </PaginationItem>
                                    <PaginationItem active={page === (middlePage - 2)} disabled={(middlePage - 2) > totalPages}>
                                        <PaginationLink onClick={() => setPage(middlePage - 2)} >
                                            {middlePage - 2}
                                        </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem active={page === (middlePage - 1)} disabled={(middlePage - 1) > totalPages}>
                                        <PaginationLink onClick={() => setPage(middlePage - 1)} >
                                            {middlePage - 1}
                                        </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem active={page === middlePage} disabled={middlePage > totalPages}>
                                        <PaginationLink onClick={() => setPage(middlePage)} >
                                            {middlePage}
                                        </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem active={page === (middlePage + 1)} disabled={(middlePage + 1) > totalPages}>
                                        <PaginationLink onClick={() => setPage(middlePage + 1)} >
                                            {middlePage + 1}
                                        </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem active={page === (middlePage + 2)} disabled={(middlePage + 2) > totalPages}>
                                        <PaginationLink onClick={() => setPage(middlePage + 2)} >
                                            {middlePage + 2}
                                        </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem disabled={page === totalPages}>
                                        <PaginationLink next onClick={() => setPage(page + 1)} />
                                    </PaginationItem>
                                    <PaginationItem disabled={page === totalPages}>
                                        <PaginationLink last onClick={() => setPage(totalPages)} />
                                    </PaginationItem>
                                </Pagination>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <Modal isOpen={open} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
                    <ModalHeader id="form-dialog-title">Importação de Análises via Arquivo Excel</ModalHeader>
                    <ModalBody>
                        <p>
                            <span>Arquivo em formato Excel (.xls, .xlsx, ou .csv) contendo as informações das análises.</span>
                            <span>Arquivo de modelo: <a href="./modelo_importacao.xlsx" target="_blank" download>Modelo de Importação</a></span>
                        </p>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label className="left" for="idEntidade">Entidade *</Label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        value={renderOptionsEntidade().filter(function (option) {
                                            return option.value == (props.analise.idEntidade || '');
                                        })}
                                        onChange={handleSelectChange('idEntidade')}
                                        isSearchable
                                        id="idEntidade"
                                        name="idEntidade"
                                        options={renderOptionsEntidade()}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label className="left" for="nome">Excel</Label>
                                    <Input
                                        id="arquivo"
                                        label="Arquivo"
                                        onChange={e => setFile(e)}
                                        type="file"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => handleClose()} color="secondary">Cancelar</Button>
                        <Button onClick={(event) => handleImport(event)} color="primary">Importar</Button>
                    </ModalFooter>
                </Modal>

            </Container>
        </div>
    );

};

const mapStateToProps = (state) => {
    return state;
};

const connectedAnalisesPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(Analises));
export { connectedAnalisesPage as Analises };
import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from "react-redux";
import { parametrosAguaAction, tiposParametrosAction, unidadesMedidasAction, logAction, parametrosAguaFaixaAction } from '../_actions';
import { withRouter, Link } from 'react-router-dom';
import { history } from '../_helpers';
import { stringUtils } from '../_utils';
import {
    Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, Button, Container, Row, Col, FormGroup, Label, Input, Form,
    TabContent, TabPane, Nav, NavItem, NavLink, UncontrolledTooltip, Table, CardFooter
} from 'reactstrap';
import InputMask from "react-input-mask";
import { NavBar } from '../_components/navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faCheck } from '@fortawesome/free-solid-svg-icons';
import '././styles.css';
import classnames from 'classnames';
import { ModalLog } from '../log/';
import { ParametrosAguaFaixaTab } from '../parametrosAgua/';
import NumberFormat from 'react-number-format';
import Select from 'react-select';

const ParametrosAguaEdit = (props) => {

    const dispatch = useDispatch();

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const parametroAguaRef = useRef(null);

    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(() => {
        const { match: { params } } = props;

        dispatch(tiposParametrosAction.getTipoParametroSelect());
        dispatch(unidadesMedidasAction.getUnidadeMedidaSelect());

        if (params.id) {
            dispatch(parametrosAguaAction.getParametrosAguaById(params.id));
            dispatch(parametrosAguaFaixaAction.clear());
        } else {
            dispatch(parametrosAguaAction.clear());
        }

    }, []);

    const handleChange = prop => event => {
        dispatch(parametrosAguaAction.onChangeProps(prop, event));
    };

    const handleSubmit = event => {

        event.preventDefault();
        const { match: { params } } = props;

        if (props.parametroAgua.nome === null || props.parametroAgua.nome === '') {
            alert('Informe um Nome válido!');
        } else {

            let payload = {
                id: params.id ? params.id : 0,
                nome: props.parametroAgua.nome,
                limiteMinimo: (props.parametroAgua.limiteMinimo != '' && props.parametroAgua.limiteMinimo != null ? props.parametroAgua.limiteMinimo.replaceAll(".", "").replaceAll(",", ".") : ''),
                limiteMaximo: (props.parametroAgua.limiteMaximo != '' && props.parametroAgua.limiteMaximo != null ? props.parametroAgua.limiteMaximo.replaceAll(".", "").replaceAll(",", ".") : ''),
                idTipo: props.parametroAgua.idTipo,
                idUnidadeMedida: props.parametroAgua.idUnidadeMedida,
                tipoValidacao: props.parametroAgua.tipoValidacao,
            };

            //console.log(payload);
            if (params.id) {
                dispatch(parametrosAguaAction.editParametrosAguaInfo(params.id, payload));
            } else {
                dispatch(parametrosAguaAction.createParametrosAgua(payload));
            }

        }

    };

    const handleClickHistory = event => {

        event.preventDefault();
        const { match: { params } } = props;

        if (params.id) {
            dispatch(logAction.getLog('ParametroAgua', params.id));
            toggle();
        }

    };

    const handleSelectChange = prop => event => {

        var event2 = {
            type: 'select',
            target: {
                value: event.value
            }
        };

        dispatch(parametrosAguaAction.onChangeProps(prop, event2));

    };

    const renderOptionsTipo = () => {
        return props.tipoParametro.tipoParametroSelect.map((p, i) => {
            return (
                { value: p.value, label: p.text }
            );
        });
    };

    const renderOptionsUnidadeMedida = () => {
        return props.unidadeMedida.unidadeMedida.map((p, i) => {
            return (
                { value: p.value, label: p.text }
            );
        });
    };

    const renderOptionsTipoValidacao = () => {
        var tipoData = [
            { value: '', text: 'SELECIONE ...' },
            { value: 'N', text: 'NUMÉRICO' },
            { value: 'T', text: 'TEXTO' }
        ];

        return tipoData.map((p, i) => {
            return (
                { value: p.value, label: p.text }
            );
        });
    };

    const { log } = props.log;

    return (
        <div>
            <NavBar />
            <Container>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col md={12}>
                                <Breadcrumb>
                                    <BreadcrumbItem><Link to="/parametrosAgua" href="">Lista de Parâmetros de Água</Link></BreadcrumbItem>
                                    <BreadcrumbItem active>{(props.parametroAgua.id > 0 ? props.parametroAgua.nome : 'Novo')}</BreadcrumbItem>
                                    {props.parametroAgua.id > 0 ?
                                        <BreadcrumbItem className="pb-0 pt-0 m-0 btn btn-muted">
                                            <a href="#" id="tagHistorico" onClick={(event) => handleClickHistory(event)}>Histórico</a>
                                            <UncontrolledTooltip placement="bottom" target="tagHistorico">
                                                Exibe o histórico de alterações do registro
                                            </UncontrolledTooltip>
                                        </BreadcrumbItem>
                                        : null}
                                </Breadcrumb>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Form>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === '1' })}
                                        onClick={() => { toggleTab('1'); }}>Dados Gerais
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === '2' })}
                                        onClick={() => { toggleTab('2'); }}>Faixas de Alerta
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <br />
                                <TabPane tabId="1">
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="left" for="nome">Nome *</Label>
                                                <Input
                                                    id="nome"
                                                    label="Nome"
                                                    maxLength="60"
                                                    required={true}
                                                    className="form-control text-uppercase"
                                                    value={props.parametroAgua.nome}
                                                    onChange={handleChange('nome')}
                                                    onInput={stringUtils.toInputUppercase}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label className="left" for="limiteMinimo">Limite Mínimo</Label>
                                                <NumberFormat
                                                    id="limiteMinimo"
                                                    label="Limite Mínimo"
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    className="form-control"
                                                    value={(props.parametroAgua.tipoValidacao == 'N' ? props.parametroAgua.limiteMinimo || '0' : '')}
                                                    onChange={handleChange('limiteMinimo')}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label className="left" for="limiteMaximo">Limite Máximo</Label>
                                                <NumberFormat
                                                    id="limiteMaximo"
                                                    label="Limite Máximo"
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    className="form-control"
                                                    value={(props.parametroAgua.tipoValidacao == 'N' ? props.parametroAgua.limiteMaximo || '' : '')}
                                                    onChange={handleChange('limiteMaximo')}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label className="left" for="idUnidadeMedida">Unidade de Medida</Label>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    value={renderOptionsUnidadeMedida().filter(function (option) {
                                                        return option.value == (props.parametroAgua.idUnidadeMedida || '');
                                                    })}
                                                    onChange={handleSelectChange('idUnidadeMedida')}
                                                    isSearchable
                                                    id="idUnidadeMedida"
                                                    name="idUnidadeMedida"
                                                    options={renderOptionsUnidadeMedida()}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label className="left" for="idTipo">Tipo *</Label>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    value={renderOptionsTipo().filter(function (option) {
                                                        return option.value == (props.parametroAgua.idTipo || '');
                                                    })}
                                                    onChange={handleSelectChange('idTipo')}
                                                    isSearchable
                                                    id="idTipo"
                                                    name="idTipo"
                                                    options={renderOptionsTipo()}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label className="left" for="tipoValidacao">Tipo de Validação *</Label>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    value={renderOptionsTipoValidacao().filter(function (option) {
                                                        return option.value == (props.parametroAgua.tipoValidacao || '');
                                                    })}
                                                    onChange={handleSelectChange('tipoValidacao')}
                                                    isSearchable
                                                    id="tipoValidacao"
                                                    name="tipoValidacao"
                                                    options={renderOptionsTipoValidacao()}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col className="left" md="8" lg="6">
                                            <span>* Campos obrigatórios</span>
                                        </Col>
                                        <Col className="d-flex flex-row-reverse" md="4" lg="6">
                                            <Button variant="contained" color="primary" onClick={(event) => handleSubmit(event)}>Salvar <FontAwesomeIcon icon={faCheck} /></Button>
                                            <Button className="mr-1" variant="contained" color="secondary" component='a' onClick={() => history.push("/parametrosAgua/")} href="">Voltar <FontAwesomeIcon icon={faUndo} /></Button>
                                        </Col>
                                    </Row>
                                </TabPane>

                                <TabPane tabId="2">
                                    {props.parametroAgua.id !== '' ?
                                        (props.parametroAgua.tipoValidacao == 'N' ? <ParametrosAguaFaixaTab /> : <div></div>)
                                        :
                                        <div>Salve o Parâmetro primeiramente, para poder editar as faixas!</div>
                                    }
                                </TabPane>
                            </TabContent>
                        </Form>
                    </CardBody>
                </Card>
            </Container>
                <ModalLog toggle={toggle} modal={modal} log={log} />
        </div>
    );

};

const mapStateToProps = (state) => {
    return state;
};

const connectedParametrosAguaEditPage = withRouter(connect(mapStateToProps, null, null, {
                pure: false
})(ParametrosAguaEdit));
export { connectedParametrosAguaEditPage as ParametrosAguaEdit};
import { crudService } from '../_services';
import { history } from '../_helpers';
import alerts from '../_components/alerts';
import Swal from 'sweetalert2';
import { stringUtils } from '../_utils';

export const entidadesAction = {
    getEntidade,
    getEntidadeSelect,
    getEntidadeById,
    onChangeProps,
    editEntidadeInfo,
    createEntidade,
    deleteEntidadeById,
    clear
};

let filterModel = {
    Page: 1,
    Limit: 10,
    Term: ''
};

function getEntidade(filterModel) {
    return dispatch => {
        alerts.waiting();
        let apiEndpoint = 'entidades' + stringUtils.serialize(filterModel);
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    dispatch(changeEntidadesList(response.data.items, response.data.totalPages));
                }
                alerts.clean();
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function getEntidadeSelect() {
    return dispatch => {
        let apiEndpoint = 'entidades/select';
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    response.data.unshift({ value: '', text: ' SELECIONE ...' });
                    dispatch(changeEntidadesList(response.data, null));
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function createEntidade(payload) {
    return dispatch => {
        alerts.waiting();
        let apiEndpoint = 'entidades/';
        crudService.post(apiEndpoint, payload)
            .then((response) => {
                if (response.request.status === 200) {
                    var ret = JSON.parse(response.request.responseText);
                    dispatch(updatedUserInfo());
                    //history.push(ret.path);
                    alerts.success(ret.message);
                } else {
                    alerts.warning(response.request.responseText);
                }
            });
    };
}
function getEntidadeById(id) {
    return dispatch => {
        alerts.waiting();
        let apiEndpoint = 'entidades/' + id;
        crudService.get(apiEndpoint)
            .then((response) => {
                dispatch(editEntidadesDetails(response.data));
                alerts.clean();
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function onChangeProps(props, event) {
    return dispatch => {
        if (event.target.type === 'checkbox') {
            dispatch(handleOnChangeProps(props, event.target.checked));
        } else {
            dispatch(handleOnChangeProps(props, event.target.value));
        }
    };
}
function editEntidadeInfo(id, payload) {
    return dispatch => {
        alerts.waiting();
        let apiEndpoint = 'entidades/' + id;
        crudService.put(apiEndpoint, payload)
            .then((response) => {
                if (response.request.status === 200) {
                    var ret = JSON.parse(response.request.responseText);
                    dispatch(updatedUserInfo());
                    //history.push(ret.path);
                    alerts.success(ret.message);
                } else {
                    alerts.warning(response.request.responseText);
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function deleteEntidadeById(id) {
    return dispatch => {

        Swal.fire({
            title: 'Confirmação',
            text: 'Tem certeza de que deseja excluir o registro?',
            icon: 'warning',
            buttons: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: "Não, cancelar!",
            dangerMode: true,
            heightAuto: false
        })
        .then((willDelete) => {
            if (willDelete.value) {
                alerts.waiting();
                let apiEndpoint = 'entidades/' + id;
                crudService.deleteDetail(apiEndpoint)
                    .then((response) => {
                        if (response.request.status === 200) {
                            var ret = JSON.parse(response.request.responseText);
                            dispatch(deleteEntidadesDetails());
                            dispatch(getEntidade(filterModel));
                            alerts.success(ret.message);
                        } else {
                            alerts.warning(response.request.responseText);
                        }
                    }).catch((err) => {
                        console.log(err);
                        alerts.error(err);
                    });

            }
        });

    };
}
export function changeEntidadesList(entidade, totalPages) {
    return {
        type: "FETECHED_ALL_ENTIDADE",
        entidade: entidade,
        totalPages: totalPages
    };
}
export function clear() {
    return {
        type: "ENTIDADE_CLEAR"
    };
}
export function handleOnChangeProps(props, value) {
    return {
        type: "HANDLE_ON_CHANGE_ENTIDADE",
        props: props,
        value: value
    };
}
export function editEntidadesDetails(entidade) {
    return {
        type: "ENTIDADE_DETAIL",
        id: entidade.id,
        nome: entidade.nome,
        responsavel: entidade.responsavel,
        telefone: entidade.telefone,
        celular: entidade.celular,
        email: entidade.email,
        excluido: entidade.excluido,
    };
}
export function updatedUserInfo() {
    return {
        type: "ENTIDADE_UPDATED"
    };
}
export function createUserInfo() {
    return {
        type: "ENTIDADE_CREATED_SUCCESSFULLY"
    };
}
export function deleteEntidadesDetails() {
    return {
        type: "DELETED_ENTIDADE_DETAILS"
    };
}
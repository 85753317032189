import { crudService } from '../_services';
import { history } from '../_helpers';
import alerts from '../_components/alerts';
import Swal from 'sweetalert2';
import { stringUtils } from '../_utils';

export const fontesExternasAction = {
    getFonteExterna,
    getFonteExternaSelect,
    getFonteExternaById,
    onChangeProps,
    editFonteExternaInfo,
    createFonteExterna,
    deleteFonteExternaById,
    clear
};

let filterModel = {
    Page: 1,
    Limit: 10,
    Term: ''
};

function getFonteExterna(filterModel) {
    return dispatch => {
        alerts.waiting();
        let apiEndpoint = 'fontesExternas' + stringUtils.serialize(filterModel);
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    dispatch(changeFonteExternasList(response.data.items, response.data.totalPages));
                }
                alerts.clean();
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function getFonteExternaSelect() {
    return dispatch => {
        let apiEndpoint = 'fontesExternas/select';
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    response.data.unshift({ value: '', text: ' SELECIONE ...' });
                    dispatch(changeFonteExternasList(response.data, null));
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function createFonteExterna(payload) {
    return dispatch => {
        alerts.waiting();
        let apiEndpoint = 'fontesExternas/';
        crudService.post(apiEndpoint, payload)
            .then((response) => {
                if (response.request.status === 200) {
                    var ret = JSON.parse(response.request.responseText);
                    dispatch(updatedUserInfo());
                    //history.push(ret.path);
                    alerts.success(ret.message, ret.path);
                } else {
                    alerts.warning(response.request.responseText);
                }
            });
    };
}
function getFonteExternaById(id) {
    return dispatch => {
        alerts.waiting();
        let apiEndpoint = 'fontesExternas/' + id;
        crudService.get(apiEndpoint)
            .then((response) => {
                dispatch(editFonteExternasDetails(response.data));
                alerts.clean();
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function onChangeProps(props, event) {
    return dispatch => {
        if (event.target.type === 'checkbox') {
            dispatch(handleOnChangeProps(props, event.target.checked));
        } else {
            dispatch(handleOnChangeProps(props, event.target.value));
        }
    };
}
function editFonteExternaInfo(id, payload) {
    return dispatch => {
        alerts.waiting();
        let apiEndpoint = 'fontesExternas/' + id;
        crudService.put(apiEndpoint, payload)
            .then((response) => {
                if (response.request.status === 200) {
                    var ret = JSON.parse(response.request.responseText);
                    dispatch(updatedUserInfo());
                    //history.push(ret.path);
                    alerts.success(ret.message, ret.path);
                } else {
                    alerts.warning(response.request.responseText);
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}
function deleteFonteExternaById(id) {
    return dispatch => {

        Swal.fire({
            title: 'Confirmação',
            text: 'Tem certeza de que deseja excluir o registro?',
            icon: 'warning',
            buttons: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: "Não, cancelar!",
            dangerMode: true,
            heightAuto: false
        })
        .then((willDelete) => {
            if (willDelete.value) {

                let apiEndpoint = 'fontesExternas/' + id;
                crudService.deleteDetail(apiEndpoint)
                    .then((response) => {
                        if (response.request.status === 200) {
                            var ret = JSON.parse(response.request.responseText);
                            dispatch(deleteFonteExternasDetails());
                            dispatch(getFonteExterna(filterModel));
                            alerts.success(ret.message);
                        } else {
                            alerts.warning(response.request.responseText);
                        }
                    }).catch((err) => {
                        console.log(err);
                        alerts.error(err);
                    });

            }
        });

    };
}
export function changeFonteExternasList(fonteExterna, totalPages) {
    return {
        type: "FETECHED_ALL_FONTE_EXTERNA",
        fonteExterna: fonteExterna,
        totalPages: totalPages
    };
}
export function clear() {
    return {
        type: "FONTE_EXTERNA_CLEAR"
    };
}
export function handleOnChangeProps(props, value) {
    return {
        type: "HANDLE_ON_CHANGE_FONTE_EXTERNA",
        props: props,
        value: value
    };
}
export function editFonteExternasDetails(fonteExterna) {
    return {
        type: "FONTE_EXTERNA_DETAIL",
        id: fonteExterna.id,
        nome: fonteExterna.nome,
        link: fonteExterna.link,
        linkAcumulado: fonteExterna.linkAcumulado,
        excluido: fonteExterna.excluido,
    };
}
export function updatedUserInfo() {
    return {
        type: "FONTE_EXTERNA_UPDATED"
    };
}
export function createUserInfo() {
    return {
        type: "FONTE_EXTERNA_CREATED_SUCCESSFULLY"
    };
}
export function deleteFonteExternasDetails() {
    return {
        type: "DELETED_FONTE_EXTERNA_DETAILS"
    };
}
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { NavBar } from '../_components/navbar';
import { Container, NavLink, Row } from 'reactstrap';
import './styles.css';
import '././styles.css';
import { dashboardAction } from '../_actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Chart from 'react-apexcharts';
import { history } from '../_helpers';
import { faMapMarkerAlt, faCity, faMicroscope, faWater } from '@fortawesome/free-solid-svg-icons';
import backgroundMapa from '../imgs/bloco_mapa.jpg';
import backgroundAnalises from '../imgs/bloco_analises.jpg';

const Home = (props) => {

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(dashboardAction.getDashboard());
    }, []);

    const dashboard = props.dashboard;

  //  console.log('dashboard.graficoPontosCursos', dashboard.graficoPontosCursos)

    const blocoPontos = {
        background: `url(${backgroundMapa})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    };

    const blocoEntidades = {
        backgroundImage: `url(${backgroundAnalises})`,
        backgroundPosition: 'left',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    };

    const blocoAnalises = {
        backgroundImage: `url(${backgroundAnalises})`,
        backgroundPosition: 'right',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    };

    const blocoCursosdagua = {
        backgroundImage: `url(${backgroundMapa})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    };

    return (
        <div className="root">
            <div>
                <NavBar />

                <Container>
                    {/*<span className="text-capitalize">Bem-Vindo {JSON.parse(localStorage.getItem('user'))["nome"]}</span>*/}
                    <Row className="mt-2">
                        <div className="col-xl-3 col-md-3 mb-2">
                            <div style={blocoPontos} className="card border-left-primary shadow h-100 py-2">
                                <div className="card-body">
                                    <NavLink onClick={() => history.push("/pontosMonitoramento")} href="#">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-1">
                                                <div className="text-sm font-weight-bold text-primary mb-1">Pontos Ativos</div>
                                                <div className="h5 mb-0 font-weight-bold text-gray-800">{dashboard.totalPontos}</div>
                                            </div>
                                            <div className="col-auto">
                                                <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" className="indigo" />
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-md-3 mb-2">
                            <div style={blocoEntidades} className="card border-left-primary shadow h-100 py-2">
                                <div className="card-body">
                                    <NavLink onClick={() => history.push("/entidades")} href="#">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-1">
                                                <div className="text-sm font-weight-bold text-primary mb-1">Entidades Ativas</div>
                                                <div className="h5 mb-0 font-weight-bold text-gray-800">{dashboard.totalEntidades}</div>
                                            </div>
                                            <div className="col-auto">
                                                <FontAwesomeIcon icon={faCity} size="2x" className="indigo" />
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-md-3 mb-2">
                            <div style={blocoAnalises} className="card border-left-primary shadow h-100 py-2">
                                <div className="card-body">
                                    <NavLink onClick={() => history.push("/analises")} href="#">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-1">
                                                <div className="text-sm font-weight-bold text-primary mb-1">Análises Realizadas</div>
                                                <div className="h5 mb-0 font-weight-bold text-gray-800">{dashboard.totalAnalises}</div>
                                            </div>
                                            <div className="col-auto">
                                                <FontAwesomeIcon icon={faMicroscope} size="2x" className="indigo" />
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-md-3 mb-2">
                            <div style={blocoCursosdagua} className="card border-left-primary shadow h-100 py-2">
                                <div className="card-body">
                                    <NavLink onClick={() => history.push("/cursosDagua")} href="#">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-1">
                                                <div className="text-sm font-weight-bold text-primary mb-1">Cursos d'água</div>
                                                <div className="h5 mb-0 font-weight-bold text-gray-800">{dashboard.totalCursos}</div>
                                            </div>
                                            <div className="col-auto">
                                                <FontAwesomeIcon icon={faWater} size="2x" className="indigo" />
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                    </Row>

                    {<Row className="mt-2">

                        <div className="col-md-12 col-lg-6 mb-2 mt-2">
                            <div className="card border-left-primary shadow h-100">
                                <div className="card-body p-0 m-0">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-sm font-weight-bold text-primary m-1">Pontos de Monitoramento por Curso de Água</div>
                                            <div>
                                                <Chart
                                                    options={dashboard.graficoPontosCursos.options}
                                                    series={dashboard.graficoPontosCursos.series}
                                                    type="bar"
                                                    height="200"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        

                    </Row>

                    /*<Row className="mt-2">

                        <div className="col-sm-12 col-md-6 mb-2 mt-2">
                            <div className="card border-left-primary shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-sm font-weight-bold text-primary mb-1">Matrículas Ativas por Classes</div>
                                            <div>
                                                <Chart
                                                    options={dashboard.graficoMatriculasClasses.options}
                                                    series={dashboard.graficoMatriculasClasses.series}
                                                    type="donut"
                                                    height="auto"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6 mb-2 mt-2">
                            <div className="card border-left-primary shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-sm font-weight-bold text-primary mb-1">Matrículas Ativas por Níveis de Valorização</div>
                                            <div>
                                                <Chart
                                                    options={dashboard.graficoMatriculasNiveisCarreira.options}
                                                    series={dashboard.graficoMatriculasNiveisCarreira.series}
                                                    type="donut"
                                                    height="auto"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Row>

                    */}
                 
                </Container>               
            </div>
                <div className="container navbar-fixed-bottom">
                <a href="https://www.fortalezatec.com.br" rel="noopener noreferrer" target="_blank"><img src={require('../imgs/logo_forcontrol.png')} style={{ height: 60 }} className="brand_forcontrol" alt="logoForControl" /></a>
                <a href="https://www.fortalezatec.com.br" target="_blank"><img src={require('../imgs/brand_fortalezatec_escuro.png')} className="brand_fortalezatec" alt="logoFortalezaTec" style={{ height: 55 }}/></a>
                <img src={require('../imgs/logo_prosinos.png')} className="brand_prosinos" alt="logoProSinos" style={{ height: 55 }} />
                </div>
        </div>
    );

}

function mapStateToProps(state) {
    return state;
}
const connectedHomePage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(Home));

export { connectedHomePage as Home };
import { crudService } from '../_services';
import alerts from '../_components/alerts';
import { stringUtils } from '../_utils';

export const graficoQualidadeCursoDaguaAction = {
    getGrafico,
    getDatasSelect,
    onChangeProps,
    handleOnChangeProps,
    clear
};

function getGrafico(filterModel, showWaiting) {
    return dispatch => {
        if (showWaiting) {
            alerts.waiting();
        }
        let apiEndpoint = 'graficoQualidadeCursoDagua';
        crudService.get(apiEndpoint + stringUtils.serialize(filterModel))
            .then((response) => {
                if (response.data) {
                  //  console.log("RESPONSE.DATA grafico", response.data)
                    response.data.graficoQualidadeCursoDagua = {
                        options: {
                            chart: {
                                id: "basic-bar",                               
                                animations: {
                                    speed: 300
                                },
                            },
                            dataLabels: {
                                position: 'top',
                                enabled: true,
                                style: {
                                    fontWeight: 'normal',
                                    colors: ['#000']
                                },
                                offsetY: -20
                            },
                            plotOptions: {
                                bar: {
                                    distributed: false,
                                    horizontal: false,
                                    dataLabels: {
                                    position: 'top'
                                    }
                                }
                            },
                            xaxis: {
                                enabled: false,
                                labels: { 
                                    rotate: -45,
                                    type: 'category'
                                },
                                style: {
                                    fontSize: '7px'
                                },
                                categories: response.data.graficoQualidadeCursoDagua.options
                            }
                        },
                        series: [
                            {
                                name: "Valor",
                                data: response.data.graficoQualidadeCursoDagua.series[0],
                                colors: '#4d3a96'
                            },
                            {
                                name: "IQA",
                                data: response.data.graficoQualidadeCursoDagua.series[1],
                                colors: '#4d3a96'
                            }
                        ]
                    };
                    dispatch(editGraficosDetails(response.data));
                }
                if (showWaiting) {
                    alerts.clean();
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}

function getDatasSelect() {
    return dispatch => {
        let apiEndpoint = 'graficoQualidadeCursoDagua/select';
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    response.data.unshift({ value: '', text: ' SELECIONE ...' });
                    dispatch(changeGraficosList(response.data, null));
                }
            }).catch((err) => {
                console.log(err);
                alerts.error(err);
            });
    };
}

function onChangeProps(props, event) {
    return dispatch => {
        if (event.target.type === 'checkbox') {
            dispatch(handleOnChangeProps(props, event.target.checked));
        } else {
            dispatch(handleOnChangeProps(props, event.target.value));

        }
    };
}

export function changeGraficosList(graficoQualidadeCursoDagua, totalPages) {
    return {
        type: "FETECHED_ALL_GRAFICO_QUALIDADE_CURSO_DAGUA",
        graficoQualidadeCursoDagua: graficoQualidadeCursoDagua,
        volumeChuvaPontos: graficoQualidadeCursoDagua,
        totalPages: totalPages
    };
}
export function clear() {
    return {
        type: "GRAFICO_QUALIDADE_CURSO_DAGUA_CLEAR"
    };
}
export function handleOnChangeProps(props, value) {
    return {
        type: "HANDLE_ON_CHANGE_GRAFICO_QUALIDADE_CURSO_DAGUA",
        props: props,
        value: value
    };
}
export function editGraficosDetails(graficoQualidadeCursoDagua) {
    return {
        type: "GRAFICO_QUALIDADE_CURSO_DAGUA_DETAIL",
        graficoQualidadeCursoDagua: graficoQualidadeCursoDagua.graficoQualidadeCursoDagua,
        idCursoAgua: graficoQualidadeCursoDagua.idCursoAgua,
        data: graficoQualidadeCursoDagua.data,
        idParametro: graficoQualidadeCursoDagua.idParametro,
        volumeChuvaPontos: graficoQualidadeCursoDagua
    };
}
export function updatedUserInfo() {
    return {
        type: "GRAFICO_QUALIDADE_CURSO_DAGUA_UPDATED"
    };
}
export function createUserInfo() {
    return {
        type: "GRAFICO_QUALIDADE_CURSO_DAGUA_CREATED_SUCCESSFULLY"
    };
}
export function deleteGraficosDetails() {
    return {
        type: "DELETED_GRAFICO_QUALIDADE_CURSO_DAGUA_DETAILS"
    };
}
const initialState = {
    page: 1,
    limit: 10,
    order: 'data',
    direction: 'desc',
    analise: [],
    entidade: [],
    ponto: [],
    id: 0,
    data: '',
    idEntidade: '',
    idPonto: '',
    iqa: 0,
    iqaCalculado: 0,
    excluido: '',
    analisesParametros: []
};

export function analise(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_ANALISE':
            return {
                ...state,
                analise: action.analise,
                totalPages: action.totalPages
            };
        case 'ANALISE_DETAIL':
            return {
                ...state,
                id: action.id,
                data: action.data,
                idEntidade: action.idEntidade,
                iqa: action.iqa,
                iqaCalculado: action.iqaCalculado,
                idPonto: action.idPonto,
                excluido: action.excluido,
                analisesParametros: action.analisesParametros
            };
        case 'ANALISE_CLEAR':
            return {
                ...state,
                analise: [],
                entidade: [],
                ponto: [],
                id: 0,
                iqa: 0,
                iqaCalculado: 0,
                data: '',
                idEntidade: '',
                idPonto: '',
                excluido: '',
                analisesParametros: []
            };
        case "ANALISE_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_ANALISE":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state;
    }
}

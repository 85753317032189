import { combineReducers } from 'redux';

import { ana } from './ana.reducer';
import { analise } from './analises.reducer';
import { camada } from './camadas.reducer';
import { analiseParametro } from './analisesParametros.reducer';
import { authentication } from './auth.reducer';
import { cemaden } from './cemaden.reducer';
import { cursoDagua } from './cursosDagua.reducer';
import { dashboard } from './dashboard.reducer';
import { entidade } from './entidades.reducer';
import { fonteExterna } from './fontesExternas.reducer';
import { graficoQualidadeCursoDagua } from './graficoQualidadeCursoDagua.reducer';
import { graficoParametrosAgua } from './graficoParametrosAgua.reducer';
import { graficoParametrosQuantitativos } from './graficoParametrosQuantitativos.reducer';
import { log } from './log.reducer';
import { parametro } from './parametros.reducer';
import { parametroAgua } from './parametrosAgua.reducer';
import { parametroAguaFaixa } from './parametrosAguaFaixas.reducer';
import { permissaoTipoUsuario } from './permissoesTiposUsuarios.reducer';
import { permissaoUsuario } from './permissoesUsuarios.reducer';
import { pontoMonitoramento } from './pontosMonitoramento.reducer';
import { tipoUsuario } from './tiposUsuarios.reducer';
import { tipoUpload } from './tiposUploads.reducer';
import { tipoParametro } from './tiposParametros.reducer';
import { unidadeMedida } from './unidadesMedidas.reducer';
import { usuario } from './usuarios.reducer';
import { upload } from './uploads.reducer';

const rootReducer = combineReducers({
    ana,
    analise,
    analiseParametro,
    authentication,
    camada,
    cemaden,
    cursoDagua,
    dashboard,
    entidade,
    fonteExterna,
    graficoParametrosQuantitativos,
    graficoQualidadeCursoDagua,
    graficoParametrosAgua,
    log,
    parametro,
    parametroAgua,
    parametroAguaFaixa,
    permissaoTipoUsuario,
    permissaoUsuario,
    pontoMonitoramento,
    tipoUsuario,
    tipoUpload,
    tipoParametro,
    unidadeMedida,
    upload,
    usuario
});

export default rootReducer;
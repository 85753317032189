import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { analisesParametrosAction, parametrosAction } from '../_actions';
import { withRouter } from 'react-router-dom';
import { Table, Button, Row, Col, Input, Form } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSync, faDollarSign, faCalculator } from '@fortawesome/free-solid-svg-icons';
import NumberFormat from 'react-number-format';
import { stringUtils } from '../_utils';

const AnalisesParametrosTab = (props) => {

    const dispatch = useDispatch();

    useEffect(() => {
        const { match: { params } } = props;
        dispatch(analisesParametrosAction.getAnaliseParametroByAnaliseId(params.id, false));
    }, []);

    const handleRefresh = event => {
        const { match: { params } } = props;
        dispatch(analisesParametrosAction.getAnaliseParametroByAnaliseId(params.id, true));
    };

    const handleChange = (event, prop, n) => {
        var index = props.state.analiseParametro.analiseParametro.findIndex(x => x.idParametroAgua === n.idParametroAgua);
        dispatch(analisesParametrosAction.onChangePropsList(prop, event, index));
    };

    const handleSubmit = event => {
        dispatch(analisesParametrosAction.createAnalisesParametros(props.state.analiseParametro.analiseParametro));
    };

    const { analiseParametro } = props.state.analiseParametro;

    return (
        <div>
            <fieldset disabled={props.disabled}>
                <Table hover striped bordered responsive size="sm">
                    <thead>
                        <tr>
                            <th hidden>ID</th>
                            <th hidden>ID Parâmetro Água</th>
                            <th>Parâmetro</th>
                            <th>Valor</th>
                            <th>Un</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            analiseParametro.map((n, index) => {
                                return (
                                    <tr key={index}>
                                        <td style={{ textAlign: "left", verticalAlign: "middle" }} hidden>{n.id}</td>
                                        <td style={{ textAlign: "left", verticalAlign: "middle" }} hidden>{n.idParametroAgua}</td>
                                        <td style={{ textAlign: "left", verticalAlign: "middle" }}>{n.parametroAgua.nome}</td>
                                        <td style={{ width: "250px", textAlign: "right", verticalAlign: "middle" }}>
                                            {
                                                (n.parametroAgua.tipoValidacao == 'N' ?
                                                    <NumberFormat
                                                        id="valor"
                                                        label="Valor"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        className="form-control"
                                                        value={n.valor}
                                                        onChange={(event) => handleChange(event, 'valor', n)}
                                                    />
                                                    :
                                                <Input
                                                    id="valor"
                                                    label="Valor"
                                                    className="form-control"
                                                    type="text"
                                                    value={n.valor || ''}
                                                    onChange={(event) => handleChange(event, 'valor', n)}
                                                    />
                                                )
                                            }
                                        </td>
                                        <td style={{ width: "70px", textAlign: "left", verticalAlign: "middle" }}>{(n.parametroAgua.unidadeMedida != undefined ? n.parametroAgua.unidadeMedida.abreviatura : '')}</td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </Table>
                <Row>
                    <Col className="d-flex flex-row-reverse" md="12" lg="12">
                            <Button variant="contained" color="primary" onClick={(event) => handleSubmit(event)}>Salvar Valores <FontAwesomeIcon icon={faCheck} /></Button>
                    </Col>
                </Row>
            </fieldset>
        </div>
    );

};

const mapStateToProps = (state) => {
    return {
        state
    };
};

const connectedAnalisesParametrosTabPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(AnalisesParametrosTab));
export { connectedAnalisesParametrosTabPage as AnalisesParametrosTab };
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { tiposUsuariosAction, logAction } from '../_actions';
import { withRouter, Link } from 'react-router-dom';
import {
    Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, Button, Container, Row, Col, FormGroup, Label, Input, Form, UncontrolledTooltip
} from 'reactstrap';
import { NavBar } from '../_components/navbar';
import { history } from '../_helpers';
import { stringUtils } from '../_utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faCheck } from '@fortawesome/free-solid-svg-icons';
import '././styles.css';
import { ModalLog } from '../log/';

const TiposUsuariosEdit = (props) => {

    const dispatch = useDispatch();

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    useEffect(() => {
        const { match: { params } } = props;

        if (params.id) {
            dispatch(tiposUsuariosAction.getTipoUsuarioById(params.id));
        } else {
            dispatch(tiposUsuariosAction.clear());
        }

    }, []);

    const handleChange = prop => event => {
        dispatch(tiposUsuariosAction.onChangeProps(prop, event));
    };

    const handleSubmit = event => {

        event.preventDefault();
        const { match: { params } } = props;

        if (props.tipoUsuario.nome === null || props.tipoUsuario.nome === '') {
            alert('Informe um Nome válido!');
        } else {

            let payload = {
                id: params.id ? params.id : 0,
                nome: props.tipoUsuario.nome,
                todosCentrosLotacao: props.tipoUsuario.todosCentrosLotacao
            };

            if (params.id) {
                dispatch(tiposUsuariosAction.editTipoUsuarioInfo(params.id, payload));
            } else {
                dispatch(tiposUsuariosAction.createTipoUsuario(payload));
            }

        }

    };

    const handleClickHistory = event => {

        event.preventDefault();
        const { match: { params } } = props;

        if (params.id) {
            dispatch(logAction.getLog('TipoUsuario', params.id));
            toggle();
        }

    };

    const { log } = props.log;

    return (
        <div>
            <NavBar />
            <Container>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col md={12}>
                                <Breadcrumb>
                                    <BreadcrumbItem><Link to="/tiposUsuarios" href="">Lista de Tipos de Usuarios</Link></BreadcrumbItem>
                                    <BreadcrumbItem active>{(props.tipoUsuario.id > 0 ? props.tipoUsuario.nome : 'Novo')}</BreadcrumbItem>
                                    {props.tipoUsuario.id > 0 ?
                                        <BreadcrumbItem className="pb-0 pt-0 m-0 btn btn-muted">
                                            <a href="#" id="tagHistorico" onClick={(event) => handleClickHistory(event)}>Histórico</a>
                                            <UncontrolledTooltip placement="bottom" target="tagHistorico">
                                                Exibe o histórico de alterações do registro
                                            </UncontrolledTooltip>
                                        </BreadcrumbItem>
                                    : null}
                                </Breadcrumb>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Form onSubmit={(event) => handleSubmit(event)}>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label className="left" for="nome">Nome *</Label>
                                        <Input
                                            id="nome"
                                            label="Nome"
                                            className="form-control"
                                            value={props.tipoUsuario.nome}
                                            onChange={handleChange('nome')}
                                            onInput={stringUtils.toInputUppercase}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup check>
                                        <Label className="left" for="todosCentrosLotacao" check>Pode visualizar/editar todos os Centros de Lotação
                                            <Input
                                                id="todosCentrosLotacao"
                                                label="Pode visualizar/editar todos os Centros de Lotação"
                                                type="checkbox"
                                                checked={props.tipoUsuario.todosCentrosLotacao || false}
                                                onChange={handleChange('todosCentrosLotacao')}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        <br />
                        <Row>
                            <Col className="left" md="6" lg="6">
                                <span>* Campos obrigatórios</span>
                            </Col>
                            <Col className="d-flex flex-row-reverse" md="6" lg="6">
                                <Button variant="contained" color="primary" type="submit">Salvar <FontAwesomeIcon icon={faCheck} /></Button>
                                <Button className="mr-1" variant="contained" color="secondary" component='a' onClick={() => history.push("/tiposUsuarios")} href="">Voltar <FontAwesomeIcon icon={faUndo} /></Button>
                            </Col>
                        </Row>
                        </Form>
                    </CardBody>
                </Card>
            </Container>
            <ModalLog toggle={toggle} modal={modal} log={log} />
        </div >
    );

};

const mapStateToProps = (state) => {
    return state;
};

const connectedTiposUsuariosEditPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(TiposUsuariosEdit));
export { connectedTiposUsuariosEditPage as TiposUsuariosEdit };
  import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { authActions } from '../_actions';
import { withRouter, Link } from 'react-router-dom';
import {
    Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown,
    DropdownToggle, DropdownItem, DropdownMenu
} from 'reactstrap';
import { withStyles } from '@material-ui/core/styles';
import { history } from '../_helpers';
import { AccessControl } from '../_components/accessControl';

const styles = theme => ({
    //navBar: {
    //    backgroundColor: '#bebebe',
    //}
});

const NavBar = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);
    const dispatch = useDispatch();

    // atualizar permissões sem precisar refazer o login
    useEffect(() => {

    }, [JSON.stringify(props.permissaoTipoUsuario)]);

    useEffect(() => {

    }, [JSON.stringify(props.permissaoUsuario)]);

    return (
        <div>
            <Navbar className="navbar navbar-light bg-light" expand="md">
                <NavbarBrand href="/home">
                    {<img src={require('../imgs/logo_gestor_bacias_horizontal.png')} width="140" height="auto" alt="logo" className="logo" />}
                    {<img src={require('../imgs/logo_monitoramento.png')} width="170" height="auto" alt="logo" className="p-0 m-0 ml-3" />}
                </NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav navbar>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle className="btn" nav caret>
                                Cadastros
                            </DropdownToggle>
                            <DropdownMenu>
                                <AccessControl
                                    rule={'camadas'}
                                    yes={() => (
                                        <DropdownItem>
                                            <NavLink className="dropdown-item" onClick={() => history.push("/camadas")} href="#">Camadas</NavLink>
                                        </DropdownItem>
                                    )}
                                />
                                <AccessControl
                                    rule={'cursosDagua'}
                                    yes={() => (
                                        <DropdownItem>
                                            <NavLink className="dropdown-item" onClick={() => history.push("/cursosDagua")} href="#">Cursos d'Água</NavLink>
                                        </DropdownItem>
                                    )}
                                />
                                <AccessControl
                                    rule={'entidades'}
                                    yes={() => (
                                        <DropdownItem>
                                            <NavLink className="dropdown-item" onClick={() => history.push("/entidades")} href="#">Entidades</NavLink>
                                        </DropdownItem>
                                    )}
                                />
                                <AccessControl
                                    rule={'fontesExternas'}
                                    yes={() => (
                                        <DropdownItem>
                                            <NavLink className="dropdown-item" onClick={() => history.push("/fontesExternas")} href="#">Fontes Externas</NavLink>
                                        </DropdownItem>
                                    )}
                                />
                                <AccessControl
                                    rule={'unidadesMedidas'}
                                    yes={() => (
                                        <DropdownItem>
                                            <NavLink className="dropdown-item" onClick={() => history.push("/unidadesMedidas")} href="#">Unidades de Medida</NavLink>
                                        </DropdownItem>
                                    )}
                                />
                                <DropdownItem divider />
                                <AccessControl
                                    rule={'usuarios'}
                                    yes={() => (
                                        <DropdownItem>
                                            <NavLink className="dropdown-item" onClick={() => history.push("/usuarios")} href="#">Usuários</NavLink>
                                        </DropdownItem>
                                    )}
                                />
                                <AccessControl
                                    rule={'permissoes'}
                                    yes={() => (
                                        <DropdownItem>
                                            <NavLink className="dropdown-item" onClick={() => history.push("/permissoes")} href="#">Permissões de Acesso</NavLink>
                                        </DropdownItem>
                                    )}
                                />
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle className="btn" nav caret href="#">
                                Monitoramento
                            </DropdownToggle>
                            <DropdownMenu>
                                <AccessControl
                                    rule={'analises'}
                                    yes={() => (
                                        <DropdownItem>
                                            <NavLink className="dropdown-item" onClick={() => history.push("/analises")} href="#">Análises de Água</NavLink>
                                        </DropdownItem>
                                    )}
                                />
                                <AccessControl
                                    rule={'parametrosAgua'}
                                    yes={() => (
                                        <DropdownItem>
                                            <NavLink className="dropdown-item" onClick={() => history.push("/parametrosAgua")} href="#">Parâmetros de Água</NavLink>
                                        </DropdownItem>
                                    )}
                                />
                                <AccessControl
                                    rule={'pontosMonitoramento'}
                                    yes={() => (
                                        <DropdownItem>
                                            <NavLink className="dropdown-item" onClick={() => history.push("/pontosMonitoramento")} target="_blank" href="#">Pontos de Monitoramento</NavLink>
                                        </DropdownItem>
                                    )}
                                />
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle className="btn" nav caret>
                                Relatórios
                            </DropdownToggle>
                            <DropdownMenu>
                                <AccessControl
                                    rule={'relatorioAnalises'}
                                    yes={() => (
                                        <DropdownItem>
                                            <NavLink className="dropdown-item" onClick={() => history.push("/pesquisaRelatorioAnalises")} href="#">Relatório de Análises</NavLink>
                                        </DropdownItem>
                                    )}
                                />
                            </DropdownMenu>
                        </UncontrolledDropdown>

                        <NavItem>
                            <NavLink className="pt-2" to="/" target="_blank" href="">Mapa de Pontos</NavLink>
                        </NavItem>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle className="btn" nav caret>
                                Gráficos
                            </DropdownToggle>
                            <DropdownMenu>
                                <AccessControl
                                    rule={'relatorioAnalises'}
                                    yes={() => (
                                        <DropdownItem>
                                            <NavLink className="dropdown-item" onClick={() => history.push("/graficoParametrosAgua")} href="#">Parâmetros Qualitativos em um Ponto</NavLink>
                                        </DropdownItem>
                                    )}
                                />
                                <AccessControl
                                    rule={'relatorioAnalises'}
                                    yes={() => (
                                        <DropdownItem>
                                            <NavLink className="dropdown-item" onClick={() => history.push("/graficoParametrosQuantitativos")} href="#">Parâmetros Quantitativos</NavLink>
                                        </DropdownItem>
                                    )}
                                />
                                <AccessControl
                                    rule={'relatorioAnalises'}
                                    yes={() => (
                                        <DropdownItem>
                                            <NavLink className="dropdown-item" onClick={() => history.push("/graficoQualidadeCursoDagua")} href="#">Qualidade dos Cursos D'Água</NavLink>
                                        </DropdownItem>
                                    )}
                                />
                            </DropdownMenu>
                        </UncontrolledDropdown>                       
                    </Nav>
                    <Nav className="ml-auto">
                        <NavItem>
                            <NavLink style={{ cursor: 'pointer' }} onClick={authActions.logout()}><small>({JSON.parse(localStorage.getItem('user'))["nome"]})</small> Sair</NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        permissaoTipoUsuario: (state.authentication.user ? (state.authentication.user.tipoUsuario ? state.authentication.user.tipoUsuario.permissoesTiposUsuarios : null) : null),
        permissaoUsuario: (state.authentication.user ? state.authentication.user.permissoesUsuarios : null)
    };
}
//const connectedNavBarPage = connect(mapStateToProps)(NavBar);
const connectedNavBarPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(withStyles(styles)(NavBar)));
export { connectedNavBarPage as NavBar };
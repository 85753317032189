import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { NavBarIndex } from '../_components/navbarIndex';
import './styles.css';

const Sobre = (props) => {

    return (
        <div id="page-content" className="fundo_login">
            <div>
                <NavBarIndex />
            </div>
            <div id="">
                <div className="container text-center">
                    <div className="row justify-content-center form_container">
                        <div className="user_card">
                            <div className="d-flex justify-content-center brand_img">
                                {<img src={require('../imgs/logo_gestor_bacias.png')} className="brand_logo" alt="logo_gestor_bacias" />}
                            </div>
                            <div className="d-flex justify-content-center">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer id="footer">
                <div className="container text-center">
                    <a href="https://www.fortalezatec.com.br" rel="noopener noreferrer" target="_blank"><img src={require('../imgs/logo_forcontrol.png')} style={{ height: 60 }} className="brand_forcontrol" alt="logoForControl" /></a>
                    <a href="https://www.fortalezatec.com.br" target="_blank"><img src={require('../imgs/brand_fortalezatec_escuro.png')} className="brand_fortalezatec" alt="logoFortalezaTec" style={{ height: 55 }} /></a>
                    <img src={require('../imgs/logo_prosinos.png')} className="brand_prosinos" alt="logoProSinos" style={{ height: 55 }} />
                </div>
            </footer>
        </div>
    );

};

const mapStateToProps = (state) => {
    const { loggingIn } = state.authentication;
    return {
        loggingIn
    };
};
const connectedLoginPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(Sobre));

export { connectedLoginPage as Sobre };
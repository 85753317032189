import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { pontosMonitoramentoAction, analisesAction, anaAction, cemadenAction } from '../_actions';
import PropTypes from 'prop-types';
import {
    Card, CardBody, CardHeader, Button, UncontrolledTooltip, Container, Modal, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';
import GoogleMap from '../_components/googleMap';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faExclamationCircle, faImage, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { stringUtils } from '../_utils';
import Chart from 'react-apexcharts';
import alerts from '../_components/alerts';
import { ModalParametros } from '../_components/modal';
import classnames from 'classnames';

const mapStyles = {
    width: '100%',
    height: '90vh'
};

const drawerWidth = 240;

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1.2),
    },
    mapFrame: {
        width: '100%',
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex'
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
    },
    'appBar-left': {
        marginLeft: drawerWidth,
    },
    'appBar-right': {
        marginRight: drawerWidth,
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
    paper: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
    },
    gridItem: {
        padding: 5,
        alignSelf: 'flex-end'
    },
    clearButton: {
        color: '#ffffff'
    }
});

// InfoWindow component
const InfoWindow = (props) => {

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const { place } = props;

    const infoWindowStyle = {
        position: 'relative',
        top: '0px',
        left: -130,
        width: 280,
        backgroundColor: 'white',
        boxShadow: '0 2px 7px 4px rgba(0, 0, 0, 0.4)',
        borderRadius: '6px',
        padding: 8,
        fontSize: 12,
        zIndex: 100,
    };

    const infoWindowStyleAna = {
        position: 'relative',
        top: '0px',
        left: -250,
        width: 500,
        backgroundColor: 'white',
        boxShadow: '0 2px 7px 4px rgba(0, 0, 0, 0.4)',
        borderRadius: '6px',
        padding: 8,
        fontSize: 12,
        zIndex: 100,
    };

    const infoDados = {
        textAlign: 'left',
        verticalAlign: 'middle',
        fontSize: 13,
        padding: 3,
        margin: 0,
    };

    const infoCurso = {
        textAlign: 'center',
        verticalAlign: 'middle',
        fontSize: 11,
        paddingTop: 2,
        margin: 0,
    };

    const infoParametros = {
        textAlign: 'left',
        verticalAlign: 'middle',
        fontSize: 12,
        margin: 0,
        padding: 2,
    };

    const openImage = (nome, imagem) => {

        alerts.showImage(imagem, nome);

    }

    //<p key={a.id} style={infoParametros}><b>{a.parametroAgua.nome + ": "}</b><span>{a.valor + " " + (a.parametroAgua.unidadeMedida ? a.parametroAgua.unidadeMedida.abreviatura : "")}{(cor_faixa == undefined ? '' : <span><FontAwesomeIcon style={{ color: cor_faixa, marginLeft: 3 }} icon={faExclamationCircle} /> <span style={{ color: cor_faixa }}>{nome_faixa}</span></span>)}</span></p>
    const openParametros = (parametros) => {

        var html = "";
        parametros.map((a) => {
            var cor_faixa = stringUtils.getParametroFaixa(a.parametroAgua, a.valor).cor;
            var nome_faixa = stringUtils.getParametroFaixa(a.parametroAgua, a.valor).nome;
            var conteudo = '<p style="padding: 0; margin: 2px;"><b>' + a.parametroAgua.nome + '": "</b><span>' + a.valor + ' ' + (a.parametroAgua.unidadeMedida ? a.parametroAgua.unidadeMedida.abreviatura : "") + (cor_faixa == undefined ? '' : <span><FontAwesomeIcon style={{ color: cor_faixa, marginLeft: 3 }} icon={faExclamationCircle} /> <span style={{ color: cor_faixa }}>{nome_faixa}</span></span>) + '</span></p>';
            html += conteudo
        })

        alerts.html(parametros.nome, html);

    }

    const handleClickHistory = event => {

        event.preventDefault();

        toggle();

    };

    let linkEstacao = "http://sjc.salvar.cemaden.gov.br/resources/graficos/cemaden/hidro/hidrologica_interativo.html?est=" + place.codigoReferencia + "&uf=RS";

    return (
        <>
        <div style={(place.fonteExterna != undefined && place.fonteExterna.nome == 'ANA' ? infoWindowStyleAna : infoWindowStyle)}>
            <div>
                <a href="#" className="btn btn-sm" style={{ marginTop: "1px", marginRight: "2px", position: "absolute", top: 0, right: 0 }}>X</a>
                <Row>
                    <Col md={12}>
                        <span className="h6">{place.nome}</span>
                        {(place.curso != '' ? <p style={infoCurso}><b>CURSO D'ÁGUA: </b> {place.curso} {(place.codigoReferencia != '' ? '(' + place.codigoReferencia + ')' : '')}</p> : '')}
                    </Col>
                </Row>
            </div>
            <hr style={{ margin: '5px' }} />
            <div>
                <Row>
                    <Col md={8}>
                            {(place.data != undefined ? <div style={infoDados}><b>DATA DA ANÁLISE: </b><br />{stringUtils.formataDataHora(place.data)}</div> : '')}
                            {(place.entidade != undefined ? <div style={infoDados}>{(place.fonteExterna == undefined || place.fonteExterna.nome != 'ANA' || place.fonteExterna.nome != 'CEMADEN' ? <><b>LABORATÓRIO: </b><br /></> : '')}{place.entidade.nome}
                            {(place.fonteExterna != undefined && place.fonteExterna.nome == 'CEMADEN' ?
                                <a href={linkEstacao} target="_blank"><FontAwesomeIcon style={{ marginLeft: 5 }} icon={faInfoCircle} /></a> : '')}</div> : '')}
                    </Col>
                    <Col left md={4}>
                            {(place.iqa != undefined ? <p style={infoDados}><b>IQA: </b>{place.iqa}</p> : '')}
                            {(place.info != undefined ?
                            <p style={infoDados}><a href="#" id="tagHistorico" onClick={(event) => handleClickHistory(event)}>Detalhado</a></p> : "")}
                    </Col>
                    <hr style={{ margin: '3px', borderTop: '2px dotted #bebebe' }} />
                </Row>
            </div>
            {(place.cemaden != null ?
                <div>
                    <hr style={{ margin: '3px', borderTop: '2px dotted #bebebe' }} />
                    <h6>Acumulado de chuva <FontAwesomeIcon onClick={() => openImage(place.cemaden.imagem)} style={{ marginLeft: 3 }} icon={faImage} size="lg" /></h6>
                    <Chart
                        options={place.cemaden.graficoPluviometriaAcumulada.options}
                        series={place.cemaden.graficoPluviometriaAcumulada.series}
                        type="line"
                        height="180"
                    />
                </div>
                : '')}
            {(place.ana != null ?
                <div>
                    <hr style={{ margin: '3px', borderTop: '2px dotted #bebebe' }} />
                    <small className="text-center">(Últimas 24 horas)</small>
                    <Row>
                        <Col sm="4">
                            <h6 className="text-center pb-0 mb-0">Chuva Acumulada</h6>
                            <Chart
                                options={place.ana.graficoChuva.options}
                                series={place.ana.graficoChuva.series}
                                type="line"
                                height="150"
                            />
                        </Col>
                        <Col sm="4">
                            <h6 className="text-center pb-0 mb-0">Nível do Curso</h6>
                            <Chart
                                options={place.ana.graficoNivel.options}
                                series={place.ana.graficoNivel.series}
                                type="line"
                                height="150"
                            />
                        </Col>
                        <Col sm="4">
                            <h6 className="text-center pb-0 mb-0">Vazão do Curso</h6>
                            <Chart
                                options={place.ana.graficoVazao.options}
                                series={place.ana.graficoVazao.series}
                                type="line"
                                height="150"
                            />
                        </Col>
                    </Row>
                </div>
                : '')}
            </div>
            <ModalParametros toggle={toggle} modal={modal} parametros={place.info} />
        </>
    );
};

// Marker component
const Marker = ({ show, place, color }) => {
    const markerStyle = {
        height: 25,
        width: 25,
        cursor: 'pointer',
        zIndex: 10,
        marginTop: '-15px', //centralizar no ponto
        marginLeft: '-12px', //centralizar no ponto
    };

    const markerCedamenStyle = {
        height: 25,
        width: 25,
        cursor: 'pointer',
        zIndex: 10,
        marginTop: '-15px', //centralizar no ponto
        marginLeft: '-12px', //centralizar no ponto
    };

    return (
        <>
            {
                (
                    (place.fonteExterna != null && place.fonteExterna.nome == 'CEMADEN') ?
                        <img src={require('../imgs/ponto_cemaden.png')} style={markerCedamenStyle} />
                        :
                        (place.fonteExterna != null && place.fonteExterna.nome == 'ANA') ?
                            <img src={require('../imgs/ponto_ana.png')} style={markerStyle} />
                            :
                            <img src={require('../imgs/ponto_quali_mapa.png')} style={markerStyle} />
                )
            }
            {show && <InfoWindow place={place} />}
        </>
    );
};

const MarkerInfoWindow = (props) => {

    const [analise, setAnalise] = useState();
    const [ana, setAna] = useState();
    const [cemaden, setCemaden] = useState();
    const [places, setPlaces] = useState([]);

    const [, forceUpdate] = React.useState(0);
    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(pontosMonitoramentoAction.getPontosMonitoramentoPublic());
        setPlaces(props.state.pontoMonitoramento.pontoMonitoramento);

    }, []);

    useEffect(() => {

        setPlaces(props.state.pontoMonitoramento.pontoMonitoramento);

    }, [props.state.pontoMonitoramento.pontoMonitoramento]);

    useEffect(() => {

        setAnalise(props.state.analise.analise);

    }, [props.state.analise.analise]);

    useEffect(() => {

        setCemaden(props.state.cemaden);

    }, [props.state.cemaden]);

    useEffect(() => {

        setAna(props.state.ana);

    }, [props.state.ana]);

    const onChildClickCallback = (key) => {

        //dispatch(analisesAction.getUltimaAnaliseByPontoPublic(key));

        //setCemaden(null);
        //let filterCemaden = {
        //    IdPonto: key,
        //    Periodos: 96
        //};

        //setAna(null);
        //let filterAna = {
        //    IdPonto: key,
        //    Datainicio: '',
        //    DataFim: ''
        //};

        //props.state.pontoMonitoramento.pontoMonitoramento.map(item =>
        //    item.id == key && item.fonteExterna != null
        //        ? (item.fonteExterna.nome == 'ANA' ?
        //            dispatch(anaAction.getAna(filterAna))
        //            :
        //            dispatch(cemadenAction.getCemaden(filterCemaden))
        //        ) : '' )

        //setPlaces(
        //    props.state.pontoMonitoramento.pontoMonitoramento.map(item =>
        //        item.id == key
        //            ? { ...item, show: true }
        //            : { ...item, show: false }
        //    ));

        //forceUpdate(n => !n);

    };

    const handleClose = () => {

        setAna(null);
        setCemaden(null);

        setPlaces(
            props.state.pontoMonitoramento.pontoMonitoramento.map(item =>
                item.id == 0
                    ? { ...item, show: true }
                    : { ...item, show: false }
            ));

        forceUpdate(n => !n);

    }

    return (
        <>
            {/*onClick={handleClose}*/}
            {((places != null && places.length > 0) ? (
                <div style={mapStyles}>
                    <GoogleMap
                        defaultZoom={10}
                        defaultCenter={[-29.5702142, -50.8251471]}
                        bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY }}
                        onChildClick={onChildClickCallback}
                        onClick={handleClose}
                    >
                        {places.map((n) => {
                            if (n.latitude == null || n.latitude === '') {
                                return;
                            }

                            if (n.longitude == null || n.longitude === '') {
                                return;
                            }

                            let cemad;
                            if (n.fonteExterna != null && n.fonteExterna.nome == 'CEMADEN') {
                                cemad = cemaden;
                            } else {
                                cemad = null;
                            }

                            let varana;
                            if (n.fonteExterna != null && n.fonteExterna.nome == 'ANA') {
                                varana = ana;
                            } else {
                                varana = null;
                            }

                            let objPlace = {
                                nome: n.nome,
                                ana: varana,
                                cemaden: cemad,
                                curso: (n.cursoDagua != null ? n.cursoDagua.nome : ''),
                                codigoReferencia: n.codigoReferencia,
                                data: analise.data,
                                entidade: analise.entidade,
                                info: analise.analisesParametros,
                                iqa: analise.iqa,
                                fonteExterna: n.fonteExterna
                            }

                            var bgcolor = '#6990fd';

                            return (
                                <Marker
                                    key={n.id}
                                    lat={n.latitude}
                                    lng={n.longitude}
                                    show={n.show || false}
                                    place={objPlace}
                                    color={bgcolor}
                                />
                            )

                        })}
                    </GoogleMap>
                </div>
            )
                :
                null)}
        </>
    );

}

InfoWindow.propTypes = {
    place: PropTypes.shape({
        nome: PropTypes.string,
        latitude: PropTypes.string
    }).isRequired,
};

Marker.propTypes = {
    show: PropTypes.bool.isRequired,
    place: PropTypes.shape({
        nome: PropTypes.string,
        latitude: PropTypes.string
    }).isRequired,
};

MarkerInfoWindow.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
    return {
        state
    };
}
const connectedMarkerInfoWindowPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(withStyles(styles)(MarkerInfoWindow)));
export { connectedMarkerInfoWindowPage as MarkerInfoWindow };
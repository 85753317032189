import React, { useState } from 'react';
import {  useDispatch } from "react-redux";
import { uploadsAction } from '../_actions';
import { Modal, ModalHeader, ModalBody, Table, Button, TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrash, faBroom } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import { UploadField } from '../_components/uploads';
import alerts from '../_components/alerts';
import Select from 'react-select';
//import api from '../_components/uploads/services';
import axios from "axios";

export const ModalUploads = ({ props, modal, toggle, uploads, tipo, id }) => {

    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const handleClean = event => {
        dispatch(uploadsAction.onChangeProps('arquivo', null));
        dispatch(uploadsAction.clear());
    };

    const handleChange = prop => event => {
        dispatch(uploadsAction.onChangeProps(prop, event));
    };

    const handleSelectChange = prop => event => {

        var event2 = {
            type: 'select',
            target: {
                value: event.value
            }
        };

        dispatch(uploadsAction.onChangeProps(prop, event2));
    };

    const handleClick = event => {

        event.preventDefault();
        const { match: { params } } = props;

        if (props.upload.idTipoUpload === null || props.upload.idTipoUpload === '') {
            alerts.alert('Informe um Tipo de Arquivo válido!');
        } else if (props.upload.arquivo === null || props.upload.arquivo === '') {
            alerts.alert('Informe um Arquivo válido!');
        } else {

            let payload = {
                id: 0,
                idTipoUpload: props.upload.idTipoUpload,
                idCamada: (tipo === 'camada' ? id : null),
                observacao: props.upload.observacao,
                arquivo: props.upload.arquivo
            };

            dispatch(uploadsAction.createUpload(payload));

            toggle();

        }

    };

    const handleDelete = (event, id) => {
        dispatch(uploadsAction.deleteUploadById(id));
    };

    const handleOpen = (event, file) => {        

        const api = axios.create({
            //baseUrl: 'http://localhost:37429/api/'
            baseUrl: 'http://monitoramento.prosinos.rs.gov.br/api/'
        });

        var filename = file.replace(/^.*[\\\/]/, '');
        api.get('api/uploads/S3/' + filename, {
            responseType: "blob",
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then((res) => {
            const file = new Blob(
                [res.data],
                { type: res.data.type });

            const fileURL = URL.createObjectURL(file);
            window.open(fileURL, '_blank');
        })
        .catch((err) => {
            alerts.error('Arquivo não localizado!');
            console.log('err', err);
        })

    };

    const getURLArquivo = url => {
        dispatch(uploadsAction.onChangeProps('arquivo', url));
    };

    const renderOptionsTipoUpload = () => {
        return props.tipoUpload.tipoUpload.map((p, i) => {
            return (
                { value: p.value, label: p.text }
            );
        });
    };

    return (         
        <div>
            <Modal isOpen={modal} toggle={toggle} size="xl" >
                <ModalHeader toggle={toggle}>Arquivos</ModalHeader>
                <ModalBody>

                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                href="#"
                                className={classnames({ active: activeTab === '1' })}
                                onClick={() => { toggleTab('1'); }}
                            >
                                Arquivos enviados
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                href="#"
                                className={classnames({ active: activeTab === '2' })}
                                onClick={() => { toggleTab('2'); }}
                            >
                                Novo Arquivo
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={activeTab}>
                        <br />

                        <TabPane tabId="1">

                            <Table hover striped bordered responsive size="sm">
                                <thead>
                                    <tr>
                                        <th>Tipo</th>
                                        <th>Arquivo</th>
                                        <th>Observação</th>
                                        <th>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        uploads.map(n => {
                                            return (
                                                <tr key={n.id}>
                                                    <td style={{ textAlign: "left", verticalAlign: "middle" }}>{n.tipoUpload.nome}</td>
                                                    <td style={{ textAlign: "left", verticalAlign: "middle" }}>
                                                        <Button color="link" size="sm" onClick={(event) => handleOpen(event, n.arquivo)}>
                                                            {n.arquivo.replace(/^.*[\\\/]/, '')}
                                                        </Button>
                                                        {/*<a target="_blank" href={n.arquivo}>{n.arquivo}</a>*/}
                                                    </td>
                                                    <td style={{ textAlign: "left", verticalAlign: "middle" }}>{n.observacao}</td>
                                                    <td style={{ textAlign: "center", width: "80px" }}>
                                                        <Button color="danger" size="sm"
                                                            onClick={(event) => handleDelete(event, n.id)}>
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </Table>

                        </TabPane>

                        <TabPane tabId="2">

                            <div style={{ minHeight: "300px" }}>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label className="left" for="idTipoUpload">Tipo de Arquivo *</Label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                value={renderOptionsTipoUpload().filter(function (option) {
                                                    return option.value == (props.upload.idTipoUpload || '');
                                                })}
                                                onChange={handleSelectChange('idTipoUpload')}
                                                isSearchable
                                                id="idTipoUpload"
                                                name="idTipoUpload"
                                                options={renderOptionsTipoUpload()}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label className="left" for="observacao">Observação</Label>
                                            <Input
                                                id="observacao"
                                                label="Observação"
                                                className="form-control"
                                                value={props.upload.observacao || ''}
                                                onChange={handleChange('observacao')}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label className="left" for="arquivo">Arquivo *</Label>
                                            {
                                                props.upload.arquivo ?
                                                    <div>{props.upload.arquivo }</div>
                                                :
                                                    <UploadField parentCallback={(url) => getURLArquivo(url)} url={props.upload.arquivo} />
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <br />
                                <Row className="mb-3">
                                    <Col className="d-flex flex-row-reverse" md="12" lg="12">
                                        <Button variant="contained" color="primary" onClick={(event) => handleClick(event)}>Enviar <FontAwesomeIcon icon={faCheck} /></Button>
                                        <Button className="mr-1" variant="contained" color="secondary" onClick={(event) => handleClean(event)}>Limpar Arquivo <FontAwesomeIcon icon={faBroom} /></Button>
                                    </Col>
                                </Row>
                            </div>

                        </TabPane>

                    </TabContent>
                    
                </ModalBody>
            </Modal>
        </div>
    );

};
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { NavBarIndex } from '../_components/navbarIndex';
import { camadasAction } from '../_actions';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    KmlLayer,
    Marker,
    InfoWindow
} from "react-google-maps";
import {
    Row, Col, Button, Link
} from 'reactstrap';
import { stringUtils } from '../_utils';
import Chart from 'react-apexcharts';
import alerts from '../_components/alerts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faImage, faStar, faLayerGroup, faFilter } from '@fortawesome/free-solid-svg-icons';
import { ModalParametros } from '../_components/modal';
import { crudService } from '../_services';
import { Hidden } from '@material-ui/core';
import {
    Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown,
    DropdownToggle, DropdownItem, DropdownMenu
} from 'reactstrap';

const styles = theme => ({
    mapFrame: {
        height: 'auto',
        left: '0',
        right: '0',
        top: '40',
        position: 'absolute',
        display: 'block'
    },
    appFrame: {
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
    }
});

const mapStyles = {
    width: '100%',
    height: '100%'
};

const markerStyle = {
    height: 25,
    width: 25,
    cursor: 'pointer',
    zIndex: 10,
    marginTop: '0px', //-15 centralizar no ponto
    marginLeft: '0px', //-12 centralizar no ponto
};

const infoWindowStyle = {
    top: '0px',
    overflow: 'hidden',
    backgroundColor: 'white',
    padding: 8,
    fontSize: 12,
};

const infoWindowStyleAna = {
    top: '0px',
    left: -250,
    width: 500,
    backgroundColor: 'white',
    padding: 8,
    fontSize: 12,
};

const infoWindowStyleCemaden = {
    top: '0px',
    overflow: 'hidden',
    backgroundColor: 'white',
    padding: 8,
    fontSize: 12,
};

const infoDados = {
    textAlign: 'left',
    verticalAlign: 'middle',
    fontSize: 13,
    padding: 3,
    margin: 0,
};

const infoCurso = {
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: 11,
    paddingTop: 2,
    margin: 0,
};

const infoParametros = {
    textAlign: 'left',
    verticalAlign: 'middle',
    fontSize: 12,
    margin: 0,
    padding: 2,
};

const IndexEducacaoAmbiental = (props) => {

    const [camadas, setCamadas] = useState([]);

    const _ = require("lodash");
    const { compose, lifecycle, withProps, withState, withHandlers, withStateHandlers } = require("recompose");

    const [zoom, setZoom] = useState(10);
    const [lat, setLat] = useState(-29.6378158);
    const [lon, setLon] = useState(-50.7858696);

    const [perimetro, setPerimetro] = useState(true);
    const [arroios, setArroios] = useState(true);
    const [municipios, setMunicipios] = useState(true);
    const [rioSinos, setRioSinos] = useState(true);
    const [educacaoAmbiental, setEducacaoAmbiental] = useState(true);

    const { classes } = props;

    const onZoomChanged = (prop) => {
      //  console.log("zoom: " + prop);
        //setZoom2(prop);
    };


    useEffect(() => {

        var apiEndpoint = 'camadas/public_educ';
        crudService.get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    setCamadas(response.data.camadas);
                  //  console.log('CAMADAS2>', response.data.camadas)
                }
            }).catch((err) => {
               // console.log(err);
                alerts.error(err);
            });



    }, []);


    const changeVisible = (id_camada) => {
        //find in array
        var obj = camadas.find((element) => {
            return element.camada.id === id_camada;
        })

        var newCamadas = camadas.map(
            el => el.camada.id === id_camada ? { ...el, v: !obj.v } : el
        )

        setCamadas(newCamadas);

    };

    const MapMarkerSearchBox = compose(
        withProps({
            googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDX5xiv8klIvdWN5yvY0zaqFF2M9z_4mgI&v=3.38",
            loadingElement: <div style={{ height: `100%` }} />,
            containerElement: <div style={{ height: `92vh` }} />,
            mapElement: <div style={{ height: `100%` }} />,
        }),
        withStateHandlers(() => ({
            selectedMarker: null,
            modal: false,
            analise: null,
            cemaden: null,
            ana: null
        }), {
            onToggleOpen: ({ selectedMarker }) => (index) => ({
                selectedMarker: index
            }),
            toggle: ({ modal }) => () => ({
                modal: !modal
            }),
            setCemaden: ({ cemaden }) => (value) => ({
                cemaden: value
            }),
            setAna: ({ ana }) => (value) => ({
                ana: value
                }),
            setAnalise: ({ analise }) => (value) => ({
                    analise: value
                })  
        }),
        withState('zoom', 'onZoomChange', zoom),
        withScriptjs,
        withGoogleMap
    )(props2 =>
        <div className={classes.mapStyles}>
            <Navbar style={{ borderWidth: 1, position: 'absolute', top: 68, left: 166, alignSelf: 'flex-end' }} className="navbar" expand="md">
                <Nav className="ml-auto">
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle className="btn bg-light m-0 p-2" nav caret>
                            <FontAwesomeIcon icon={faLayerGroup} />
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem>
                                <input onChange={() => setArroios(!arroios)} checked={arroios} type="checkbox" /> Arroios
                            </DropdownItem>
                            <DropdownItem>
                                <input onChange={() => setPerimetro(!perimetro)} checked={perimetro} type="checkbox" /> Perímetro da Bacia
                            </DropdownItem>
                            <DropdownItem>
                                <input onChange={() => setRioSinos(!rioSinos)} checked={rioSinos} type="checkbox" /> Rio dos Sinos
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
            </Navbar>

            <Navbar style={{ borderWidth: 1, position: 'absolute', top: 68, left: 214, alignSelf: 'flex-end' }} className="navbar" expand="md">
                <Nav className="ml-auto">
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle className="btn bg-light m-0 p-2" nav caret>
                            <FontAwesomeIcon icon={faFilter} />
                        </DropdownToggle>
                        <DropdownMenu>
                            {camadas.map((n, index) => {
                                return (
                                    <DropdownItem key={index}>
                                        <input checked={n.v} onChange={() => changeVisible(n.camada.id)} type="checkbox" /> {n.camada.nome}
                                    </DropdownItem>
                                )
                            })
                            }
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
            </Navbar>
            
                <GoogleMap
                    defaultCenter={{ lat: lat, lng: lon }}
                    zoom={props2.zoom}
                    ref={props2.onMapMounted}
                    onZoomChanged={() => onZoomChanged()}
                >

                    {(perimetro ?
                        <KmlLayer
                            url='https://prosinos-s3-files.s3.sa-east-1.amazonaws.com/camadas/production/bacia_sinos.kml'
                            options={{ preserveViewport: true }}
                        />
                        : '')}
                    {(arroios ?
                        <KmlLayer
                            url='https://prosinos-s3-files.s3.sa-east-1.amazonaws.com/camadas/production/hidrografia_v6.kmz'
                            options={{ preserveViewport: true }}
                        />
                        : '')}
                    {(rioSinos ?
                        <KmlLayer
                            url='https://prosinos-s3-files.s3.sa-east-1.amazonaws.com/camadas/production/sinos_v6.kml'
                            options={{ preserveViewport: true }}
                        />
                    : '')}


                {camadas.map((n, index) => {
                    return (
                        (n.camada.uploads != null && n.v && n.camada.uploads.map((item, ix) => {
                            return (
                                <KmlLayer
                                    key={ix}
                                    url={item.arquivo}
                                    options={{ preserveViewport: true }}
                                />
                            )
                        })
                        )
                    )
                })
                }

                    

                </GoogleMap>
        </div>
    );

    return (
        <div>
            <div>
                <NavBarIndex />
            </div>

            <div className={classes.mapStyles}>
                <MapMarkerSearchBox />
            </div>

        </div>
    );

};

IndexEducacaoAmbiental.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
    return {
        state
    };
}
const connectedIndexPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(withStyles(styles)(IndexEducacaoAmbiental)));
export { connectedIndexPage as IndexEducacaoAmbiental };
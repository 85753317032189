import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import { history } from '../_helpers';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { withStyles } from '@material-ui/core/styles';
import { usuariosAction } from '../_actions';
import { Button, Form, FormGroup, Row, Col, Label, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faUndo } from '@fortawesome/free-solid-svg-icons';

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
        width: theme.spacing(9),
        height: theme.spacing(9),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        color: '#ffffff'
    },
    backButton: {
        marginTop: theme.spacing(1),
    }
});

class PasswordReset extends Component {

    state = {
        submitted: false,
        login: '',
        token: '',
        password: '',
        repeatPassword: '',
    }

    componentDidMount() {

        const query = new URLSearchParams(this.props.location.search);
        var login = query.toString().substr(6, (query.toString().indexOf("&", 0)) - 6);
        this.setState({ login: decodeURIComponent(login)});

        var token = query.toString().substr(query.toString().indexOf("token=", 0) + 6, (query.toString().length - query.toString().indexOf("token=", 0) - 6));
        this.setState({ token: token });

    }

    handleChangePassword = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    handleChangeRepeatPassword = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    handleSubmit = (event) => {
        this.setState({ submitted: true }, () => {
            setTimeout(() => this.setState({ submitted: false }), 2500);
        });
        const { dispatch } = this.props;
        let payload = {
            login: this.state.login,
            senha: this.state.password
        };
        dispatch(usuariosAction.passwordReset(payload, this.state.token));
    }

    render() {
        const { submitted } = this.state;
        const { classes } = this.props;

        return (
            <Container component={Paper} maxWidth="sm">
                <CssBaseline />
                <div className={classes.paper}>
                    <Grid container justify="flex-start">
                        <IconButton className={classes.backButton} onClick={() => history.goBack()} aria-label="Voltar" color="primary">
                            <KeyboardBackspaceIcon />
                        </IconButton>
                    </Grid>
                    <Typography component="h2" variant="h6">
                        Informe e confirme a nova senha.
                    </Typography>

                    <Form style={{ marginTop: 10 }}>

                        <FormGroup className="input-group mb-2">
                            <input
                                autoFocus
                                type="password"
                                name="senha"
                                id="senha"
                                value={this.state.password}
                                className="form-control"
                                onChange={this.handleChangePassword('password')}
                                placeholder="Nova Senha *"
                            />
                        </FormGroup>

                        <FormGroup className="input-group mb-2">
                            <input
                                autoFocus
                                type="password"
                                name="repeatPassword"
                                id="repeatPassword"
                                value={this.state.repeatPassword}
                                className="form-control"
                                onChange={this.handleChangeRepeatPassword('repeatPassword')}
                                placeholder="Repita a Nova Senha *"
                            />
                        </FormGroup>

                        <Button
                            onClick={(event) => this.handleSubmit(event)}
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={submitted}
                            className={classes.submit}
                        >
                            {
                                (submitted && 'Enviando informações...')
                                || (!submitted && 'Alterar')
                            }
                        </Button>

                    </Form>

                </div>
            </Container>
        );
    }
}
PasswordReset.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return state;
}
const connectedPasswordResetPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(withStyles(styles)(PasswordReset)));
export { connectedPasswordResetPage as PasswordReset };